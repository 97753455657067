import React from 'react'

function CustomStatus({ keyValue, name }) {
    const statusMap = {
        total: name === "Ticket" ? "Total Ticket" : "Total Planned",
        inprogress: "In Progress",
        waiting_for_approval: "Waiting for Approval",
        approved: "Completed",
        reassigned: "Re - Assigned",
        reopened: "Reopened",
        yet_to_be_assigned: "Unassigned",
        save_as_draft: "Save as Draft",
    };

    const statusText = statusMap[keyValue];

    if (statusText) {
        return <div >{statusText}</div>;
    }

    // If the keyValue does not match any of the predefined ones, handle it dynamically
    if (keyValue && keyValue !== "waiting_for_approval" && keyValue !== "approved") {
        return <div>{keyValue.charAt(0).toUpperCase() + keyValue.slice(1)}</div>;
    }

    return null;
};

export default CustomStatus
