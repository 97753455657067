//Feature - QR Code is generated for assets & exported to PDF

//Created by - Janaki J
//Updated by - Janaki J

//importing dependencies and packages
import jsPDF from "jspdf";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { CONSTANTS } from "../../constants/URLs/urlConstants";
import { custService } from "../../services/service.index";
import { siteService } from "../../services/service.index";
import { assetService } from "../../services/service.index";
import Commonheader from "../../layouts/common/commonHeader";
import "./qrCodeStyle.css";
import IMAGENAME from "../../medias/media.index";
// import QRCode from "react-qr-code";
// import { QRCodeCanvas } from "qrcode.react";
import { QRCode } from "react-qrcode-logo";
import Loader from "../../components/loader/loader";
import { useSelector } from "react-redux";
import BackButton from "../../components/BackButton/BackButton";
import S3BucketImage from "../report/pdfPreview/s3BucketImages";

//Function to generate QR Code and to export PDF
function DropdownQR() {
  const accounting = useSelector((state) => state.access.accounting);
  const accessAsset = useSelector((state) => state.access.asset);
  const [type, setType] = useState("");
  const [typeSelected, setTypeSelected] = useState(false);
  const [custdata, setCustData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [custname, setCustname] = useState("");
  const [sitename, setSitename] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [siteId, setSiteID] = useState("");
  const [allasset, setAllasset] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showhide, setShowhide] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  let isValid = true;
  // const isLoading = useSelector((state) => state.asset.isLoading);
  // console.log("custdata", custdata)

  // console.log("allasset", allasset)
  //dropdown for customer names
  const handleCustomer = () => {
    // let array = string.split(",");
    setId(id);
    setName(name);
  };

  // client side validation
  const [sitenameErr, setSiteNameErr] = useState("");
  const [custnameErr, setCustnameErr] = useState("");
  const [typenameErr, setTypenameErr] = useState("");



  const setQRSize = (val) => {
    let defaultSize = 105
    let lengthVal = 21
    let countLine = 0

    if (val.asset_id?.length > lengthVal || val.device_id?.length > lengthVal || val.facility_id?.length > lengthVal || val.plant_id?.length > lengthVal) {
      countLine++

    }
    // if (val.asset_id?.length > 30 && val.make?.length > 30) {
    //     countLine++
    // }
    if (val.asset_name?.length > lengthVal || val.device_name?.length > lengthVal || val.facility_name?.length > lengthVal || val.plant_name?.length > lengthVal) {
      countLine++
      // countLine++
      // countLine++

    }
    if (val.criticality?.length > lengthVal || val.category?.length > lengthVal || val.department?.length > lengthVal || val.location?.length > lengthVal || val.make?.length > lengthVal || val.origin?.length > lengthVal || val.nature_of_asset?.length > lengthVal) {
      countLine++
      countLine++
    }

    if (val.model?.length > lengthVal) {
      countLine++
      // countLine++
      // countLine++
    }


    if (countLine == 0) return defaultSize
    else return defaultSize - (countLine * 5)

  }
  const formValidation = () => {
    const sitenameErr = {};
    const custnameErr = {};
    const typenameErr = {};
    if (custname.trim().length < 1) {
      custnameErr.custnameShort = "Please Choose Customer";
      isValid = false;
    }
    if (sitename.trim().length < 1) {
      sitenameErr.sitenameShort = "Please Choose Site";
      isValid = false;
    }
    if (type.trim().length < 1) {
      typenameErr.typenameShort = "Please Choose Asset/Device/Facility";
      isValid = false;
    }
    setSiteNameErr(sitenameErr);
    setCustnameErr(custnameErr);
    setTypenameErr(typenameErr);
    return isValid;
  };

  //fetch customer name
  const getData = async () => {
    await custService
      .getCustData(CONSTANTS.CUST_GET_ALL)
      .then(function (response) {
        setCustData(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  //fetch site based on customer id
  const getSiteByCustId = async (id) => {
    await siteService
      .getSiteById(id)
      .then(function (res) {
        setSiteData(res.data);
      })
      .catch(function (error) {
        // console.log(error.response);
      });
  };

  //fetch asset based on site id
  const getAssetBySiteId = async (id) => {
    setSiteID(id)
  };

  //Asset device facility type radio buttons
  const handleTypeQR = (e) => {
    // console.log('typee', e.target.value);
    setSelectedOptions([]);
    setShowhide("");
    const getshow = e.target.value;
    setType(getshow);
  }

  //select based on - radio button click
  const handleShow = (e) => {
    // console.log('0000', e.target.value);
    setTypeSelected((!typeSelected))
    const getshow = e.target.value;
    setShowhide(getshow);
    // if (faQR != "fa") {
    setIsLoading(true)
    assetService
      .getAssetById(siteId, type)
      .then(function (res) {
        // console.log(res.data);
        setAllasset(res.data);
        // setSelectedOptions(res.data)
        setIsLoading(false);
      })
      .catch(function (error) {
        // console.log(error.response);
        setIsLoading(false);
      });
    // } else {
    //   setAllasset(list1);
    // }
  };


  //dropdown select onChange function
  const handleChange = (obj) => {
    // console.log('obj', obj);
    setSelectedOptions(obj);
  };

  const dateTime = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }).format(new Date());

  //export pdf button
  const multiDownload = async () => {
    const isValid = formValidation();
    // if (!isValid) {
    //   alert(`Please fill mandatory field's`)
    // }
    if (showhide === "single" && selectedOptions.length === 0) {
      alert(`Please select atleast one ${type} ID`)
    }
    if (allasset.length !== 0) {
      var pdf = new jsPDF("p", "pt", "a4");
      var htmlTemp = document.getElementById("QRcode");
      //check for Screen dimensions
      let srcwidth = document.getElementById("QRcode").scrollWidth;
      await pdf.html(htmlTemp, {
        // margin: [35, 35, 35, 40],
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        html2canvas: {
          scale: 595.26 / srcwidth, //595.26 is the width of A4 page
          allowTaint: true,
          removeContainer: true,
          pagesplit: true,
          scrollY: 0,
          useCORS: true,
        },
        callback: function (htmlPDF) {
          // htmlPDF.save(`report.pdf`);
          htmlPDF.save(`ZV_${sitename.substring(25)}_${dateTime}_V0.25.pdf`);
        },
      });
    }
    return isValid;
  };

  //useEffect
  useEffect(() => {
    getData();
  }, []);
  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />}
      <Commonheader sitefilter={false} />
      <BackButton />
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4 mt-4">
            <form
              //prevent default page loading on pressing "Enter" key
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              name="myForm"
              id="Form"
              className="mt-5 rounded p-4 bg-light shadow"
              style={{
                height: '36rem'
              }}
            >
              <div className="formHeadings mb-2">QR Code Generator</div>
              <div className="mt-mb-2 col-md-12">
                <label>
                  Customer Name
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="custname"
                  className="form-select"
                  placeholder="Select"
                  value={custname}
                  onChange={(e) => {
                    setCustname(e.target.value);
                    handleCustomer(e.target.value);
                    getSiteByCustId(e.target.value.split(",")[0]);
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {custdata?.map((eachData) => {
                    return (
                      <option
                        value={[eachData._id, eachData.legal_name]}
                        key={eachData._id}
                      >
                        {eachData.legal_name}
                      </option>
                    );
                  })}
                </select>
                {Object.keys(custnameErr).map((key) => {
                  if (!custname) {
                    return (
                      <div key={key} style={{ color: "red", fontSize: 12 }}>
                        {custnameErr[key]}
                      </div>
                    );
                  } else {
                    <p></p>
                  }
                })}
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className="ml-mb-2 col-md-12">
                <label>
                  Site Name
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="sitename"
                  className="form-select"
                  placeholder="Select"
                  value={sitename}
                  onChange={(e) => {
                    setSitename(e.target.value);
                    getAssetBySiteId(e.target.value.split(",")[0]);
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {siteData?.map((eachSite, i) => {
                    return (
                      <option
                        value={[eachSite._id, eachSite.site_name]}
                        key={i}
                      >
                        {eachSite.site_name}
                      </option>
                    );
                  })}
                </select>
                {Object.keys(sitenameErr).map((key) => {
                  if (!sitename) {
                    return (
                      <div key={key} style={{ color: "red", fontSize: 12 }}>
                        {sitenameErr[key]}
                      </div>
                    );
                  } else {
                    <p></p>
                  }
                })}
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className="ml-mb-2 col-md-12">
                <label>Choose {accessAsset && accessAsset.c ? "Asset/Device/Facility" : "Asset"}
                  <span className="text-danger">*</span>
                </label>
                <br></br>
                <div>
                  {
                    accessAsset && accessAsset.c &&
                    <>
                      <input
                        style={{ marginTop: "10px" }}
                        className="radioButtons"
                        type="radio"
                        name="type"
                        value="asset"
                        defaultChecked={type === "low"}
                        onChange={(e) => handleTypeQR(e)}
                      />
                      &nbsp;Asset
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px" }}
                        type="radio"
                        name="type"
                        value="device"
                        onChange={(e) => handleTypeQR(e)}
                      />
                      &nbsp; Device
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px" }}
                        type="radio"
                        name="type"
                        value="facility"
                        onChange={(e) => handleTypeQR(e)}
                      />
                      &nbsp; Facility
                      {Object.keys(typenameErr).map((key) => {
                        if (!type) {
                          return (
                            <div key={key} style={{ color: "red", fontSize: 12 }}>
                              {typenameErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>
                        }
                      })}
                    </>
                  }
                  {accounting?.r &&
                    <>
                      <input
                        className="radioButtons"
                        style={{ marginTop: "10px" }}
                        type="radio"
                        name="type"
                        value="fa"
                        onChange={(e) => handleTypeQR(e)}
                      />
                      &nbsp; Fixed Assets
                    </>
                  }
                </div>
              </div>

              <div className="mt-4 col-md-12">
                <div>
                  <label>Choose All/Single
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <br></br>
                  <input
                    style={{ marginTop: "10px" }}
                    className="radioButtons"
                    type="radio"
                    name="assets"
                    value="all"
                    checked={showhide === "all"}
                    onChange={handleShow}
                  />
                  &nbsp; Select All
                  <input
                    style={{ marginLeft: "25px" }}
                    className="radioButtons"
                    type="radio"
                    name="assets"
                    value="single"
                    checked={showhide === "single"}
                    onChange={handleShow}
                  />
                  &nbsp; Select Single
                </div>
              </div>

              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              {showhide === "single" && (
                <div className="col-md-12">
                  <div>
                    <Select
                      className="dropdown"
                      placeholder="Select"
                      getOptionLabel={(option) => option.asset_id || option.device_id || option.facility_id}
                      getOptionValue={(option) => option._id}
                      options={allasset}
                      value={selectedOptions}
                      onChange={handleChange}
                      isSearchable={true}
                      isMulti
                      isClearable
                    />
                  </div>
                </div>
              )}

              &nbsp;&nbsp;&nbsp;
              <div className="col-md-12">
                <button
                  style={{ width: "100px" }}
                  type="button"
                  onClick={(e) => multiDownload(e.target.value)}
                  className="float-end saveButton"
                >
                  Export PDF
                </button>
                {/* <button
                  type="button"
                  onClick={() => accessAsset?.c ? navigate("/assetlist") : navigate("/cwip")}
                  className="backButton"
                >
                  Back
                </button> */}
              </div>
            </form>
          </div>
        </div>

        <div id="QRcode"
          className="reset"
        >
          {/* generate all qrcodes */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "flex-start",
              // justifyContent: "center",
              alignItems: "baseline",
              flexDirection: "row",
            }}
          >
            {allasset !== null &&
              showhide === "all" &&
              allasset.map((obj) => {
                return (
                  <div
                    key={obj._id}
                  >
                    <div className="normRect"
                      style={{
                        // position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",

                      }}
                    >

                      {/* .............. Header Logo Start ......... */}
                      <div
                        style={{
                          // border: "1px solid red",
                          width: "100%",
                          // height: "35px",
                          // position: "absolute",
                          top: "8",
                          // display: "block"
                          // display: "flex",  // ----uncomment for Indigo
                          justifyContent: "space-between", // ----uncomment for Indigo
                          alignItems: "center",
                          paddingBottom: "1.5px"
                        }}
                      >
                        {/* {console.log("custdata", custdata[0].cust_logo)} */}
                        <S3BucketImage
                          imageUrl={custdata[0].cust_logo}
                          widthSize={40}
                          marginSize={5}
                        />
                        {/* <img
                          // src={IMAGENAME.logoZV}
                          // src={custdata[0].cust_logo}
                     
                        style={{

                          width: "10rem",

                          // height: "1.6rem",

                          textAlign: "center",

                          marginTop: "4px", // ------6px for Indigo
                          marginRight: "5px",// ----uncomment for Indigo
                          // marginBottom: "2px",   //-----2 px for Indigo
                          // margin: "auto"
                        }}
                        /> */}


                      </div>
                      {/* .............. Header Logo End ......... */}
                      <div
                        style={{
                          // position: "absolute",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          // border: "1px solid red",
                          flex: "auto",
                        }}
                      >
                        <div
                          style={{
                            // border: "1px solid red",
                          }}
                        >
                          {/* .................. Header .................. */}
                          <div className="splitTop splitRect">{obj.site_name}</div>
                          <div className="splitRect" >
                            <span style={{ textTransform: 'capitalize', margin: '0px', letterSpacing: "0.01px" }}>{type == "fa" ? "Asset" : type} ID : </span>
                            <>
                              {obj.asset_id || obj.device_id || obj.facility_id || obj.plant_id}
                            </>
                          </div>
                          {/* .................. Header End  ..................*/}
                        </div>

                        <div
                          style={{
                            // border: "1px solid red",
                            // width: "100%",
                            // height: "100%"
                          }}
                        >
                          {/* .................. QR .................. */}
                          <QRCode
                            // value={obj._id}
                            value={`zongo.ai/${obj?.qr_id || obj?._id}`}
                            id="val._id"
                            size={setQRSize(obj)}
                            logoImage={IMAGENAME.loaderLogo}
                            logoWidth={20}
                            logoHeight={22}
                            // aspectRatio={2}
                            // removeQrCodeBehindLogo={true}
                            logoPadding={0.1}
                            eyeRadius={8}
                            qrStyle="dots"
                            logoPaddingStyle="circle"
                            quietZone={2}
                          />
                          {/* .................. QR End .................. */}
                        </div>

                        <div
                          style={{
                            // textTransform: 'capitalize',
                          }}
                        >
                          {/* .................. Footer .................. */}
                          <div className="splitRect splitTop"
                          // style={{
                          //     padding: '0 5px 0 5px'
                          // }}
                          >
                            <span style={{ textTransform: 'capitalize', margin: '0px', letterSpacing: "0.01px", }}>{type == "fa" ? "Asset Name" : type} : </span>
                            <>
                              {obj.asset_name || obj.device_name || obj.facility_name || obj.plant_name}
                            </>
                          </div>

                          {(type === "asset" || type === "device") ? (
                            <>
                              <div className="splitRect">Make : {obj.make || "-"}</div>
                              <div className="splitRect">Model : {obj.model || "-"}</div>
                              <div className="splitRect">Location : {obj.location || "-"}</div>
                            </>
                          ) : type == "plant" ?
                            <>
                              <div className="splitRect">{"Location"} : {obj.location}</div>
                              <div className="splitRect">{"Category"} : {obj.category || "Plant"}</div>
                              <div className="">{"Origin :"}  {obj.origin || ""}</div>
                            </>
                            : (
                              <>
                                <div className="splitRect">{type == "fa" ? "Nature of Asset" : "Category"} : {obj.category || obj.nature_of_asset || "-"}</div>
                                <div className="splitRect">{type == "fa" ? "" : "Dept :"}  {obj.department || ""}</div>
                                <div className="splitRect">{type == "fa" ? "Location" : "Location"} : {obj.location}</div>
                              </>
                            )
                          }
                          {/* .................. Footer End .................. */}
                        </div>
                      </div>

                      {/* .............. Footer Logo Start ......... */}
                      <div
                        style={{
                          // border: "1px solid red",
                          width: "100%",
                          height: "35px",
                          marginBottom: '7px',
                          // position: "absolute",
                          // bottom: "0"
                        }}
                      >
                        <div>
                          <img
                            src={IMAGENAME.logoZV}
                            // src={IMAGENAME.qrVARLogo}
                            style={{
                              width: "120px",
                              textAlign: "center",
                              paddingTop: "4px"
                              // pageBreakAfter: "always",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '9px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            // border: "1px solid black",
                            // paddingBottom: '11px'
                          }}
                        >www.zongovita.com
                        </div>

                      </div>
                      {/* .............. Footer Logo Start ......... */}

                    </div>
                  </div>
                )
              })}
          </div>



          {/*generate based on selection*/}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "flex-start",
              alignItems: "baseline",
              flexDirection: "row",
            }}
          >
            {selectedOptions !== null &&
              showhide === "single" &&
              selectedOptions.map((val) => {
                return (
                  <div
                    key={val._id}
                  >
                    <div className="normRect"
                      style={{
                        // position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",

                      }}
                    >

                      {/* .............. Header Logo Start ......... */}
                      <div
                        style={{
                          // border: "1px solid red",
                          width: "100%",
                          // height: "35px",
                          // position: "absolute",
                          top: "8",
                          // display: "block"
                          // display: "flex",  // ----uncomment for Indigo
                          justifyContent: "space-between", // ----uncomment for Indigo
                          alignItems: "center",
                          paddingBottom: "1.5px"
                        }}
                      >
                        <S3BucketImage
                          imageUrl={custdata[0].cust_logo}
                          widthSize={40}
                          marginSize={5}
                        />
                        {/* <img
                          // src={IMAGENAME.logoZV}
                          // src={custdata[0].cust_logo}
                     
                        style={{

                          width: "10rem",

                          // height: "1.6rem",

                          textAlign: "center",

                          marginTop: "4px", // ------6px for Indigo
                          marginRight: "5px",// ----uncomment for Indigo
                          // marginBottom: "2px",   //-----2 px for Indigo
                          // margin: "auto"
                        }}
                        /> */}


                      </div>
                      {/* .............. Header Logo End ......... */}
                      <div
                        style={{
                          // position: "absolute",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          // border: "1px solid red",
                          flex: "auto",
                        }}
                      >
                        <div
                          style={{
                            // border: "1px solid red",
                          }}
                        >
                          {/* .................. Header .................. */}
                          <div className="splitTop splitRect">{val.site_name}</div>
                          <div className="splitRect" >
                            <span style={{ textTransform: 'capitalize', margin: '0px', letterSpacing: "0.01px" }}>{type == "fa" ? "Asset" : type} ID : </span>
                            <>
                              {val.asset_id || val.device_id || val.facility_id || val.plant_id}
                            </>
                          </div>
                          {/* .................. Header End  ..................*/}
                        </div>

                        <div
                          style={{
                            // border: "1px solid red",
                            // width: "100%",
                            // height: "100%"
                          }}
                        >
                          {/* .................. QR .................. */}
                          <QRCode
                            // value={val._id}
                            value={`zongo.ai/${val?.qr_id || val?._id}`}
                            id="val._id"
                            size={setQRSize(val)}
                            logoImage={IMAGENAME.loaderLogo}
                            logoWidth={20}
                            logoHeight={22}
                            // aspectRatio={2}
                            // removeQrCodeBehindLogo={true}
                            logoPadding={0.1}
                            eyeRadius={8}
                            qrStyle="dots"
                            logoPaddingStyle="circle"
                            quietZone={2}
                          />
                          {/* .................. QR End .................. */}
                        </div>

                        <div
                          style={{
                            // textTransform: 'capitalize',
                          }}
                        >
                          {/* .................. Footer .................. */}
                          <div className="splitRect splitTop"
                          // style={{
                          //     padding: '0 5px 0 5px'
                          // }}
                          >
                            <span style={{ textTransform: 'capitalize', margin: '0px', letterSpacing: "0.01px", }}>{type == "fa" ? "Asset Name" : type} : </span>
                            <>
                              {val.asset_name || val.device_name || val.facility_name || val.plant_name}
                            </>
                          </div>

                          {(type === "asset" || type === "device") ? (
                            <>
                              <div className="splitRect">Make : {val.make || "-"}</div>
                              <div className="splitRect">Model : {val.model || "-"}</div>
                              <div className="splitRect">Location : {val.location || "-"}</div>
                            </>
                          ) : type == "plant" ?
                            <>
                              <div className="splitRect">{"Location"} : {val.location}</div>
                              <div className="splitRect">{"Category"} : {val.category || "Plant"}</div>
                              <div className="">{"Origin :"}  {val.origin || ""}</div>
                            </>
                            : (
                              <>
                                <div className="splitRect">{type == "fa" ? "Nature of Asset" : "Category"} : {val.category || val.nature_of_asset || "-"}</div>
                                <div className="splitRect">{type == "fa" ? "" : "Dept :"}  {val.department || ""}</div>
                                <div className="splitRect">{type == "fa" ? "Location" : "Location"} : {val.location}</div>
                              </>
                            )
                          }
                          {/* .................. Footer End .................. */}
                        </div>
                      </div>

                      {/* .............. Footer Logo Start ......... */}
                      <div
                        style={{
                          // border: "1px solid red",
                          width: "100%",
                          height: "35px",
                          marginBottom: '5px',
                          // position: "absolute",
                          // bottom: "0"
                        }}
                      >
                        <div>
                          <img
                            src={IMAGENAME.logoZV}
                            // src={IMAGENAME.qrVARLogo}
                            style={{
                              width: "120px",
                              textAlign: "center",
                              paddingTop: "4px"
                              // pageBreakAfter: "always",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '9px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            // border: "1px solid black",
                            // paddingBottom: '11px'
                          }}
                        >www.zongovita.com
                        </div>

                      </div>
                      {/* .............. Footer Logo Start ......... */}

                    </div>
                  </div>
                )
              })}
          </div>
        </div>


      </div>
    </div>
  );
}

//exporting component
export default DropdownQR;