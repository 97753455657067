import React from 'react'
import '../../css/modules/workorder/workorder.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

function SearchBox({searchInput,handleSearchInputChange,handleClearButton}) {
    return (
        <div className="float-end">
            <form className="mb-4">
                <input
                    type="text"
                    placeholder="Search"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    className="workorder-search"
                />
                <span
                    type="reset"
                    onClick={handleClearButton}
                    className="workorder-search-clear"
                >
                    <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                </span>
            </form>
        </div>
    )
}

export default SearchBox
