//importing dependencies and packages
import React, { useEffect, useState } from "react";
import moment from "moment";
import "../../../css/modules/report/workorderReport/commonstyle.css";
import S3BucketImage from "./s3BucketImages";

const convertToDMS = (coordinate, isLatitude) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

  const direction = isLatitude
    ? coordinate >= 0
      ? "N"
      : "S"
    : coordinate >= 0
      ? "E"
      : "W";

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
};

/////////////////////////AWS Operations ends/////////////////////////
const MaintenanceActivity = (props) => {
  const { MaintenanceActivity, activityName, backgroundColor } = props;

  //useEffects
  useEffect(() => {
    // console.log("MaintenanceActivity", MaintenanceActivity);
  }, [props]);

  //////////////////////////////////////HTML////////////////////////////////////////
  return (
    <div className="mx-auto">
      <table className="table maint-activity-table">
        <thead className="align-middle">
          <tr>
            <th colSpan="14" className="report-asset-details">
              {activityName}
            </th>
          </tr>
          <tr className="align-middle">
            <th colSpan="5" className="border border-dark">
              {MaintenanceActivity?.start_date !== null ? (
                <div className="w-100">
                  {MaintenanceActivity?.start_date ? (
                    <p className="align-middle m-0">
                      &nbsp; Start Date & Time -&nbsp;
                      {`${moment(MaintenanceActivity?.start_date).format(
                        "MMMM Do YYYY | hh:mm:ss A"
                      )}`}
                      &nbsp;
                    </p>
                  ) : (
                    <p className="align-middle m-0">
                      &nbsp; Start Date & Time - NA&nbsp;
                    </p>
                  )}
                </div>
              ) : (
                <div className="w-100">
                  {/* <p>Start Date & Time - NA </p> */}
                  <p className="align-middle m-0">
                    &nbsp; Start Date & Time - NA&nbsp;
                  </p>
                </div>
              )}
            </th>
            <th colSpan="5" className="border border-dark">
              {MaintenanceActivity?.end_date !== null ? (
                <div className="w-100">
                  {MaintenanceActivity?.end_date ? (
                    <p className="align-middle m-0">
                      End Date & Time -&nbsp;
                      {`${moment(MaintenanceActivity?.end_date).format(
                        "MMMM Do YYYY | hh:mm:ss A"
                      )}`}
                      &nbsp;
                    </p>
                  ) : (
                    <p className="align-middle m-0">
                      End Date & Time - NA&nbsp;
                    </p>
                  )}
                </div>
              ) : (
                <div className="w-100">
                  <p className="align-middle m-0">
                    End Date & Time - NA&nbsp;
                  </p>
                </div>
              )}
            </th>
            <th colSpan="4" className="border border-dark">
              Activity Duration -&nbsp;
              {moment
                .utc(
                  moment
                    .duration(MaintenanceActivity?.ma_duration, "seconds")
                    .asSeconds() * 1000
                )
                .format("HH:mm:ss")}
            </th>
          </tr>
          <tr>
            <th scope="col" colSpan="1" className="text-break border border-dark text-center align-middle">
              Task No
            </th>
            <th scope="col" colSpan="3" className="text-break border border-dark">
              Checkpoint
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark">
              Standard Requirement
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark">
              Response
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark">
              Comments
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark">
              Before Image
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark">
              After Image
            </th>
          </tr>
        </thead>
        {MaintenanceActivity?.checkpoints?.length !== 0
          ? MaintenanceActivity?.checkpoints?.map((eachData, index) => {
            return (
              <tbody className="w-100" key={index}>
                <tr className="align-middle">
                  <td colSpan='1' className="text-break border border-dark text-center align-middle">
                    {index + 1}
                  </td>
                  <td colSpan='3' className="text-break border border-dark align-middle">
                    {eachData.checkpoint}
                  </td>
                  <td colSpan='2' className="text-break border border-dark align-middle">
                    {eachData.standard_requirement}
                  </td>
                  <td colSpan='2' className="text-break border border-dark align-middle">
                    {eachData.cp_answer === "yes"
                      ? eachData.cp_answer.charAt(0).toUpperCase() +
                      eachData.cp_answer.slice(1)
                      : eachData.cp_answer === "not_applicable"
                        ? "Not Applicable"
                        : eachData.cp_answer === "no"
                          ? eachData.cp_answer.charAt(0).toUpperCase() +
                          eachData.cp_answer.slice(1)
                          : eachData.cp_answer}
                  </td>
                  <td colSpan='2' className="text-break overflow-hidden border border-dark align-middle">
                    {eachData.cp_comments ? eachData.cp_comments : '-'}
                  </td>
                  <td colSpan='2' className="text-break overflow-hidden border border-dark align-middle text-center" >
                    {eachData.cp_before_pic ?
                      <S3BucketImage
                        imageUrl={eachData.cp_before_pic}
                        widthSize={"6rem"}
                      /> : "-"}
                  </td>
                  <td colSpan='2' className="text-break overflow-hidden border border-dark align-middle text-center" >
                    {eachData.cp_after_pic ?
                      <S3BucketImage
                        imageUrl={eachData.cp_after_pic}
                        widthSize={"6rem"}
                      />
                      : "-"}
                  </td>
                </tr>
              </tbody>
            );
          })
          : null}
      </table>
      <table className="table maint-activity-table-performer">
        <tbody>
          <tr className="align-middle">
            <th colSpan="8" className="maint-activity-table-performer-comment">
              <span className="fw-bold">
                Additional Comments :&nbsp;
              </span>
              {MaintenanceActivity?.ma_comment}
            </th>
            <th className="border border-dark text-center align-middle" >
              {MaintenanceActivity?.ma_signature ?
                <S3BucketImage
                  imageUrl={MaintenanceActivity?.ma_signature}
                  widthSize={"100px"}
                /> : "-NA-"}
              <br />
              <span>Signature</span>
            </th>
          </tr>
        </tbody>
      </table>
      <table className="table maint-activity-table-performer-details">
        <thead style={{ width: "100%" }}>
          {MaintenanceActivity?.performer_info?.length !== 0 ? (
            <>
              {(() => {
                const lastPerformerInfo =
                  MaintenanceActivity?.performer_info?.[
                  MaintenanceActivity?.performer_info.length - 1
                  ];
                return lastPerformerInfo ? (
                  <>
                    <tr>
                      <th colSpan="3">
                        Performed by -
                        {lastPerformerInfo?.performed_by?.first_name &&
                          lastPerformerInfo?.performed_by?.first_name !==
                          "undefined undefined undefined" ? ` ${lastPerformerInfo?.performed_by?.first_name + " " + lastPerformerInfo?.performed_by?.middle_name + " " + lastPerformerInfo?.performed_by?.last_name}` : " -"}
                      </th>
                      <th colSpan="3">
                        Designation - {lastPerformerInfo?.performed_by?.designation ? ` ${lastPerformerInfo?.performed_by?.designation.replace("_", " ")}` : " -"}
                      </th>
                      <th colSpan="3">
                        Email - {lastPerformerInfo?.performed_by?.email ? ` ${lastPerformerInfo?.performed_by?.email}` : " -"}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="9">
                        Performer Geo Location -{lastPerformerInfo?.geo_loc?.coordinates?.length > 0 ? ` Lat : ${convertToDMS(
                          lastPerformerInfo?.geo_loc?.coordinates[1],
                          true
                        )} & Long : ${convertToDMS(
                          lastPerformerInfo?.geo_loc?.coordinates[0],
                          false
                        )}` : " Lat : NA & Long : NA"}
                      </th>
                    </tr>
                  </>
                ) : null;
              })()}
            </>
          ) : null}
        </thead>
      </table>
    </div>
  );
};

export default MaintenanceActivity;
