//importing dependencies and packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import IMAGENAME from "../../../medias/media.index";
import { useSelector, useDispatch } from "react-redux";
import "../../../css/modules/report/workorderReport/commonstyle.css";
import S3BucketImage from "./s3BucketImages";
import Loader from "../../../components/loader/loader";
import '../../../css/modules/report/workorderReport/ticket.css'

// function of Ticket Report Preview page to download report
const TicketReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.site.isLoading);

  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState([]);
  const [key, setKey] = useState('');
  const [selectedItem, setSelectedItem] = useState("Asset");

  const ticketReportDataArray = useSelector((state) => state?.ticket?.ticketReportData?.result);

  /////////////dispatch function to get ticket report data////////////
  const getInsReport = (ticketId) => {
    let idArray = ticketId.split(",")
    dispatch({
      type: "TICKET_REPORT",
      payload: { ticket_mid: idArray },
    });

  };
  //useEffects
  useEffect(() => {
    if (ticketReportDataArray !== null) {
      // console.log(ticketReportData);
    }
  }, [ticketReportDataArray]);

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getInsReport(id);
    }
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0])
      setSelectedItem(location.state?.[1])
    }
  }, [])

  useEffect(() => {
    // { console.log('imageSrc', imageSrc) }
  }, [imageSrc])

  const convertToDMS = (coordinate, isLatitude) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    const direction = isLatitude
      ? coordinate >= 0 ? 'N' : 'S'
      : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }

  const capitalisedfunction = (status) => {
    if (!status) return "NA"; // Handle null, undefined, or empty status
    return status
      .split("_") // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words with spaces
  };

  ///////////////////////////////HTML//////////////////////////////////
  return (
    <>
      {isLoading && <Loader />}
      {ticketReportDataArray !== undefined && ticketReportDataArray?.length > 0 && ticketReportDataArray?.map((ticketReportData) => {
        return <div className="report-responsive">
          {ticketReportData !== undefined &&
            <table className="table report-header-table">
              <thead className="w-100">
                <tr>
                  <th scope="col" colSpan="10" className="report-header-table-logo">
                    <img src={IMAGENAME.logo} alt="ZongoVita logo" className="report-header-table-logo-image" />
                  </th>
                  <th colSpan="40" className="report-header-table-content">
                    {ticketReportData?.customer ? <>
                      {`${ticketReportData.customer.legal_name} - ${ticketReportData.customer.city}, ${ticketReportData.customer.state}`}
                      <br />
                      <span className="report-header-table-content-span">
                        {`Report : ${ticketReportData.ticket?.name}`}
                      </span>
                    </> : 'No Data Available'}
                  </th>
                  <th colSpan="10" className="report-header-table-logo">
                    <S3BucketImage
                      imageUrl={ticketReportData?.customer?.cust_logo}
                      widthSize={'auto'}
                    />
                  </th>
                </tr>
                <tr className="align-middle">
                  <th colSpan="15" className="border border-dark">
                    {ticketReportData?.ticket?.id !== null ?
                      <p className="align-middle m-0 w-100 text-break"> Ticket Id -  <span className="fw-light">{`${ticketReportData?.ticket?.id}`}</span>  </p> :
                      <p className="align-middle m-0 w-100 text-break"> Ticket Id - <span className="fw-light"> NA</span> </p>}
                  </th>
                  <th colSpan="15" className="border border-dark">
                    {ticketReportData?.ticket?.service?.criticality !== null ?
                      <p sclassName="align-middle m-0 w-100 text-break">Criticality - <span className="fw-light">  {ticketReportData?.ticket?.service?.criticality}</span> </p> :
                      <p className="align-middle m-0">Criticality - <span className="fw-light">NA</span></p>
                    }
                  </th>
                  <th colSpan="10" className="border border-dark">
                    {ticketReportData?.ticket?.status !== null ?
                      <p className="align-middle m-0 w-100 text-break">Status - <span className="fw-light">{ticketReportData?.ticket?.status == 'approved' ? "Closed" : capitalisedfunction(ticketReportData?.ticket?.status)}</span>  </p> :
                      <p className="align-middle m-0">Status -<span className="fw-light"> NA </span></p>
                    }
                  </th>
                  <th colSpan="20" className="border border-dark">
                    {ticketReportData?.ticket?.updatedAt !== null ?
                      <p className="align-middle m-0">Updated at - <span className="fw-light">{`
                        ${ticketReportData?.ticket?.updatedAt !== null && ticketReportData?.ticket?.updatedAt !== '' && ticketReportData?.ticket?.updatedAt !== undefined ? moment(
                        ticketReportData?.ticket?.updatedAt
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span> </p> :
                      <p className="align-middle m-0">Updated at - <span className="fw-light"> NA</span> </p>
                    }
                  </th>
                </tr>
                <tr>
                  <th colSpan={ticketReportData?.ticket?.reopen?.length > 0 ? "30" : "60"} className="border border-dark align-middle">TAT (hh:mm:ss) -
                    <span className="fw-light align-middle"> {ticketReportData?.ticket?.ticket_duration ?
                      moment.utc(moment.duration(ticketReportData?.ticket?.ticket_duration, "seconds").asSeconds() * 1000).format("HH:mm:ss") : ''}
                    </span>
                  </th>
                  {ticketReportData?.ticket?.reopen?.length > 0 ?
                    <th colSpan="30" className="border border-dark align-middle">Reopen Count - <span className="fw-light align-middle">
                      {`${ticketReportData?.ticket?.reopen?.length > 0 ? (ticketReportData?.ticket?.reopen?.length < 10 ? `0${ticketReportData?.ticket?.reopen?.length}` : ticketReportData?.ticket?.reopen?.length) : '00'}`}
                    </span>
                    </th> : ''}
                </tr>
                <tr>
                  <th colSpan="60" className="report-asset-details">
                    {ticketReportData?.parent?.type?.charAt(0).toUpperCase() + ticketReportData?.parent?.type?.slice(1)}{" "} Details
                  </th>
                </tr>
                <tr>
                  <th colSpan="20" className="border border-dark align-middle" >{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} ID - <span className="fw-light align-middle"> {`${ticketReportData?.parent?.parent_id || "NA"}`}
                    </span>
                  </th>
                  <th colSpan="20" className="border border-dark align-middle">{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Name -
                    <span className="fw-light align-middle"> {`${ticketReportData?.parent?.parent_name || "NA"}`}</span>
                  </th>
                  <th colSpan="20" className="border border-dark align-middle">{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                    ticketReportData?.parent?.type?.slice(1)}{" "} Department -
                    <span className="fw-light align-middle">  {`${ticketReportData?.parent?.department || "NA"}`}</span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="30" className="border border-dark align-middle">
                    <span className="fw-bold">{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                      ticketReportData?.parent?.type?.slice(1)}{" "} Geo Location - Lat : </span>
                    <span className="fw-light">
                      {ticketReportData?.parent?.parent_geo_location?.coordinates?.length > 0
                        ? convertToDMS(ticketReportData?.parent?.parent_geo_location?.coordinates[1], true)
                        : 'NA'}
                    </span>
                    <span className="fw-bold"> & Long : </span>
                    <span className="fw-light">
                      {ticketReportData?.parent?.parent_geo_location?.coordinates?.length > 0
                        ? convertToDMS(ticketReportData?.parent?.parent_geo_location?.coordinates[0], false)
                        : 'NA'}
                    </span>
                  </th>
                  <th colSpan="30" className="border border-dark align-middle">
                    <span className="fw-bold">{ticketReportData?.parent?.type?.charAt(0).toUpperCase() +
                      ticketReportData?.parent?.type?.slice(1)}{" "} Location - </span>
                    <span className="fw-light">
                      {ticketReportData?.parent?.location || 'NA'}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="60" className="report-asset-details">Creation Details</th>
                </tr>
                <tr>
                  <th colSpan="30" className="border border-dark align-middle">
                    Created Date & Time - <span className="fw-light">
                      {ticketReportData?.ticket?.createdAt ? moment(ticketReportData?.ticket?.createdAt).format("MMMM Do YYYY | hh:mm:ss A") : 'NA'}
                    </span>
                  </th>
                  <th colSpan="15" className="border border-dark align-middle">
                    Created By - <span className="fw-light">
                      {ticketReportData?.ticket?.created_by || 'NA'}
                    </span>
                  </th>
                  <th colSpan="15" className="border border-dark align-middle">
                    Creation Mode - <span className="fw-light">
                      {ticketReportData?.ticket?.entry_type || 'NA'}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="10" className="border border-dark align-middle">
                    Issue Details
                  </th>
                  <th colSpan="50" className="border border-dark align-middle">
                    <span className="fw-light">
                      {ticketReportData?.ticket?.service?.problem_description || 'NA'}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="10" className="border border-dark align-middle">
                    Condition
                  </th>
                  <th colSpan="50" className="border border-dark align-middle">
                    <span className="fw-light">
                      {capitalisedfunction(ticketReportData?.ticket?.service?.status)}
                    </span>
                  </th>
                </tr>
                {ticketReportData?.ticket?.attachment?.length > 0 ?
                  <><tr>
                    <th colSpan="60" className="ticket-report-attachment-header">Attached Images - During Ticket Creation</th>
                  </tr>
                    <tr>
                      {ticketReportData?.ticket?.attachment?.map((el, i) => {
                        const attachmentLength = ticketReportData?.ticket?.attachment?.length;
                        return (
                          <th key={i} colSpan={60 / attachmentLength} scope="col" className="report-header-table-logo" >
                            <S3BucketImage
                              imageUrl={el}
                              widthSize={'auto'}
                            />
                          </th>
                        );
                      })}
                    </tr>
                  </> : <></>}
                {ticketReportData?.ticket?.assignment?.assigned_by &&
                  <>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Assigned to - <span className="fw-light">
                          {ticketReportData?.ticket?.assignment?.assignee?.length > 0
                            ? ticketReportData?.ticket?.assignment?.assignee
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">
                        Assigned by - <span className="fw-light">
                          {ticketReportData?.ticket?.assignment?.assigned_by || "NA"}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Assigned Geo Location -  Lat : <span className="fw-light">{ticketReportData?.ticket?.assignment?.geo_loc?.coordinates?.length > 0
                          ? convertToDMS(ticketReportData?.ticket?.assignment?.geo_loc?.coordinates[1], true)
                          : "NA"} </span>  &
                        Long : <span className="fw-light"> {ticketReportData?.ticket?.assignment?.geo_loc?.coordinates?.length > 0
                          ? convertToDMS(ticketReportData?.ticket?.assignment?.geo_loc?.coordinates[0], false)
                          : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">
                        Assigned Date & Time -  <span className="fw-light">
                          {ticketReportData?.ticket?.assignment?.assigned_date !== null &&
                            ticketReportData?.ticket?.assignment?.assigned_date !== '' &&
                            ticketReportData?.ticket?.assignment?.assigned_date !== undefined
                            ? moment(ticketReportData?.ticket?.assignment?.assigned_date).format("MMMM Do YYYY | hh:mm:ss A")
                            : "NA"}
                        </span>
                      </th>
                    </tr>
                  </>}
                {ticketReportData?.ticket?.performance?.performed_by &&
                  <>
                    <tr>
                      <th colSpan="60" className="report-asset-details align-middle">Performance Details</th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Performed Date & Time - <span className="fw-light">
                          {ticketReportData?.ticket?.performance?.performed_date ? moment(ticketReportData?.ticket?.performance?.performed_date).format("MMMM Do YYYY | hh:mm:ss A")
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">
                        Performed By - <span className="fw-light">
                          {ticketReportData?.ticket?.performance?.performed_by || "NA"}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="40" className="border border-dark align-middle">
                        Performer Geo Location - Lat : <span className="fw-light">
                          {ticketReportData?.ticket?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.performance?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light align-middle">
                          {ticketReportData?.ticket?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.performance?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Duration (hh:mm:ss) -
                        <span className="fw-light">
                          {`${ticketReportData?.ticket?.performance?.performance_duration ?
                            moment.utc(moment.duration(ticketReportData?.ticket?.performance?.performance_duration, "seconds").asSeconds() * 1000).format("HH:mm:ss") : "NA"}`}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="60" className="border border-dark align-middle">Root Cause Type - <span className="fw-light">{`${ticketReportData?.ticket?.performance?.root_cause?.type || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {
                      ticketReportData?.ticket?.performance?.root_cause?.type == 'direct' ?
                        <tr>
                          <th colSpan="60" className="border border-dark align-middle">Details -<span className="fw-light">
                            {`${ticketReportData?.ticket?.performance?.root_cause?.direct !== '' ? ticketReportData?.ticket?.performance?.root_cause?.direct : '-'}`}
                          </span>
                          </th>
                        </tr> :
                        <tr>
                          {ticketReportData?.ticket?.performance?.root_cause?.tool?.length > 0 &&
                            ticketReportData?.ticket?.performance?.root_cause?.tool?.map((el, i) => {
                              return (
                                <th colSpan={60 / ticketReportData?.ticket?.performance?.root_cause?.tool?.length} key={i} className="border border-dark align-content-start" >
                                  <span className="fw-bold">{`Why ${i + 1}`}</span>
                                  <br />
                                  <br />
                                  <span className="fw-bold">Reason: </span>
                                  <span className="fw-light">{el}</span>
                                </th>
                              );
                            })}
                        </tr>
                    }
                    <tr>
                      <th colSpan="10" className="border border-dark align-middle">Solution
                      </th>
                      <th colSpan="50" className="border border-dark align-middle">
                        <span className="fw-light">{` ${ticketReportData?.ticket?.performance?.notes || "NA"}`}
                        </span>
                      </th>
                    </tr>
                    {ticketReportData?.ticket?.performance?.attachment?.length > 0 ?
                      <><tr>
                        <th colSpan="60" className="ticket-report-attachment-header">Attached Images - During Ticket Performance</th>
                      </tr>
                        <tr>
                          {ticketReportData?.ticket?.performance?.attachment?.map((el, i) => {
                            const attachmentLength = ticketReportData?.ticket?.performance?.attachment?.length;
                            return (
                              <th key={i} colSpan={60 / attachmentLength} scope="col" className="report-header-table-logo">
                                <S3BucketImage
                                  imageUrl={el}
                                  widthSize={'auto'}
                                />
                              </th>
                            );
                          })}
                        </tr>
                      </> : ''
                    }
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">{`${ticketReportData?.ticket?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {ticketReportData?.ticket?.performance?.signature ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.performance?.signature}
                            // imageUrl={el}
                            widthSize={'100px'}
                          /> : "-NA-"}
                      </span>
                      </th>
                    </tr>
                  </>}
                {ticketReportData?.ticket?.approval?.approved_by &&
                  <>
                    <tr>
                      <th colSpan="60" className="report-asset-details">Approval Details</th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">Approved On - <span className="fw-light">{`
                  ${ticketReportData?.ticket?.approval?.approved_date !== null && ticketReportData?.ticket?.approval?.approved_date !== '' && ticketReportData?.ticket?.approval?.approved_date !== undefined ? moment(
                        ticketReportData?.ticket?.approval?.approved_date
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="15" className="border border-dark align-middle">Approved By - <span className="fw-light">
                        {`${ticketReportData?.ticket?.approval?.approved_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="15" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {ticketReportData?.ticket?.approval?.signature ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.approval?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Approver Geo Location - Lat : <span className="fw-light">
                          {ticketReportData?.ticket?.approval?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.approval?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light">
                          {ticketReportData?.ticket?.approval?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.approval?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">
                        {`${ticketReportData?.ticket?.approval?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>}
                {ticketReportData?.ticket?.reopen?.length > 0 && <tr>
                  <th colSpan="60" className="report-asset-details">Ticket History</th>
                </tr>
                }
                {ticketReportData?.ticket?.reopen?.length > 0 && ticketReportData?.ticket?.reopen?.map((el, i) => {
                  return <>
                    <tr>
                      <th colSpan="60" className="ticket-reopen-header">{`Performance Details for Reopen - ${el?.createdAt !== null && el?.createdAt !== '' && el?.createdAt !== undefined ? moment(
                        el?.createdAt
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}</th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">Performed Date & Time -  <span className="fw-light">{`${el?.performance?.performed_date !== null && el?.performance?.performed_date !== '' && el?.performance?.performed_date !== undefined ? moment(
                        el?.performance?.performed_date
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}</span></th>
                      <th colSpan="30" className="border border-dark align-middle">Performed By -  <span className="fw-light">
                        {`${el?.performance?.performed_by || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="40" className="border border-dark align-middle">
                        Performer Geo Location - Lat : <span className="fw-light">
                          {el?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.performance?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light">
                          {el?.performance?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.performance?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Duration (hh:mm:ss) - <span className="fw-light">
                        {`${el?.performance?.performance_duration ?
                          moment.utc(moment.duration(el?.performance?.performance_duration, "seconds").asSeconds() * 1000).format("HH:mm:ss") : "NA"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="60" className="border border-dark align-middle">Root Cause Type - <span className="fw-light">
                        {`${el?.performance?.root_cause?.type || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {
                      el?.performance?.root_cause?.type == 'direct' ?
                        <tr>
                          <th colSpan="60" className="border border-dark align-middle">Details - <span className="fw-light">
                            {`${el?.performance?.root_cause?.direct !== '' ? el?.performance?.root_cause?.direct : '-'}`}
                          </span>
                          </th>
                        </tr> :
                        <tr>
                          {el?.performance?.root_cause?.tool?.length > 0 &&
                            el?.performance?.root_cause?.tool?.map((ele, i) => {
                              return (
                                <th colSpan={60 / el?.performance?.root_cause?.tool?.length} key={i} className="border border-1 align-content-start">
                                  <span className="fw-bold">{`Why ${i + 1}`}</span>
                                  <br />
                                  <br />
                                  <span className="fw-bold">Reason: </span>
                                  <span className="fw-light">{ele}</span>
                                </th>
                              );
                            })}
                        </tr>
                    }
                    <tr>
                      <th colSpan="10" className="border border-dark align-middle">Solution
                      </th>
                      <th colSpan="50" className="border border-dark align-middle"><span className="fw-light">{` 
                  ${el?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    {el?.performance?.attachment?.length > 0 ?
                      <><tr>
                        <th colSpan="60" className="ticket-report-attachment-header">Attached Images - During Ticket Performance</th>
                      </tr>
                        <tr>
                          {el?.performance?.attachment?.map((ele, i) => {
                            const attachmentLength = el?.performance?.attachment?.length;
                            return (
                              <th key={i} colSpan={60 / attachmentLength} scope="col" className="report-header-table-logo" >
                                <S3BucketImage
                                  imageUrl={ele}
                                  widthSize={'auto'}
                                />
                              </th>
                            );
                          })}
                        </tr>
                      </> : ''}
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">
                        {`${el?.performance?.notes || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {el?.performance?.signature ?
                          <S3BucketImage
                            imageUrl={el?.performance?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="60" className="report-asset-details">Reopen Details</th>
                    </tr>
                    <tr>
                      <th colSpan="20" className="border border-dark align-middle">Reopened On - <span className="fw-light">
                        {`${el?.reopened_date ? moment(el?.reopened_date).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Reopened By - <span className="fw-light">
                        {`${el?.reopened_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {el?.signature ?
                          <S3BucketImage
                            imageUrl={el?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Reopened Geo Location - Lat : <span className="fw-light">
                          {el?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light">
                          {el?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">
                        {`${el?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>
                })}
                {ticketReportData?.ticket?.status === "rejected" &&
                  <>
                    <tr>
                      <th colSpan="60" className="report-asset-details">Rejection Details</th>
                    </tr>
                    <tr>
                      <th colSpan="20" className="border border-dark align-middle">Rejected On - <span className="fw-light">
                        {`${ticketReportData?.ticket?.rejection?.rejected_date ? 
                        moment(ticketReportData?.ticket?.rejection?.rejected_date).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Rejected By - <span className="fw-light">
                        {`${ticketReportData?.ticket?.rejection?.rejected_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {`${ticketReportData?.ticket?.rejection?.signature !== null && ticketReportData?.ticket?.rejection?.signature !== '' && ticketReportData?.ticket?.rejection?.signature !== undefined ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Rejecter Geo Location - Lat : <span className="fw-light">
                          {ticketReportData?.ticket?.rejection?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.rejection?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light">
                          {ticketReportData?.ticket?.rejection?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.rejection?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">
                        {`${ticketReportData?.ticket?.rejection?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>
                }
                {ticketReportData?.ticket?.status === "cancelled" &&
                  <>
                    <tr>
                      <th colSpan="60" className="report-asset-details">Cancellation Details</th>
                    </tr>
                    <tr>
                      <th colSpan="20" className="border border-dark align-middle">Cancelled On - <span className="fw-light">
                        {`${ticketReportData?.ticket?.cancellation?.cancelled_date !== null && ticketReportData?.ticket?.cancellation?.cancelled_date !== '' && ticketReportData?.ticket?.cancellation?.cancelled_date !== undefined ? moment(
                          ticketReportData?.ticket?.cancellation?.cancelled_date
                        ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Cancelled By - <span className="fw-light">
                        {`${ticketReportData?.ticket?.cancellation?.cancelled_by || "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {`${ticketReportData?.ticket?.cancellation?.signature !== null && ticketReportData?.ticket?.cancellation?.signature !== '' && ticketReportData?.ticket?.cancellation?.signature !== undefined ?
                          <S3BucketImage
                            imageUrl={ticketReportData?.ticket?.cancellation?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Canceller Geo Location - Lat : <span className="fw-light">
                          {ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light">
                          {ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(ticketReportData?.ticket?.cancellation?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">
                        {`${ticketReportData?.ticket?.cancellation?.notes || "NA"}`}
                      </span>
                      </th>
                    </tr>
                  </>
                }
              </thead>
            </table>
          }
          <br />
          <br />
          <br />
          <br />
        </div >
      })}
    </>
  );
};

export default TicketReport;
