import React, { useState, useEffect } from 'react'
import WeighingBalence from './1WeighingBalence'
import Commonheader from '../../../layouts/common/commonHeader'
import AirCompressorMonitor from './2AirCompressorMonitoring'
import { useLocation, useParams } from 'react-router-dom'
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import ReportPDFDownload from './reportPDFDownload'
import BackButton from '../../../components/BackButton/BackButton'
import AhuLog from './3Ahu'
import ThcLog from './4Thc'
import DbcLog from './5Dbc'
import { reportService } from '../../../services/service.index'
import moment from 'moment'
import PMC6 from './6PreventiveMaintenanceC-Checks'
import PMC7 from './7PreventiveMaintanance(Daily)'
import PmcGenMonthly from './9PmcGeneratorMonthly'
import PmcGenDaily from './10PmcGeneratorDaily'
import BoilerDaily from './12.boilerDaily'
import Loader from '../../../components/loader/loader'
import TMR from './16.temMonitRecord'
import BOD from './17BOD'
import Fortnightly from './Fortnightly'
import '../../../css/modules/report/customReport/allForms.css'
import {
    data7PMC, data8Pmc,
    BOD1, BOD2, BOD3, BOD31, BOD4, BOD5, BOD6, BOD7, BOD8, BOD9, BOD91, BOD10, BOD36, BOD37, BOD43, BOD56, BOD57, BOD107,
    SRS1, SRS11, SRS3, SRS33, SRS4, SRS41, SRS5, SRS51, tmr16, tmr44,
    WeighingBalenceHeader1, Weighing1BalenceHeader, WeighingBalenceHeader2, WeighingBalenceHeader3, Weighing13BalenceHeader, Weighing14BalenceHeader,
    Weighing25BalenceHeader, Weighing26BalenceHeader, Weighing28BalenceHeader, Weighing28BalenceHeader2, Weighing32BalenceHeader, Weighing23BalenceHeader23,
    Weighing33BalenceHeader33, Weighing41BalenceHeader, Weighing42BalenceHeader, Weighing42BalenceHeader42, Weighing47BalenceHeader, Weighing48BalenceHeader,
    Weighing49BalenceHeader, Weighing50BalenceHeader, Weighing52BalenceHeader, Weighing58BalenceHeader, Weighing58BalenceHeader58, Weighing61BalenceHeader,
    Weighing114BalenceHeader, Weighing114BalenceHeaderB,
    ThcheaderData, Thcdata1, Thcdata2, Thcdata4, Thcdata5, Thc24headerData, Thc24data1, Thc24data2, Thc24data4, Thc24data5,
    tableHeaderDaily, tableHeaderDaily2,
    Filter1, Filter2, Filter3, Filter4,
    DBC5, DBC21, DBC22, DBC102, DBC103, DBC104, DBC105, DBC106, DBC110, DBC115, DBC116, DBCtableHeaders141, DBCdata12140, DBCdata12141, DBCdata12144, DBCdata12142, DBCdata12148,
    ThcheaderData2,
    ThcdataIns, ThcdataIns2, ThcdataIns5, Thcheaderins6, Thcheaderins7,
} from './FormatData'
import CalibrationAutoTitrator from './CalibrationAutoTitrator'
import CalibrationRecord from './CalibrationRecordAutomated'
import GenexDailyReport from './GenexDailyReport'
import { useSelector } from "react-redux";
import SRS from './SRS'
import PCAUBI from './PCAUBI'
import GenexEleMechMainReport from './genexEleMechMainReport'
import Filtertemplate from './Filtertemplate'
import PCAUBI2 from './PCAUBI2'
import Insecticutor from './InsecticutorReport'

function AllForm() {
    const sites_id = useSelector((state) => state.auth?.sites_id && state.auth?.sites_id[0]);
    const { id } = useParams();
    const location = useLocation();
    const selectedSite = useSelector((state) => state.site.selectedSiteList);
    const siteData = useSelector((state) => state.site.siteUserList);
    const queryParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const filename = queryParams.get('filename');
    const [filterData, setFilterData] = useState({
        startDate: '',
        endDate: '',
        selectedStartDate: '',
        selectedEndDate: ''
    })

    // page set up for print
    const [printPageSet, setPrintPageSet] = useState('A4 landscape')
    const [pageWidth, setPageWidth] = useState(1200)
    const [isMulti, setIsMulti] = useState(false);
    const [frequency, setFrequency] = useState('');

    useEffect(() => {
        setIsMulti(queryParams.get('isMulti') === 'true' ? true : false);
        setFrequency(queryParams.get('frequency'));
    }, []);

    const [data, setData] = useState([]);
    const [assetData, setAssetData] = useState('');
    const [selectAll, setSelectAll] = useState(false); // State to track if "Select All" checkbox is checked
    const [selectedOptions, setSelectedOptions] = useState([]); // State to keep track of selected options
    const [responseData, SetResponseData] = useState([])

    //checkby
    const checkByCheckpoints1 = [...new Set(responseData?.checklist
        ?.map(item => item?.ins_approval_id)
        .filter(answer => answer !== '' && answer !== undefined && answer !== null)
    )];
    const checkByCheckpoints2 = [...new Set(responseData?.checklist
        ?.map(item => item?.performed_by)
        .filter(answer =>
            answer !== '' &&
            answer !== undefined &&
            answer !== null &&
            !Array.isArray(answer)
        )
    )];
    const check1 = [{
        data: {
            value: `Done by : ${checkByCheckpoints2}`,
            style: { textAlign: 'left' },
        },
        data1: {
            value: `Checked by : ${checkByCheckpoints1}`,
            style: { textAlign: 'left' },
        },
    }]
    // Footer details
    const check2 = {
        data: `Checked by: ${checkByCheckpoints1}`,
        colSpan: '6'
    }
    const formatCheckpoint = (item, index) => {
        return item?.checkpoints && item?.checkpoints[index]?.answer ? item?.checkpoints[index]?.answer : '-'
    };
    const formatDate = (date) => {
        return date && date !== undefined ? moment(date).local().format("DD/MM/YY") : '-'
    }
    const formatTime = (date) => {
        return date && date !== undefined ? moment(date).local().format("hh:mm A") : '-'
    }
    const formatApprovalPerformer = (res) => {
        return res && res?.length > 0 ? res : '-'
    }
    const formatDataObj = (value) => {
        return {
            value: value,
            style: { textAlign: 'center' },
        }
    }
    // 1 WeighingBalence
    const WeighingBalence1 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatTime(item?.end_date)),
        data3: formatDataObj(formatCheckpoint(item, 0)),
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data6: formatDataObj(formatApprovalPerformer(item?.performed_by)),
    }));
    // formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    // formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-')
    const instCodeData = [
        { data: `Instrument Code No: ${responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : ''}` },
        { data: `Make: ${responseData?.parent?.make !== undefined ? responseData?.parent?.make : ''}` }]

    //5 6618cb11abf43927cdb992b6
    const DBCtable1 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatCheckpoint(item, 2)),
        data3: formatDataObj(formatCheckpoint(item, 3)),
        data4: formatDataObj(formatCheckpoint(item, 4)),
        data5: formatDataObj(formatCheckpoint(item, 5)),
        data6: formatDataObj(formatCheckpoint(item, 6)),
        data7: formatDataObj(formatCheckpoint(item, 7)),
        data8: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //9 661a2ceb069914b5adaa78a8
    const dataPmc9 = [
        { data: `${responseData?.parent?.parent_name !== undefined ? responseData?.parent?.parent_name : '-'}`, colSpan: '5', textAlign: 'center' },
        {
            data: `FREQUENCY - MONTHLY`,
            colSpan: '5',
            textAlign: 'center'
        },
    ]
    //10 661a2e75069914b5adaa78aa
    const dataPmc10 = [
        { data: `${responseData?.parent?.parent_name !== undefined ? responseData?.parent?.parent_name : '-'}`, colSpan: '5', textAlign: 'center' },
        {
            data: `FREQUENCY - WEEKLY`,
            colSpan: '5',
            textAlign: 'center'
        },
    ]
    //16 661d0a1a181d6a4ba128bc27
    const tmrdistinct = [
        ...new Set(
            responseData?.checklist
                ?.map(item => item?.checkpoints[0]?.answer)
                ?.filter(answer => answer !== undefined && answer !== "")
        )
    ];
    const tmrdistinct2 = [
        ...new Set(
            responseData?.checklist
                ?.map(item => item?.checkpoints[1]?.answer)
                ?.filter(answer => answer !== undefined && answer !== "")
        )
    ];
    let temperatureLimit = responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : '';
    const tmr16A = [
        { data: `Name of Instrument : ${responseData?.parent?.parent_name !== undefined && responseData?.parent?.parent_name !== '' ? responseData?.parent?.parent_name : ''}`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
        { data: `Instrument Code No : ${responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : ''}`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
    ]
    const tmr16B = [
        { data: `Temperature Limit : ${temperatureLimit !== '' ? (temperatureLimit === "QC-IC-03" ? "42° C - 44° C" : "55° C - 60° C") : ''} `, colSpan: '1', rowSpan: '1', textAlign: 'left' },
        { data: `Month and Year :  ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate === filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
    ]
    const tmr16D = [
        { data: `Temperature Limit : 30° C - 35° C`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
        { data: `Month and Year :  ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate === filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
    ]
    const tmr16C = [
        { data: `Calibrated Thermometer Code: ${tmrdistinct} and Calibration Due Date: ${tmrdistinct2} `, colSpan: '1', rowSpan: '1', textAlign: 'left' },
    ]
    //6629de5f07f6f770dea8c5b2
    const tmr16e = [
        { data: `Temperature Limit : 20° C - 25° C`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
        { data: `Month and Year :  ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate === filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`, colSpan: '1', rowSpan: '1', textAlign: 'left' },
    ]
    //17 661e70f69d007bb0980fc7d2 AND //18 661e71579d007bb0980fc7d3
    const BOD11 = [
        { data: `Instrument Code No : ${responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : ''}`, colSpan: '3', rowSpan: '1', textAlign: 'left' },
    ]
    const tableResData = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatCheckpoint(item, 0)),
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatCheckpoint(item, 2)),
        data4: formatDataObj(formatCheckpoint(item, 3)),
        data5: formatDataObj(formatCheckpoint(item, 4)),
        data6: formatDataObj(formatCheckpoint(item, 5)),
        data7: formatDataObj(formatCheckpoint(item, 6)),
        data8: formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-')
    }));
    const tableResData1 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatCheckpoint(item, 2)),
        data4: formatDataObj(formatCheckpoint(item, 3)),
        data5: formatDataObj(formatCheckpoint(item, 4)),
        data6: formatDataObj(formatCheckpoint(item, 5)),
        data7: formatDataObj(formatCheckpoint(item, 6)),
        data8: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
        data9: formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-')
    }));
    //19 661e71ab9d007bb0980fc7d4
    const BOD13 = [
        { data: `Instrument Code No : ${responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : ''}`, colSpan: '3', rowSpan: '1', textAlign: 'left' },
        { data: `Make : ${responseData?.parent?.make !== undefined ? responseData?.parent?.make : ''}`, colSpan: '3', rowSpan: '1', textAlign: 'left' },
    ]
    const tableResData2 = responseData?.checklist?.map((item) => {
        return {
            data0: formatDataObj(formatDate(item?.start_date)),
            data1: formatDataObj(formatTime(item?.start_date)),
            data2: formatDataObj(formatTime(item.end_date)),
            data3: formatDataObj(formatCheckpoint(item, 0)),
            data4: formatDataObj(formatCheckpoint(item, 1)),
            data5: {
                value: item?.checkpoints[2]?.answer !== '' && item?.checkpoints?.length === 6 ? item?.checkpoints[2]?.answer : '-',
                style: { textAlign: 'center' },
            },
            data6: {
                value: item?.checkpoints?.length === 5 && item?.checkpoints[2]?.answer !== '' ? item?.checkpoints[2]?.answer : (item?.checkpoints?.length === 6 && item?.checkpoints[3]?.answer !== '' ? item?.checkpoints[3]?.answer : '-'),
                style: { textAlign: 'center' },
            },
            data7: {
                value: item?.checkpoints?.length === 5 && item?.checkpoints[3]?.answer !== '' ? item?.checkpoints[3]?.answer : (item?.checkpoints?.length === 6 && item?.checkpoints[4]?.answer !== '' ? item?.checkpoints[4]?.answer : '-'),
                style: { textAlign: 'center' },
            },
            data8: {
                value: item?.checkpoints?.length === 5 && item?.checkpoints[4]?.answer !== '' ? item?.checkpoints[4]?.answer : (item?.checkpoints?.length === 6 && item?.checkpoints[5]?.answer !== '' ? item?.checkpoints[5]?.answer : '-'),
                style: { textAlign: 'center' },
            },
            data9: formatDataObj(formatApprovalPerformer(item?.performed_by)),
            data10: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
        };
    });
    //20 6620ae4e9d007bb0980fc7d7
    const WeighingBalence2 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatTime(item?.end_date)),
        data3: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data6: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data7: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //21 22 6620b0779d007bb0980fc7d8
    const DBCtable2 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatCheckpoint(item, 2)),
        data3: formatDataObj(formatCheckpoint(item, 3)),
        data4: formatDataObj(formatCheckpoint(item, 4)),
        data5: formatDataObj(formatCheckpoint(item, 5)),
        data6: formatDataObj(formatCheckpoint(item, 6)),
        data7: formatDataObj(formatCheckpoint(item, 7)),
        data8: formatDataObj(formatCheckpoint(item, 8)),
        data9: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    // 662381a1e54f9b1753251d1f
    const WeighingBalenceWork = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data: {
            value: index + 1,  // received email on 31st jan to maintain this date format.
            style: { textAlign: 'center' },
        },
        data1: formatDataObj(formatDate(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatCheckpoint(item, 4)),
        data7: formatDataObj(formatCheckpoint(item, 5)),
    }));
    //23 6621f4b77ba5a161365b69ab
    const WeighingBalence3 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatCheckpoint(item, 0)),
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatCheckpoint(item, 2)),
        data4: formatDataObj(formatCheckpoint(item, 3)),
        data5: formatDataObj(formatCheckpoint(item, 4)),
        data6: formatDataObj(formatCheckpoint(item, 5)),
        data7: formatDataObj(formatCheckpoint(item, 6)),
        data8: formatDataObj(formatCheckpoint(item, 7)),
        data9: formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-')
    }));
    // 27 662382e6e54f9b1753251d21
    const tableResData4 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '2' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatCheckpoint(item, 4)),
        data7: {
            value: formatCheckpoint(item, 5),
            style: { textAlign: 'center', colSpan: '2' }, // Define inline style here
        },
        data8: formatDataObj(formatCheckpoint(item, 6)),
        data9: formatDataObj(formatTime(item?.end_date)),
        data10: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data11: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),

    }));
    //28 66238357e54f9b1753251d22
    const WeighingBalence28 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatTime(item?.end_date)),
        data3: formatDataObj(formatCheckpoint(item, 0)),
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data6: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data7: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //29 662383eae54f9b1753251d24
    const tableResData5 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '2' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data10: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data11: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    const tableResDataVacuum = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatTime(item?.end_date)),
        data3: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data6: formatDataObj(formatCheckpoint(item, 3)),
        data10: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data11: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    // 30 6626340579c2608c2533e9b6
    const tableResData6 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data10: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data11: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    // 31 6626349579c2608c2533e9b7
    const tableResData7 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data10: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data11: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    // 34 6626388f79c2608c2533e9ba
    const tableResData8 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '2' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatCheckpoint(item, 4)),
        data7: formatDataObj(formatCheckpoint(item, 5)),
        data8: formatDataObj(formatCheckpoint(item, 6)),
        data9: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
        data10: formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-')
    }));
    const BOD34 = [
        { data: `Instrument Code No : ${responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : ''}`, colSpan: '3', rowSpan: '1', textAlign: 'left' },
    ]
    //35 6629de5f07f6f770dea8c59f
    const tableResData9 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data10: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //37 6629de5f07f6f770dea8c5a1
    const tableResData10 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data10: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data11: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //41 6629de5f07f6f770dea8c5a5
    const WeighingBalence41 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatCheckpoint(item, 4)),
        data7: formatDataObj(formatDate(item?.end_date)),
        data8: formatDataObj(formatTime(item?.end_date)),
        data9: formatDataObj(formatCheckpoint(item, 5)),
        data10: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //42 6629de5f07f6f770dea8c5a6
    const instCodeData42 = [
        { data: `LAF ID`, colSpan: '1', textAlign: 'center' },
        { data: `${responseData?.parent?.parent_id !== undefined ? responseData?.parent?.parent_id : ''} `, colSpan: '4' },
        { data: `Location`, colSpan: '1', textAlign: 'center' },
        { data: `${responseData?.parent?.location !== undefined ? responseData?.parent?.location : ''}`, colSpan: '5' }
    ]
    const WeighingBalence42 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatTime(item?.end_date)),
        data3: formatDataObj(formatCheckpoint(item, 0)),
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data6: formatDataObj(formatCheckpoint(item, 3)),
        data7: formatDataObj(formatCheckpoint(item, 4)),
        data8: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data9: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
        data11: formatDataObj(formatCheckpoint(item, 5)),
    }));
    //6629de5f07f6f770dea8c5ab
    const WeighingBalence43 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data: {
            value: index + 1,
            style: { textAlign: 'center' },
        },
        data1: formatDataObj(formatDate(item?.start_date)),
        data3: formatDataObj(formatCheckpoint(item, 0)),
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data6: formatDataObj(formatCheckpoint(item, 3)),
        data8: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data9: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //43 6629de5f07f6f770dea8c5a7
    const tableResData11 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '2' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatCheckpoint(item, 4)),
        data7: formatDataObj(formatCheckpoint(item, 5)),
        data8: formatDataObj(formatCheckpoint(item, 6)),
    }));
    //48 6629de5f07f6f770dea8c5ac
    const WeighingBalence48 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data3: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: 2 },
        },
        data4: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatCheckpoint(item, 2)),
        data8: formatDataObj(formatApprovalPerformer(item?.performed_by)),
    }));
    //49 6629de5f07f6f770dea8c5ac
    const WeighingBalence49 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '2' },
        },
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatTime(item?.start_date)),
        data4: formatDataObj(formatTime(item?.end_date)),
        data5: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data6: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //52 6629de5f07f6f770dea8c5b0
    const WeighingBalence52 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '2' },
        },
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatCheckpoint(item, 2)),
        data4: formatDataObj(formatCheckpoint(item, 3)),
        data5: formatDataObj(formatCheckpoint(item, 4)),
        data6: formatDataObj(formatCheckpoint(item, 5)),
        data7: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data8: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //66238234e54f9b1753251d20
    const WeighingBalenceEyeWash = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatCheckpoint(item, 0)),
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data5: formatDataObj(formatApprovalPerformer(item?.performed_by)),
    }));
    const obj53 = [
        [{ data: `Name of Product / Material` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[0]?.answer))] }],
        [{ data: `Batch No.` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[1]?.answer))] }],
        [{ data: `Batch Size` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[2]?.answer))] }],
        [{ data: `Mfg. Date` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[3]?.answer))] }],
        [{ data: `Exp. Date` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[4]?.answer))] }],
        [{ data: `Mode of Pack` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[5]?.answer))] }],
        [{ data: `Reason for Study` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[6]?.answer))] }],
        [{ data: `Qty Kept Including Reserve Sample` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[7]?.answer))] }],
        [{ data: `Study Started` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[8]?.answer))] }],
        [{ data: `Study Completed` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[9]?.answer))] }],
        [{ data: `Chamber & Rack Number` }, { data: [...new Set(responseData?.checklist?.map(item => item?.checkpoints[10]?.answer))] }],
    ]
    // 57 6629de5f07f6f770dea8c5b5
    const tableResData57 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatDate(item?.end_date)),
        data5: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data6: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //58 6629de5f07f6f770dea8c5b6
    const WeighingBalence58 = responseData?.checklist && responseData?.checklist?.map((item, i) => ({
        data: {
            value: i + 1,  // received email on 31st jan to maintain this date format.
            style: { textAlign: 'center' },
        },
        data1: formatDataObj(formatDate(item?.start_date)),
        data2: formatDataObj(formatCheckpoint(item, 0)),
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatCheckpoint(item, 4)),
        data7: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data8: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //61 6629de5f07f6f770dea8c5b9
    const WeighingBalence61 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatCheckpoint(item, 0)),
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatCheckpoint(item, 2)),
        data4: formatDataObj(formatCheckpoint(item, 3)),
        data5: formatDataObj(formatCheckpoint(item, 4)),
        data6: formatDataObj(formatCheckpoint(item, 5)),
        data7: formatDataObj(formatCheckpoint(item, 6)),
        data8: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data9: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
        data10: formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-')
    }));
    //64 6629de5f07f6f770dea8c5bc
    const heading64A = [{ data: `Preventive Maintenance - ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    const heading64B = [{ data: `Equipment Code :${responseData?.parent ? responseData?.parent?.parent_id : ''} `, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    //66 6629de5f07f6f770dea8c5be
    const heading66A = [{ data: `Frequency -  ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    //73 6629de5f07f6f770dea8c5c5
    const heading73A = [{ data: `AREA NAME - ${responseData?.parent ? responseData?.parent?.area : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }, { data: `FREQUENCY : ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    const heading73B = [{ data: `EQUIPMENT NAME/CODE: ${responseData?.parent ? responseData?.parent?.parent_id : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    //74 6629de5f07f6f770dea8c5c6
    const heading74A = [{ data: `650 KVA GENERATOR`, textAlign: 'center', rowSpan: '1', colSpan: '1' }, { data: `FREQUENCY - ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    //75 6629de5f07f6f770dea8c5c7
    const heading75A = [{ data: `PREVENTIVE MAINTENANCE -  ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'center', rowSpan: '1', colSpan: '1' }]
    //85 6629de5f07f6f770dea8c5d1
    const heading85A = [{ data: `Frequency -   ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'right', rowSpan: '1', colSpan: '1' }]
    // 6629de5f07f6f770dea8c5d7
    const heading90A = [{ data: `FREQUENCY -   ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'right', rowSpan: '1', colSpan: '1' }]
    //102 666acd540849568dce4373df
    const DBCtable102 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatTime(item?.start_date)),
        data2: formatDataObj(formatCheckpoint(item, 2)),
        data3: formatDataObj(formatCheckpoint(item, 3)),
        data4: formatDataObj(formatCheckpoint(item, 4)),
        data5: formatDataObj(formatCheckpoint(item, 5)),
        data6: formatDataObj(formatCheckpoint(item, 6)),
        data7: formatDataObj(formatCheckpoint(item, 7)),
        data8: formatDataObj(formatCheckpoint(item, 8)),
        data9: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //107 666acd540849568dce4373e4
    const tableResData107 = responseData?.checklist && responseData?.checklist?.map((item, index) => ({
        data0: formatDataObj(formatDate(item?.start_date)),
        data2: {
            value: formatCheckpoint(item, 0),
            style: { textAlign: 'center', colSpan: '3' },
        },
        data3: formatDataObj(formatCheckpoint(item, 1)),
        data4: formatDataObj(formatCheckpoint(item, 2)),
        data5: formatDataObj(formatCheckpoint(item, 3)),
        data6: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data7: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //114 666acd540849568dce4373eb
    const WeighingBalence114 = responseData?.checklist && responseData?.checklist?.map(item => ({
        data: formatDataObj(formatDate(item?.start_date)),
        data1: formatDataObj(formatCheckpoint(item, 0)),
        data2: formatDataObj(formatCheckpoint(item, 1)),
        data3: formatDataObj(formatCheckpoint(item, 2)),
        data4: formatDataObj(formatCheckpoint(item, 3)),
        data5: formatDataObj(item?.common_comment !== '' ? item?.common_comment : '-'),
        data6: formatDataObj(formatApprovalPerformer(item?.performed_by)),
        data7: formatDataObj(formatApprovalPerformer(item?.ins_approval_id)),
    }));
    //Fornighty
    const rowsFornighty = [];
    const rowsFornighty2 = [];
    const extractValues = (str) => {
        const toleranceMatch = str.match(/Tolerance: ([^\r]+)/);
        const toleranceLimitMatch = str.match(/Tolerance limit: ([^\r]+)/);

        const tolerance = toleranceMatch ? toleranceMatch[1].trim() : null;
        const toleranceLimit = toleranceLimitMatch ? toleranceLimitMatch[1].trim() : null;

        return { tolerance, toleranceLimit };
    };
    let stdFornighty = [];
    let calibrationFornighty = [];
    responseData?.maint_checklist?.flatMap(item => {
        const date = item?.maintenance_activities[0]?.start_date && item?.maintenance_activities[0]?.start_date !== '' ? moment(item?.maintenance_activities[0]?.start_date).local().format("DD/MM/YY") : '-';
        const time = item?.maintenance_activities[0]?.start_date && item?.maintenance_activities[0]?.start_date !== '' ? moment(item?.maintenance_activities[0]?.start_date).local().format("hh:mm A") : '-';
        const error = item?.maintenance_activities[0]?.checkpoints[2]?.cp_answer !== '' ? item?.maintenance_activities[0]?.checkpoints[2]?.cp_answer : '-';
        if (item?.maintenance_activities[0]?.checkpoints[0]?.cp_answer !== '') {
            stdFornighty.push(item?.maintenance_activities[0]?.checkpoints[0]?.cp_answer)
        }
        if (item?.maintenance_activities[0]?.checkpoints[1]?.cp_answer !== '') {
            calibrationFornighty.push(item?.maintenance_activities[0]?.checkpoints[1]?.cp_answer)
        }
        const checkpoints = [];

        let j = 0;
        for (let i = 3; i < item?.maintenance_activities[0]?.checkpoints?.length; i += 2) {
            const { tolerance, toleranceLimit } = extractValues(item?.maintenance_activities[0]?.checkpoints[i]?.standard_requirement);
            checkpoints.push({
                checkpoint1: item?.maintenance_activities[0]?.checkpoints[i]?.checkpoint.split(" ").slice(-1)[0],
                checkpoint2: tolerance !== '' ? tolerance : '-',
                checkpoint3: toleranceLimit !== '' ? toleranceLimit : '-',
                checkpoint4: item?.maintenance_activities[0]?.checkpoints[i]?.cp_answer !== '' ? item?.maintenance_activities[0]?.checkpoints[i]?.cp_answer : '-',
                checkpoint5: item?.maintenance_activities[0]?.checkpoints[i + 1]?.cp_answer !== '' ? item?.maintenance_activities[0]?.checkpoints[i + 1]?.cp_answer : '-',
                checkpoint6: item?.maintenance_activities[0]?.performer_info ? item?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-',
                checkpoint7: item?.maint_approval_id !== '' ? item?.maint_approval_id : '-',
            });
            j++;
        }
        checkpoints.forEach((checkpoint, index) => {
            if (index % j === 0) {
                rowsFornighty.push({
                    data1: { value: date, style: { textAlign: 'center', rowSpan: j } },
                    data2: { value: time, style: { textAlign: 'center', rowSpan: j } },
                    data3: { value: error, style: { textAlign: 'center', rowSpan: j } },
                    data4: { value: checkpoint?.checkpoint1, style: { textAlign: 'center' } },
                    data5: { value: checkpoint?.checkpoint2, style: { textAlign: 'center' } },
                    data6: { value: checkpoint?.checkpoint3, style: { textAlign: 'center' } },
                    data7: { value: checkpoint?.checkpoint4, style: { textAlign: 'center' } },
                    data8: { value: checkpoint?.checkpoint5, style: { textAlign: 'center' } },
                    data9: { value: checkpoint?.checkpoint6, style: { textAlign: 'center' } },
                });
                rowsFornighty2.push({
                    data1: { value: date, style: { textAlign: 'center', rowSpan: j } },
                    data2: { value: time, style: { textAlign: 'center', rowSpan: j } },
                    data3: { value: error, style: { textAlign: 'center', rowSpan: j } },
                    data4: { value: checkpoint?.checkpoint1, style: { textAlign: 'center' } },
                    data5: { value: checkpoint?.checkpoint2, style: { textAlign: 'center' } },
                    data6: { value: checkpoint?.checkpoint3, style: { textAlign: 'center' } },
                    data7: { value: checkpoint?.checkpoint4, style: { textAlign: 'center' } },
                    data8: { value: checkpoint?.checkpoint5, style: { textAlign: 'center' } },
                    data9: { value: checkpoint?.checkpoint6, style: { textAlign: 'center' } },
                    data10: { value: checkpoint?.checkpoint7, style: { textAlign: 'center' } },
                });
            } else {
                rowsFornighty.push({
                    data1: { value: checkpoint?.checkpoint1, style: { textAlign: 'center' } },
                    data2: { value: checkpoint?.checkpoint2, style: { textAlign: 'center' } },
                    data3: { value: checkpoint?.checkpoint3, style: { textAlign: 'center' } },
                    data4: { value: checkpoint?.checkpoint4, style: { textAlign: 'center' } },
                    data5: { value: checkpoint?.checkpoint5, style: { textAlign: 'center' } },
                    data6: { value: checkpoint?.checkpoint6, style: { textAlign: 'center' } },
                });
                rowsFornighty2.push({
                    data1: { value: checkpoint?.checkpoint1, style: { textAlign: 'center' } },
                    data2: { value: checkpoint?.checkpoint2, style: { textAlign: 'center' } },
                    data3: { value: checkpoint?.checkpoint3, style: { textAlign: 'center' } },
                    data4: { value: checkpoint?.checkpoint4, style: { textAlign: 'center' } },
                    data5: { value: checkpoint?.checkpoint5, style: { textAlign: 'center' } },
                    data6: { value: checkpoint?.checkpoint6, style: { textAlign: 'center' } },
                    data7: { value: checkpoint?.checkpoint7, style: { textAlign: 'center' } },
                });
            }
        });
    });
    stdFornighty = [...new Set(stdFornighty)]
    calibrationFornighty = [...new Set(calibrationFornighty)]

    const componentsMap = {
        "6604f856e448a6f58df81130": <WeighingBalence instCodeData={instCodeData} tableHeaders={WeighingBalenceHeader1} tableHeaders2={Weighing1BalenceHeader} data={WeighingBalence1} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'SHIMADZU BALANCE USAGE LOG SHEET'} docNumber={'QCOP/048:F1-02'} malur={'Malur'} check2={check2} />,
        "6604f889e448a6f58df81455": <AirCompressorMonitor responseData={responseData} filterData={filterData} />,
        "66178d67d188a4a4f949a9b7": <AhuLog responseData={responseData} filterData={filterData} />,
        "66178f41d188a4a4f949a9ba": <ThcLog logo={responseData?.customer?.cust_logo} responseData={responseData} filterData={filterData} chklname={"TEMPERATURE AND RELATIVE HUMIDITY LOGSHEET"} tableHeaders={ThcheaderData} data1={Thcdata1} data2={Thcdata2} data4={Thcdata4} data5={Thcdata5} assetid={selectedOptions} />,
        "6629de5f07f6f770dea8c5a2": <Insecticutor logo={responseData?.customer?.cust_logo} responseData={responseData} filterData={filterData} chklname={"PREVENTIVE MAINTENANCE CHECKLIST (INSECTICUTOR)"} tableHeaders={ThcheaderData2} tableHeaders1={Thcheaderins6} tableHeaders2={Thcheaderins7} data1={ThcdataIns} data2={ThcdataIns2} data4={Thcdata4} data5={ThcdataIns5} assetid={selectedOptions} />,
        "6618cb11abf43927cdb992b6": <DbcLog data={DBCtable1} responseData={responseData} filterData={filterData} data1={DBC5.data1} data2={DBC5.data2} data6={DBC5.data3} data7={DBC5.data4} data8={DBC5.data5} data9={DBC5.data6} data10={DBC5.data7} data12={DBC5.data9} tableHeaders={DBC5.data8} medopharm={'medopharm'} std={"small"} LeastCount={"0.01 Kg"} />,
        "661a2a2b069914b5adaa78a5": <PMC6 responseData={responseData} filterData={filterData} logo={responseData?.customer?.cust_logo} chklname={'PREVENTIVE MAINTENANCE C-CHECKS (ONCE IN MONTH)'} heading2={"25 KVA GENERATOR"} docNumber={"MAINT/041:F3-00"} />,
        "661a2ada069914b5adaa78a6": <PMC7 responseData={responseData} headingName={"PREVENTIVE MAINTENANCE A-CHECKS (DAILY)"} filterData={filterData} chklname={'25 KVA GENERATOR'} data4={data7PMC} docNumber={'MAINT/041:F1-01'} frequency={''} logo={responseData?.customer?.cust_logo} />, // format number 7
        "661a2b72069914b5adaa78a7": <PMC7 responseData={responseData} headingName={"PREVENTIVE MAINTENANCE A CHECK"} filterData={filterData} chklname={'180 KVA GENERATOR'} docNumber={'MAINT/045:F4-01'} frequency={'Daily'} data4={data8Pmc} />, // format number 8
        "661a2ceb069914b5adaa78a8": <PmcGenMonthly responseData={responseData} filterData={filterData} frequency={"MONTHLY"} headingName={"PREVENTIVE MAINTENANCE CHECK LIST"} data4={dataPmc9} docNumber={'MAINT/037:F2-01'} />,
        "661a2e75069914b5adaa78aa": <PmcGenMonthly responseData={responseData} filterData={filterData} frequency={"WEEKLY"} headingName={"PREVENTIVE MAINTENANCE A CHECK LIST"} data4={dataPmc10} docNumber={'MAINT/063:F3-00'} />,
        "661a2d6b069914b5adaa78a9": <PmcGenDaily responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE A- CHECK FOR THE MONTH OF"} heading={"650 KVA Generator"} tableHeaders={tableHeaderDaily} docNumber={'MAINT/063:F1-00'} />,
        "6629de5f07f6f770dea8c5d0": <PmcGenDaily responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE A- CHECK FOR THE MONTH OF"} heading={"400 KVA GENERATOR"} tableHeaders={tableHeaderDaily2} docNumber={'MAINT/037:F1-02'} />,
        "661a305d069914b5adaa78ab": <BoilerDaily responseData={responseData} filterData={filterData} logo={responseData?.customer?.cust_logo} />,
        "661cf171181d6a4ba128bc15": <WeighingBalence instCodeData={instCodeData} tableHeaders={Weighing13BalenceHeader} tableHeaders2={Weighing1BalenceHeader} data={WeighingBalence1} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'ANALYTICAL BALANCE USAGE LOG SHEET '} docNumber={'QCOP/079:F1-00'} malur={'Malur'} check2={check2} />,
        "661cfa0e181d6a4ba128bc16": <WeighingBalence instCodeData={instCodeData} tableHeaders={Weighing14BalenceHeader} tableHeaders2={Weighing28BalenceHeader2} data={WeighingBalence1} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'ESSAE BALANCE USAGE LOG SHEET '} docNumber={'QCOP/044:F1-02'} malur={'Malur'} check2={check2} />,
        "661cff33181d6a4ba128bc17": <WeighingBalence instCodeData={instCodeData} tableHeaders={Weighing13BalenceHeader} tableHeaders2={Weighing1BalenceHeader} data={WeighingBalence1} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'SEMI MICRO BALANCE USAGE LOG SHEET '} docNumber={'QCOP/075:F1-00'} malur={'Malur'} check2={check2} />,
        "661d0a1a181d6a4ba128bc27": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD'} data4={tmr16A} data5={tmr16B} data7={tmr16C} data6={tmr16} docNumber={'QCOP/051:F1-01'} malur={'Malur'} />,
        "661e70f69d007bb0980fc7d2": <BOD responseData={tableResData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'BOD INCUBATOR USAGE LOG SHEET'} docNumber={'QCML/010:F1-02'} data6={BOD1} data4={BOD11} logo={responseData?.customer?.cust_logo} />,
        "661e71579d007bb0980fc7d3": <BOD responseData={tableResData1} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'BOD INCUBATOR CUM INCUBATOR USAGE LOG SHEET'} docNumber={'QCML/036:F1-00'} data6={BOD2} data4={BOD11} logo={responseData?.customer?.cust_logo} />,
        "661e71ab9d007bb0980fc7d4": <BOD responseData={tableResData2} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'AUTOTITRATOR USAGE LOG SHEET'} docNumber={'QCOP/018:F1-04'} data6={BOD3} header2={BOD31} data4={BOD13} logo={responseData?.customer?.cust_logo} />,
        "6620ae4e9d007bb0980fc7d7": <WeighingBalence instCodeData={instCodeData} tableHeaders={WeighingBalenceHeader2} tableHeaders2={Weighing28BalenceHeader2} data={WeighingBalence2} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CONDUCTIVITY METER USAGE LOG SHEET'} docNumber={'QCOP/052:F1-01'} checkedby={false} malur={'Malur'} />,
        "6620b0779d007bb0980fc7d8": <DbcLog data={DBCtable2} responseData={responseData} filterData={filterData} data1={DBC21?.data1} data2={DBC21?.data2} data6={DBC21?.data3} data7={DBC5.data4} data8={DBC21?.data4} data9={DBC21?.data5} data10={DBC21?.data6} data12={DBC21?.data8} tableHeaders={DBC21?.data7} LeastCount={"0.001 Gm"} />,
        "6620b38a9d007bb0980fc7d9": <DbcLog data={DBCtable1} responseData={responseData} filterData={filterData} data1={DBC22?.data1} data2={DBC22?.data2} data6={DBC22?.data3} data7={DBC22?.data4} data8={DBC22?.data5} data9={DBC22?.data6} data10={DBC22?.data7} data12={DBC22?.data8} tableHeaders={DBC5.data8} make={"MAKE : ORION"} LeastCount={"0.005 Kg"} medopharm={'medopharm'} />,
        "6621f4b77ba5a161365b69ab": <WeighingBalence instCodeData={instCodeData} tableHeaders={WeighingBalenceHeader3} tableHeaders2={Weighing23BalenceHeader23} data={WeighingBalence3} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DESTRUCTION RECORD'} docNumber={'QCML/035:F1-00'} instrumentCode={true} medopharm={'medopharm'} check1={check1} />,
        "6622179e42df7e8cfa16cbbb": <ThcLog logo={responseData?.customer?.cust_logo} responseData={responseData} filterData={filterData} tableHeaders={Thc24headerData} data1={Thc24data1} data2={Thc24data2} chklname={"TEMPERATURE LOG SHEET"} data4={Thc24data4} data5={Thc24data5} assetid={selectedOptions} />,
        "662381a1e54f9b1753251d1f": <WeighingBalence tableHeaders={Weighing25BalenceHeader} data={WeighingBalenceWork} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'WORK ALLOTMENT LOG'} docNumber={'QCGN/057:F1-01'} instrumentCode={true} checkedby={false} malur={'Malur'} />,
        "66238234e54f9b1753251d20": <WeighingBalence tableHeaders={Weighing26BalenceHeader} data={WeighingBalenceEyeWash} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'EYE WASH AND EMERGENCY SHOWER VERIFICATION LOG'} docNumber={'QCOP/053:F1-01'} instCodeData={instCodeData} />,
        "662382e6e54f9b1753251d21": <BOD responseData={tableResData4} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DISSOLUTION TEST APPARATUS USAGE LOG BOOK'} docNumber={'QCOP/039:F1-02'} data6={BOD4} data4={BOD13} logo={responseData?.customer?.cust_logo} instrumentCode={true} malur={'Malur'} />,
        "66238357e54f9b1753251d22": <WeighingBalence instCodeData={instCodeData} tableHeaders={Weighing28BalenceHeader} tableHeaders2={Weighing28BalenceHeader2} data={WeighingBalence28} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DIGITAL TAP DENSITY USAGE LOG SHEET'} docNumber={'QCOP/029:F1-02'} checkedby={false} malur={'Malur'} />,
        "662383eae54f9b1753251d24": <BOD responseData={tableResData5} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'ELECTROMAGNETIC SIEVE SHAKER USAGE LOG SHEET'} docNumber={'QCOP/040:F1-04'} data6={BOD5} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6626340579c2608c2533e9b6": <BOD responseData={tableResData6} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'UV CABINET USAGE LOG SHEET'} docNumber={'QCOP/004:F1-03'} data6={BOD6} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6626349579c2608c2533e9b7": <BOD responseData={tableResData7} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'UV-VISIBLE SPECTROPHOTOMETER USAGE LOG SHEET'} docNumber={responseData?.checklist !== undefined && responseData?.checklist?.length > 0 && responseData?.checklist[0]?.asset_id === 'QC-VIS-04' ? 'QCOP/064:F1-02' : 'QCOP/037:F1-04'} data6={BOD7} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "662635a679c2608c2533e9b8": <BOD responseData={tableResDataVacuum} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'VACUUM OVEN USAGE LOG SHEET'} docNumber={'QCOP/018:F1-04'} data6={BOD9} header2={BOD91} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6626364c79c2608c2533e9b9": <WeighingBalence tableHeaders={Weighing32BalenceHeader} tableHeaders2={Weighing33BalenceHeader33} data={WeighingBalence3} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'STERILIZATION RECORD'} docNumber={'QCML/028:F1-02'} instrumentCode={true} medopharm={'medopharm'} check1={check1} />,
        "6626388f79c2608c2533e9ba": <BOD responseData={tableResData8} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TWIN INCUBATOR USAGE LOG SHEET'} docNumber={'QCML/033:F1-03'} data6={BOD8} data4={BOD34} logo={responseData?.customer?.cust_logo} />,
        "6629de5f07f6f770dea8c59f": <BOD responseData={tableResData9} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'COLONY COUNTER USAGE LOG SHEET'} docNumber={'QCML/003:F1-00'} data6={BOD10} logo={responseData?.customer?.cust_logo} />,
        "6629de5f07f6f770dea8c5a0": <BOD responseData={tableResData6} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DIGITAL TABLET HARDNESS TESTER USAGE LOG SHEET'} docNumber={'QCOP/061:F2-02'} data6={BOD36} data4={BOD34} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5a1": <BOD responseData={tableResData10} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DISINTEGRATION TEST APPARATUS USAGE LOG SHEET'} docNumber={'QCOP/045:F1-03'} data6={BOD37} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5a3": <BOD responseData={tableResData1} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'INCUBATOR USAGE RECORD'} docNumber={'QCML/004:F1-01'} data6={BOD2} data4={BOD11} logo={responseData?.customer?.cust_logo} />,
        "6629de5f07f6f770dea8c5a4": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD FOR INCUBATOR'} data4={tmr16A} data5={tmr16B} data7={tmr16C} data6={tmr44} docNumber={responseData?.checklist !== undefined && responseData?.checklist?.length > 0 && responseData?.checklist[0]?.asset_id === 'QC-IC-03' ? 'QCML/032:F2-02' : 'QCML/032:F3-01'} monthyear={false} headerName={"Twin"} />,//40
        "6629de5f07f6f770dea8c5a5": <WeighingBalence instCodeData={instCodeData} tableHeaders={Weighing41BalenceHeader} data={WeighingBalence41} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'HPLC INSTRUMENT USAGE LOG BOOK'} docNumber={'QCOP/063:F1-02'} malur={'Malur'} check2={check2} />,
        "6629de5f07f6f770dea8c5a6": <WeighingBalence instCodeData={instCodeData42} tableHeaders={Weighing42BalenceHeader} tableHeaders2={Weighing42BalenceHeader42} data={WeighingBalence42} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'LAMINAR AIR FLOW USAGE LOG SHEET'} docNumber={'QCML/002:F1-00'} checkedby={false} />,
        "6629de5f07f6f770dea8c5a7": <BOD responseData={tableResData11} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'STABILITY SAMPLE REGISTER'} docNumber={'QCGN/016:F6-00'} data6={BOD43} data4={BOD13} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "6629de5f07f6f770dea8c5a8": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD FOR INCUBATOR'} data4={tmr16A} data5={tmr16B} data7={tmr16C} data6={tmr44} docNumber={'QCML/010:F2-00'} />,//44
        "6629de5f07f6f770dea8c5a9": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD FOR TWIN INCUBATOR'} data4={tmr16A} data5={tmr16D} data7={tmr16C} data6={tmr44} docNumber={'QCML/033:F2-01'} headerName={"Twin"} />,//45
        "6629de5f07f6f770dea8c5aa": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD FOR INCUBATOR'} data4={tmr16A} data5={tmr16B} data7={tmr16C} data6={tmr44} docNumber={'QCML/032:F2-01'} />,//46
        "6629de5f07f6f770dea8c5ab": <WeighingBalence tableHeaders={Weighing47BalenceHeader} data={WeighingBalence43} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'pH METER DAILY VERIFICATION REPORT'} docNumber={responseData?.checklist !== undefined && responseData?.checklist?.length > 0 && responseData?.checklist[0]?.asset_id === 'QC-pH-03' ? 'QCML/037:F3-01' : 'QCML/037:F3-00'} />,

        "6629de5f07f6f770dea8c5ac": <WeighingBalence tableHeaders={Weighing48BalenceHeader} instCodeData={instCodeData} data={WeighingBalence48} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'FT-IR SPECTROPHOTOMETER USAGE LOG SHEET'} docNumber={'QCOP/054:F1-03'} check2={check2} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5ad": <WeighingBalence tableHeaders={Weighing49BalenceHeader} instCodeData={instCodeData} data={WeighingBalence49} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'HOT AIR OVEN USAGE LOG SHEET'} docNumber={'QCOP/049:F1-02'} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5ae": <WeighingBalence tableHeaders={Weighing50BalenceHeader} tableHeaders2={Weighing1BalenceHeader} instCodeData={instCodeData} data={WeighingBalence1} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'pH METER USAGE LOG SHEET'} docNumber={responseData?.checklist !== undefined && responseData?.checklist?.length > 0 && responseData?.checklist[0]?.asset_id === 'QC-pH-03' ? 'QCOP/077:F1-00' : 'QCOP/046:F1-02'} medopharm={'medopharm'} check2={check2} />,
        "6629de5f07f6f770dea8c5af": <BOD responseData={tableResData6} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'REFRACTOMETER USAGE LOG SHEET'} docNumber={'QCOP/003:F1-03'} data6={BOD6} data4={BOD13} logo={responseData?.customer?.cust_logo} medopharm={'medopharm'} />,
        "6629de5f07f6f770dea8c5b0": <WeighingBalence tableHeaders={Weighing52BalenceHeader} data={WeighingBalence52} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'Stability Samples Issuance Record'} docNumber={'QCGN/016:F8-00'} />,
        "6629de5f07f6f770dea8c5b1": <WeighingBalence tableHeaders={Weighing52BalenceHeader} data={WeighingBalence52} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'STABILITY STUDY SCHEDULE'} docNumber={'QCGN/041:F1-02'} objheading={obj53} />,
        "6629de5f07f6f770dea8c5b2": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD FOR BOD INCUBATOR'} data4={tmr16A} data5={tmr16e} data7={tmr16C} data6={tmr44} docNumber={'QCML/036:F4-01'} headerName={"Twin"} />,

        "66c864199f90c3885a433e7d": <TMR responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'TEMPERATURE MONITORING RECORD FOR BOD INCUBATOR'} data4={tmr16A} data5={tmr16e} data7={tmr16C} data6={tmr44} docNumber={'QCML/036:F3-01'} headerName={"Twin"} />,//46

        "6629de5f07f6f770dea8c5b4": <BOD responseData={tableResData6} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'FRIABILITY TEST APPARATUS USAGE LOG SHEET'} docNumber={'QCOP/011:F1-03'} data6={BOD56} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5b5": <BOD responseData={tableResData57} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'MUFFLE FURNACE USAGE LOG BOOK'} docNumber={'QCOP/062:F1-01'} data6={BOD57} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5b6": <WeighingBalence tableHeaders={Weighing58BalenceHeader} tableHeaders2={Weighing58BalenceHeader58} data={WeighingBalence58} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'pH METER DAILY CALIBRATION REPORT'} docNumber={'QCCB/028:F2-02'} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5b7": <BOD responseData={tableResData6} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'SEMI AUTOMATIC POLARIMETER USAGE LOG SHEET'} docNumber={'QCOP/002:F1-05'} data6={BOD6} data4={BOD13} logo={responseData?.customer?.cust_logo} malur={'Malur'} />,
        "6629de5f07f6f770dea8c5b9": <WeighingBalence tableHeaders={Weighing61BalenceHeader} tableHeaders2={Weighing33BalenceHeader33} data={WeighingBalence61} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'STERILIZATION RECORD FOR RVSB'} docNumber={'QCML/028:F3-00'} medopharm={'medopharm'} />,
        "6629de5f07f6f770dea8c5ba": <PmcGenMonthly responseData={responseData} headingName={"PREVENTIVE MAINTENANCE"} headingName2={[{ data: '180 KVA GENSET', textAlign: 'center', rowSpan: '1', colSpan: '1' }]} filterData={filterData} frequency={"MONTHLY"} docNumber={'MAINT/045:F1-00'} />,
        "6629de5f07f6f770dea8c5bc": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"SIFTER"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/027:F6-00'} />,
        "6629de5f07f6f770dea8c5be": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE OF METAL DETECTOR"} headingName2={heading66A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/050:F1-00'} />,
        "6629de5f07f6f770dea8c5bf": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"AUTOMATIC CAPSULE FILLING MACHINE"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/029:F1-00'} />,
        "6629de5f07f6f770dea8c5c0": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"COMMUNITING MILL / MULTIMILL"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/027:F3-00'} />,
        "6629de5f07f6f770dea8c5c1": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"ROTARY COMPRESSION MACHINE"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/023:F1-00'} />,
        "6629de5f07f6f770dea8c5c2": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE OF BARCODING MACHINE"} headingName2={heading66A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/049:F1-00'} />,
        "6629de5f07f6f770dea8c5c3": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"COLLOID MILL"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/027:F5-00'} />,
        "6629de5f07f6f770dea8c5c4": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"AIR CURTAIN"} headingName2={heading64A} frequency={"MONTHLY"} docNumber={'MAINT/031:F2-00'} />,
        "6629de5f07f6f770dea8c5c5": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"MATERIAL PASS BOX PREVENTIVE MAINTENEANCE RECORD"} headingName2={heading73A} headingName3={heading73B} frequency={"MONTHLY"} docNumber={'MAINT/056:F1-01'} />,
        "6629de5f07f6f770dea8c5c6": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE CHECK LIST"} headingName2={heading74A} frequency={"MONTHLY"} docNumber={'MAINT/063:F2-00'} />,
        "6629de5f07f6f770dea8c5c7": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"HOT WATER GENERATOR"} headingName2={heading75A} frequency={"MONTHLY"} docNumber={'MAINT/013:F2-00'} />,
        "6629de5f07f6f770dea8c5c8": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PLANETARY MIXER"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/027:F2-00'} />,
        "6629de5f07f6f770dea8c5c9": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE OF DEFOILER"} headingName2={heading66A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/046:F1-00'} />,
        "6629de5f07f6f770dea8c5ca": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"STRIP PACKING MACHINE"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/028:F1-00'} />,
        "6629de5f07f6f770dea8c5cb": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE OF STIRRER"} headingName2={heading66A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/047:F1-00'} />,
        "6629de5f07f6f770dea8c5cc": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"OCTAGONAL BLENDER-II"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/022:F3-00'} />,
        "6629de5f07f6f770dea8c5cd": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"PREVENTIVE MAINTENANCE OF SHRINK WRAPPING MACHINE"} headingName2={heading66A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/048:F1-00'} />,
        "6629de5f07f6f770dea8c5ce": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"BOILER - 850 KG"} headingName2={heading64A} frequency={"MONTHLY"} docNumber={'MAINT/036:F2-00'} medopharm={'MEDOPHARM'} />,
        "6629de5f07f6f770dea8c5cf": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"BLISTER PACKING MACHINE"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/025:F1-00'} />,
        "6629de5f07f6f770dea8c5d1": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"RECIPROCATING AIR COMPRESSOR"} headingName2={heading85A} frequency={"MONTHLY"} docNumber={'MAINT/004:F2-01'} />,
        "6629de5f07f6f770dea8c5d2": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"STATIC PASS BOX PREVENTIVE MAINTENEANCE RECORD"} headingName2={heading73A} headingName3={heading73B} frequency={"MONTHLY"} docNumber={'MAINT/056:F2-00'} medopharm={'medopharm'} footer={false} />,
        "6629de5f07f6f770dea8c5d3": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"DYNAMIC PASS BOX PREVENTIVE MAINTENEANCE RECORD"} headingName2={heading73A} headingName3={heading73B} frequency={"MONTHLY"} docNumber={'MAINT/056:F3-00'} medopharm={'medopharm'} footer={false} />,
        "6629de5f07f6f770dea8c5d5": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"CPD 75 SCREW AIR COMPRESSOR"} headingName2={heading85A} frequency={"MONTHLY"} docNumber={'MAINT/035:F3-01'} />,
        "6629de5f07f6f770dea8c5d6": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"COATING & POLISHING PAN"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/027:F4-00'} />,
        "6629de5f07f6f770dea8c5d7": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"CPD 75 SCREW AIR COMPRESSOR"} headingName2={heading90A} frequency={"WEEKLY"} docNumber={'MAINT/035:F2-02'} />,
        "6629de5f07f6f770dea8c5d8": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"DISPENSING BOOTH"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/033:F1-01'} />,
        "6629de5f07f6f770dea8c5da": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"FLUID BED DRIER"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/020:F1-02'} />,
        "6629de5f07f6f770dea8c5db": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"COATING MACHINE"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/024:F1-00'} />,
        "6629de5f07f6f770dea8c5dc": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"RAPID MIXER GRANULATOR"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/021:F1-01'} />,
        "6629de5f07f6f770dea8c5dd": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"SAMPLING BOOTH"} headingName2={heading64A} headingName3={heading64B} frequency={"MONTHLY"} docNumber={'MAINT/033:F2-02'} />,
        "666acd540849568dce4373f6": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"DUMP VALUE CONDUCTIVITY CHALLENGING TEST RECORD"} headingName2={heading66A} frequency={"MONTHLY"} docNumber={'MAINT/006:F11-00'} footer={false} />,
        "6629de5f07f6f770dea8c5de": <Fortnightly data={DBCtable1} rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} responseData={responseData} filterData={filterData} data12={DBCdata12141} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"0.50 to 120.00 Kg"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/074:F47-01'} department={'PRODUCTION'} LeastCount={"0.01 Kg"} />,
        "6629de5f07f6f770dea8c5df": <Fortnightly data={DBCtable1} rows={rowsFornighty2} std={stdFornighty} calibration={calibrationFornighty} responseData={responseData} filterData={filterData} data12={DBCdata12140} headingName={'MAKE : ORION'} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"0.100 to 40.000 Kgs"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/183:F2-00'} department={'PRODUCTION'} LeastCount={"0.005 Kg"} />,
        "666acd540849568dce4373df": <DbcLog data={DBCtable102} responseData={responseData} filterData={filterData} data1={DBC102.data1} data2={DBC102.data2} data6={DBC102.data3} data7={DBC102.data4} data8={DBC102.data5} data9={DBC102.data6} data10={DBC102.data7} data12={DBC102.data9} tableHeaders={DBC102.data8} medopharm={'Medopharm'} std={"small"} LeastCount={"0.01 Kg"} />,
        "666acd540849568dce4373e0": <DbcLog data={DBCtable1} responseData={responseData} filterData={filterData} data1={DBC103.data1} data2={DBC103.data2} data6={DBC103.data3} data7={DBC103.data4} data8={DBC103.data5} data9={DBC103.data6} data10={DBC103.data7} data12={DBC103.data9} tableHeaders={DBC103.data8} medopharm={'medopharm'} std={"small"} LeastCount={"0.001 g"} />,
        "666acd540849568dce4373e1": <DbcLog data={DBCtable102} responseData={responseData} filterData={filterData} data1={DBC104.data1} data2={DBC104.data2} data6={DBC104.data3} data7={DBC104.data4} data8={DBC104.data5} data9={DBC104.data6} data10={DBC104.data7} data12={DBC104.data9} tableHeaders={DBC104.data8} medopharm={'Medopharm'} std={"small"} LeastCount={"0.1 g"} />,
        "666acd540849568dce4373e2": <DbcLog data={DBCtable102} responseData={responseData} filterData={filterData} data1={DBC105.data1} data2={DBC105.data2} data6={DBC105.data3} data7={DBC105.data4} data8={DBC105.data5} data9={DBC105.data6} data10={DBC105.data7} data12={DBC105.data9} tableHeaders={DBC105.data8} medopharm={'Medopharm'} std={"small"} LeastCount={"0.2g"} />,
        "666acd540849568dce4373e3": <DbcLog data={DBCtable1} responseData={responseData} filterData={filterData} data1={DBC106.data1} data2={DBC106.data2} data6={DBC106.data3} data7={DBC106.data4} data8={DBC106.data5} data9={DBC106.data6} data10={DBC106.data7} data12={DBC106.data9} tableHeaders={DBC106.data8} medopharm={'medopharm'} std={"small"} LeastCount={"0.001 g"} />,
        "666acd540849568dce4373e4": <BOD responseData={tableResData107} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DESTRUCTION LOG BOOK FOR ANALYTICAL SAMPLES'} docNumber={'QCGN/006:F1-02'} data6={BOD107} data4={BOD13} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "666acd540849568dce4373e7": <DbcLog data={DBCtable1} responseData={responseData} filterData={filterData} data1={DBC110.data1} data2={DBC110.data2} data6={DBC110.data3} data7={DBC110.data4} data8={DBC110.data5} data9={DBC110.data6} data10={DBC110.data7} data12={DBC110.data9} tableHeaders={DBC110.data8} medopharm={'medopharm'} std={"small"} LeastCount={"0.2 g"} />,
        "666acd540849568dce4373f7": <CalibrationRecord instCodeData={instCodeData} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CALIBRATION REPORT OF FRIABILITY TEST APPARATUS'} docNumber={'QCCB/011:F1-04'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373f5": <CalibrationRecord instCodeData={instCodeData} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'MONTHLY CALIBRATION REPORT OF CONDUCTIVITY METER'} docNumber={'QCCB/030:F1-03'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373eb": <WeighingBalence instCodeData={instCodeData} tableHeaders={Weighing114BalenceHeader} tableHeaders2={Weighing114BalenceHeaderB} data={WeighingBalence114} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'ESSAE BALANCE DAILY CALIBRATION REPORT'} docNumber={'QCCB/026:F1-05'} malur={'Malur'} check2={check2} checked={true} instrumentCode={true} />,
        "666acd540849568dce4373ec": <DbcLog data={DBCtable102} responseData={responseData} filterData={filterData} data1={DBC115.data1} data2={DBC115.data2} data6={DBC115.data3} data7={DBC115.data4} data8={DBC115.data5} data9={DBC115.data6} data10={DBC115.data7} data12={DBC115.data9} tableHeaders={DBC115.data8} medopharm={'Medopharm'} std={"small"} LeastCount={"0.01 Gm"} />,
        "666acd540849568dce4373ed": <DbcLog data={DBCtable1} responseData={responseData} filterData={filterData} data1={DBC116.data1} data2={DBC116.data2} data6={DBC116.data3} data7={DBC116.data4} data8={DBC116.data5} data9={DBC116.data6} data10={DBC116.data7} data12={DBC116.data9} tableHeaders={DBC116.data8} medopharm={'medopharm'} std={"small"} LeastCount={"0.2 g"} />,
        "666acd540849568dce4373f3": <CalibrationAutoTitrator instCodeData={instCodeData} tableHeaders={Weighing13BalenceHeader} tableHeaders2={Weighing1BalenceHeader} data={WeighingBalence1} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CALIBRATION REPORT OF AUTO TITRATOR'} docNumber={'QCCB/032:F1-00'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373f2": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"HEPA FILTER REPLACE REPLACEMENT RECORD"} headingName2={heading85A} frequency={"MONTHLY"} docNumber={'MAINT/011:F2-01'} heading={2} footer={false} />,
        "666acd540849568dce4373f4": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"CHILLER"} headingName2={heading75A} frequency={"MONTHLY"} docNumber={'MAINT/043:F1-00'} />,
        "666acd540849568dce437402": <PmcGenMonthly responseData={responseData} filterData={filterData} headingName={"OIL FREE AIR COMPRESSOR"} headingName2={heading85A} frequency={"MONTHLY"} docNumber={'MAINT/044:F3-01'} />,
        "666acd540849568dce437403": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12141} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"0.050 to 176.000"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/074:F68-01'} department={'PRODUCTION'} LeastCount={"0.001 g"} />,
        "666acd540849568dce437404": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12142} tableHeaders={DBCtableHeaders141} medopharm={'Medopharm'} OperatingRange={"0.500 Gm to 160.000 Gm"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'STR/010:F25-00'} department={'STORES'} LeastCount={"0.001 Gm"} />,
        "666acd540849568dce437405": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12141} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"0.050 to 496.000 g"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/074:F49-01'} department={'PRODUCTION'} LeastCount={"0.001 g"} />,
        "666acd540849568dce437406": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12144} tableHeaders={DBCtableHeaders141} medopharm={'Medopharm'} OperatingRange={"0.50 kg to 48.00 kg"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'STR/010:F13-03'} department={'STORES'} LeastCount={"0.01 kg"} />,
        "666acd540849568dce437407": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12141} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"0.050 to 160.000 g"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/074:F71-00'} department={'PRODUCTION'} LeastCount={"0.001 g"} />,
        "666acd540849568dce437408": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12142} tableHeaders={DBCtableHeaders141} medopharm={'Medopharm'} OperatingRange={"1.00 Gm to 240.00 Gm"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'STR/010:F9-03'} department={'STORES'} LeastCount={"0.01 Gm"} />,
        "666acd540849568dce437409": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12142} tableHeaders={DBCtableHeaders141} medopharm={'Medopharm'} OperatingRange={"20.0 Gm to 4800.0 Gm"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'STR/010:F11-04'} department={'STORES'} LeastCount={"0.2 g"} />,
        "666acd540849568dce43740a": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12148} tableHeaders={DBCtableHeaders141} medopharm={'Medopharm'} OperatingRange={"50.0 gm to 2400.0 gm"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'STR/010:F24-00'} department={'STORES'} LeastCount={"0.1 gm"} />,
        "666acd540849568dce43740b": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12141} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"50.0 to 1200.0 g"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/074:F11-03'} department={'PRODUCTION'} LeastCount={"0.2 g"} />,
        "666acd540849568dce43740c": <Fortnightly rows={rowsFornighty} std={stdFornighty} calibration={calibrationFornighty} data={DBCtable1} responseData={responseData} filterData={filterData} data12={DBCdata12141} tableHeaders={DBCtableHeaders141} medopharm={'medopharm'} OperatingRange={"60.0 to 2400.0 g"} Tolerance={"± 0.1% of standard weight used or least count whichever is maximum."} docNumber={'PDN/074:F3-03'} department={'PRODUCTION'} LeastCount={"0.2g"} />,
        "6629de5f07f6f770dea8c5b3": <SRS responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'SOFTENER REGENERATION LOG SHEET (NEAR PURIFIED WATER PLANT AREA)'} data6={SRS1} header2={SRS11} docNumber={'MAINT/006:F30-01'} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "66b1b6ff6231c54b2fd49f2c": <SRS responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'SOFTENER REGENERATION LOG SHEET (NEAR RAW WATER SUMP)'} data6={SRS1} header2={SRS11} docNumber={'MAINT/006:F12-01'} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "66b1be596231c54b2fd49f37": <SRS responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'CLEANING /STERILIZATION OF VENT FILTER RECORD'} data6={SRS33} header={[]} header2={SRS3} docNumber={'MAINT/006:F8-01'} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "66b1b6206231c54b2fd49f2a": <SRS responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'WATER SYSTEM SANITATION RECORD'} data6={SRS4} header2={SRS41} docNumber={'MAINT/006:F10-02'} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "66b1c0d36231c54b2fd49f39": <SRS responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR COMPRESSOR MONITORING RECORD'} data6={SRS5} header2={SRS51} docNumber={'MAINT/004:F5-01'} logo={responseData?.customer?.cust_logo} instrumentCode={true} />,
        "6629de5f07f6f770dea8c5b8": <PCAUBI instCodeData={instCodeData} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'PERFORMANCE CHECK OF AUTOCLAVE USING BIOLOGICAL INDICATORS'} docNumber={'QCML/035:F2-00'} malur={'Malur'} check2={check2} instrumentCode={true} />,
        "666acd540849568dce4373e5": <PCAUBI instCodeData={instCodeData} responseData={responseData} filterData={filterData} depname={'QUALITY ASSURANCE'} chklname={'CALIBRATION RECORD OF AUTOMATED FRIBILATOR EF-2W'} docNumber={'QAD/050:F1-00'} malur={'Malur'} check2={check2} instrumentCode={true} />,
        "666acd540849568dce4373e6": <PCAUBI responseData={responseData} filterData={filterData} depname={'PRODUCTION'} chklname={'CALIBRATION RECORD OF DISINTEGRATION TESTER'} docNumber={'PDN/058:F1-01'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373e8": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'SEMI MICRO BALANCE DAILY CALIBRATION REPORT'} docNumber={'QCCB/036:F1-01'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373e9": <PCAUBI responseData={responseData} filterData={filterData} depname={'PRODUCTION'} chklname={'CALIBRATION RECORD OF DISINTEGRATION TESTER (EDI-2SA)'} docNumber={'PDN/161:F1-00'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373f9": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'DIGITAL TABLET HARDNESS TESTER CALIBRATION LOG SHEET'} docNumber={'QCCB/033:F2-00'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373fa": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CALIBRATION REPORT OF TAP DENSITY TESTER'} docNumber={'QCCB/021:F1-04'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373fb": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CALIBRATION REPORT OF DISINTEGRATION TEST APPARATUS'} docNumber={'QCCB/027:F1-03'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373ff": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'PREVENTIVE MAINTENANCE FOR FUMEHOOD'} docNumber={'QCGN/013:F8-01'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce437400": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CALIBRATION REPORT OF MELTING POINT APPARATUS'} docNumber={'QCCB/025:F1-02'} malur={'Malur'} check2={check2} />,
        "66b1bd4d6231c54b2fd49f35": <PCAUBI responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'SHIMADZU BALANCE DAILY CALIBRATION REPORT'} docNumber={'QCCB/029:F1-06'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373f8": <PCAUBI2 responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'pH METER MONTHLY CALIBRATION REPORT'} docNumber={'QCCB/028:F1-04'} malur={'Malur'} check2={check2} />,
        "666acd540849568dce4373fe": <PCAUBI2 responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={'CALIBRATION REPORT OF FT-IR SPECTROPHOTOMETER'} docNumber={'QCCB/031:F1-00'} malur={'Malur'} check2={check2} />,
        "6629de5f07f6f770dea8c5bb": <Filtertemplate instCodeData={instCodeData} headingName2={heading85A} tableHeaders={Filter1} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR HANDLING UNIT FILTER CLEANING RECORD FOR 5 μ [PLENUM FILTERS]'} malur={''} medopharm={''} docNumber={'MAINT/003:F2-19'} />,
        "6629de5f07f6f770dea8c5bd": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter1} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR HANDLING UNIT FILTER CLEANING RECORD FOR 20 μ [RETURN FILTERS]'} malur={''} medopharm={''} docNumber={'MAINT/003:F17-01'} />,
        "6629de5f07f6f770dea8c5d9": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter2} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR HANDLING UNIT FILTER CLEANING RECORD FOR 10 μ [EXHAUST FILTERS]'} malur={''} medopharm={''} docNumber={'MAINT/003:F4-06'} />,
        "666acd540849568dce4373de": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter1} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR HANDLING UNIT FILTER CLEANING RECORD FOR 20 μ [MOUTH FILTERS]'} malur={''} medopharm={''} docNumber={'MAINT/003:F3-11'} />,
        "666acd540849568dce4373ee": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter1} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR HANDLING UNIT FILTER CLEANING RECORD FOR 10 μ [PLENUM]'} malur={''} medopharm={''} docNumber={'MAINT/003:F1-18'} />,
        "666acd540849568dce4373ef": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter1} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'AIR HANDLING UNIT FILTER CLEANING RECORD FOR 20 μ [RETURN RISER FILTERS]'} malur={''} medopharm={''} docNumber={'MAINT/003:F5-03'} />,
        "666acd540849568dce4373fc": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter3} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'FILTER CLEANING RECORD FOR DUST COLLECTORS'} malur={''} medopharm={''} docNumber={'MAINT/003:F7-04'} />,
        "666acd540849568dce4373fd": <Filtertemplate instCodeData={instCodeData} tableHeaders={Filter4} responseData={responseData} filterData={filterData} depname={'MAINTENANCE'} chklname={'FILTER CLEANING RECORD FOR FBD,FBP,NEOCOTA FILTERS'} malur={''} medopharm={''} docNumber={'MAINT/003:F20-02'} />,
        //Genex Report 
        "66839340c178e08749ee3040": <GenexDailyReport instCodeData={instCodeData} responseData={responseData} filterData={filterData} depname={'QUALITY CONTROL'} chklname={''} malur={''} medopharm={''} docNumber={filterData?.startDate !== '' ? moment(filterData?.startDate).local().format("DD/MM/YY") : ''} />,
        "6698e94081beea7ba35ba3da":
            responseData?.checklist?.map((checklistItem, index) => (
                <div key={index} style={{
                    pageBreakBefore: index !== 0 ? 'always' : 'auto',
                    paddingTop: index !== 0 ? '20px' : '0px'
                }}>
                    <GenexEleMechMainReport
                        pagenumber={index}
                        instCodeData={instCodeData}
                        responseData={{ ...responseData, checklist: [checklistItem] }}
                        filterData={filterData}
                        reportName={'ELECTRICAL PREVENTIVE MAINTENANCE REPORT'}
                        pageWidth={pageWidth}
                    />
                </div >
            )),
        // <GenexEleMechMainReport instCodeData={instCodeData} responseData={responseData} filterData={filterData} reportName={'ELECTRICAL PREVENTIVE MAINTENANCE REPORT'} pageWidth={pageWidth} />,
        "66a134fc2293d585b229d25c":
            responseData?.checklist?.map((checklistItem, index) => (
                <div key={index} style={{
                    pageBreakBefore: index !== 0 ? 'always' : 'auto',
                    paddingTop: index !== 0 ? '20px' : '0px'
                }}>
                    <GenexEleMechMainReport
                        pagenumber={index}
                        instCodeData={instCodeData}
                        responseData={{ ...responseData, checklist: [checklistItem] }}
                        filterData={filterData}
                        reportName={'MECHANICAL PREVENTIVE MAINTENANCE REPORT'}
                        pageWidth={pageWidth}
                    />
                </div >
            )),
        // "66a134fc2293d585b229d25c": <GenexEleMechMainReport instCodeData={instCodeData} responseData={responseData} filterData={filterData} reportName={'MECHANICAL PREVENTIVE MAINTENANCE REPORT'} pageWidth={pageWidth} />,
        "66a8ca9d5c20cf12e1b77a56":
            responseData?.checklist?.map((checklistItem, index) => (
                <div key={index} style={{
                    pageBreakBefore: index !== 0 ? 'always' : 'auto',
                    paddingTop: index !== 0 ? '20px' : '0px'
                }}>
                    <GenexEleMechMainReport
                        pagenumber={index}
                        instCodeData={instCodeData}
                        responseData={{ ...responseData, checklist: [checklistItem] }}
                        filterData={filterData}
                        reportName={'SERVICE REPORT'}
                        pageWidth={pageWidth}
                    />
                </div >
            )),
    };

    const componentToRender = componentsMap[id];
    const handleSelectAll = () => {
        if (!selectAll) {
            let valuesArray = [];
            if (Array.isArray(data)) {
                valuesArray = data.map(el => el.value);
            } else if (data && typeof data === 'object') {
                valuesArray = [data.value];
            }
            setAssetData(valuesArray);
            setSelectedOptions(data); // Select all options
        } else {
            setSelectedOptions([]); // Deselect all options
            setAssetData([]);
        }
        setSelectAll(!selectAll); // Toggle the "Select All" checkbox
    };

    const handleAsset = (option) => {
        setSelectedOptions(option);
        let valuesArray = [];
        if (Array.isArray(option)) {
            valuesArray = option.map(el => el.value);
            if (option?.length === data.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        } else if (option && typeof option === 'object') {
            valuesArray = [option.value];
        }
        setAssetData(valuesArray);
    };

    const handleDate = (name, e) => {
        e.preventDefault();
        const newDate = e.target.value;

        let newFilterData = { ...filterData };
        if (name === 'start') {
            newFilterData = {
                ...filterData,
                startDate: newDate,
                selectedStartDate: moment(newDate).local().format('DD MMM YY').toUpperCase(),
            };
        } if (name === 'end') {
            newFilterData = {
                ...filterData,
                endDate: newDate,
                selectedEndDate: moment(newDate).local().format('DD MMM YY').toUpperCase()
            };
        }
        setFilterData(newFilterData);
    };

    useEffect(() => {
        SetResponseData([])
        if (id !== undefined) {
            setSelectedOptions([]);
            setSelectAll(false);
            let arr = selectedSite?.selectedSite.map((site) => site._id);
            let siteIds = Array.isArray(arr) ? arr.join(',') : arr;
            reportService.getAssetsList(id, siteIds)
                .then((res) => {
                    if (res.status === 200) {
                        let arr = res?.data?.map((el) => {
                            return ({ value: el._id, label: el.id })
                        })
                        setData(arr);
                    }
                })
                .catch((err) => {
                });
        }
    }, [id, selectedSite])

    const handleFilter = () => {
        const startDate = moment(filterData?.selectedStartDate, "DD MMM YY");
        const endDate = moment(filterData?.selectedEndDate, "DD MMM YY");
        const dayDifference = endDate.diff(startDate, 'days') + 1;

        if (dayDifference > 31 && id === "66839340c178e08749ee3040") {
            alert("You cannot view data for more than 31 days");
            return;
        }

        if ((selectedSite?.selectedSite.length > 1 && !selectedSite?.selectAll) || (selectedSite?.selectAll && siteData?.result_count > 1)) {
            alert("Please select a single site to proceed with the report")
            return;
        }
        setIsLoading(true)
        if (filterData?.startDate === '' || filterData?.endDate === '') {
            alert("Please choose a start date and an end date for the filtering")
            setIsLoading(false)
            return;
        }
        if (assetData?.length === 0) {
            alert("Please choose asset for filtering")
            setIsLoading(false)
            return;
        }
        let body = {
            parent_ids: assetData,
            start_date: moment(filterData?.startDate).local().startOf('day').format(),
            end_date: moment(filterData?.endDate).local().endOf('day').format()
        }
        reportService.getSingleReport({ firstId: id, body: body })
            .then((res) => {
                if (res.status === 200) {
                    if (res?.data?.checklist?.length == 0 && res?.data?.maint_checklist?.length == 0) {
                        alert("Selected date and asset have no activities to display")
                    }
                    SetResponseData(res.data)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
            });
    }
    useEffect(() => {
        if (id === '6698e94081beea7ba35ba3da' || id === '66a134fc2293d585b229d25c' || id === '66a8ca9d5c20cf12e1b77a56') {
            setPrintPageSet('A4 portrait')
            setPageWidth(1000)
        }
    }, [id])
    return (
        <div className="w-100">
            <Commonheader />
            <BackButton />
            <h4 className="text-center mt-5 pt-4 download-title" >Download Report</h4>
            <div className='custom-report-main-div'>
                <div className="border rounded p-3 d-lg-flex flex-wrap justify-content-evenly w-75 mx-auto filter-div">
                    <div className="mb-3 w-25">
                        <label>Start Date</label><br />
                        <input
                            type="date"
                            placeholder="Start Date"
                            value={filterData?.startDate}
                            onChange={(e) => handleDate("start", e)}
                            className='date-filter-custom-report'
                            min={sites_id === '6422f56e26037ff46450359d' ? "2024-06-21" : ""}
                            max={filterData?.endDate || new Date().toISOString().split('T')[0]}
                        />

                    </div>
                    <div className="mb-3 w-25">
                        <label>End Date</label><br />
                        <input
                            type="date"
                            placeholder="End Date"
                            value={filterData?.endDate}
                            onChange={(e) => handleDate("end", e)}
                            min={sites_id === '6422f56e26037ff46450359d' ? "2024-06-21" : filterData?.startDate}
                            max={new Date().toISOString().split('T')[0]}
                            datatype='MM/YYYY'
                            className='date-filter-custom-report'
                        />
                    </div>
                    <div className="mb-3 w-50"> {/* Added margin-bottom for spacing */}
                        <label>Select Asset</label>
                        <Select
                            isClearable
                            formatCreateLabel={() => undefined}
                            isMulti={isMulti}
                            options={data}
                            value={selectedOptions}
                            onChange={handleAsset}
                            placeholder="Select Asset"
                            style={{ marginBottom: '5px' }} /* Adjusted margin for spacing */
                        />
                        {isMulti && isMulti == true ?
                            <div className="d-flex align-items-center">
                                <input
                                    type='checkbox'
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    style={{ marginRight: '5px' }}
                                />
                                <label>Select All</label>
                            </div> : ''}
                        <button className="saveButton" onClick={handleFilter} style={{ width: '100px', marginTop: '10px' }}>Apply Filter</button>
                    </div>
                </div>
                {isLoading && <Loader />}
                <ReportPDFDownload
                    btnDisable={(responseData?.checklist?.length>0 || responseData?.maint_checklist?.length>0)?false:true}
                    printData={componentToRender}
                    // pageSetup={'A4 landscape'}
                    // pageSetup={'A4 portrait'}
                    pageSetup={printPageSet}
                    startDate={filterData?.startDate}
                    endDate={filterData?.endDate}
                    reportName={filename}
                />
            </div>

        </div>
    )
}
export default AllForm


