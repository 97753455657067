import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import '../../css/component/backButton.css'

const BackButton = () => {

    const handleBackMoment = () => {
        window.history.back();
    }

    return (
        <div onClick={handleBackMoment} className='back-btn' >
            <FontAwesomeIcon icon={faArrowLeftLong} />
            {/* <span> Back</span> */}
        </div >
    )
}

export default BackButton