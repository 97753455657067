//Feature - Form to update/modify Device details

//Created by - Janaki J
//Updated by - Janaki J

//importing dependencies and packages
import { useEffect, useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DEVICE_UPDATE } from "../../redux/actions/deviceAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";

//Function to update device
const DeviceUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const options = ["1", "2", "3"];
  const location = useLocation();

  //to fetch data from redux
  const dispatch = useDispatch();
  const deviceGetSingle = useSelector((state) => state.device.deviceGetSingle);
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.device.isLoading);
  const access = useSelector((state) => state.access.device);
  const queryParams = new URLSearchParams(location.search);
  const screen = (queryParams.get('screen')) ? true : false;
  const [data, setData] = useState({
    id: "",
    device_id: "",
    name: "",
    desc: "",
    type: "",
    make: "",
    model: "",
    srlno: "",
    range: "",
    resolution: "",
    accuracy: "",
    rating: "",
    uom: "",
    criticality: "",
    devicelocation: "",
    manufacture: "",
    installation: "",
    month: "",
    devicedepartment: "",
    accessibility: "",
    photo: "",
    remark: "",
    url: "",
  });
  const [deviceidErr, setDeviceidErr] = useState("");
  const [devicenameErr, setDevicenameErr] = useState("");
  const [typeErr, setTypeErr] = useState("");
  const [makeErr, setMakeErr] = useState("");
  const [modelErr, setModelErr] = useState("");
  const [rangeErr, setRangeErr] = useState("");
  const [resolutionErr, setResolutionErr] = useState("");
  const [accuracyErr, setAccuracyErr] = useState("");
  const [criticErr, setCriticErr] = useState("");

  const patterns = {
    idChars: /^([a-zA-Z0-9./@-]+[0-9()\s-]*)*[a-zA-Z0-9./@-]+$/,
    numChars: /^([0-9]+\s)*[0-9]+$/,
    rncChars: /^([a-zA-Z0-9./&-]+\s)*[a-zA-Z.,0-9./&-]+$/,
    areaChars: /^([(a-zA-Z)0-9-/@|#]+\s)*[a-zA-Z0-9-/@|#]+$/,
    makeChars: /^([(a-zA-Z)0-9-/@|#]+\s)*[(a-zA-Z)0-9-/@|#]+$/,
    deptChars: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
    uomChars: /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/
  };

  //Functions to handle form inputs

  const formValidation = () => {
    const deviceidErr = {};
    const devicenameErr = {};
    const typeErr = {};
    const makeErr = {};
    const modelErr = {};
    const rangeErr = {};
    const resolutionErr = {};
    const accuracyErr = {};
    const criticErr = {};
    let isValidCheck = true;

    if (!validateChars(data.device_id, patterns.idChars) 
      || !validateChars(data.name, patterns.idChars) 
      || !validateChars(data.make, patterns.makeChars) 
      || !validateChars(data.model, patterns.areaChars) 
      || !validateChars(data.criticality, patterns.numChars)
    ) {
      isValidCheck = false
      console.log("inside")
      // setIsValid(false)
    }

    if (data.device_id.trim().length < 1) {
      deviceidErr.deviceidShort = "Please Enter Asset ID";
      isValidCheck = false
    }
    setDeviceidErr(deviceidErr);
    if (data.name.trim().length < 1) {
      devicenameErr.deviceNameShort = "Please Enter Asset name";
      isValidCheck = false
    }
    setDevicenameErr(devicenameErr);
    if (data.make.trim().length < 1) {
      makeErr.devicemakeShort = "Please Enter Make";
      isValidCheck = false
    }
    setMakeErr(makeErr);
    if (data.type.trim().length < 1) {
      typeErr.devicetypeShort = "Please Enter Type";
      isValidCheck = false
    }
    setTypeErr(typeErr);
    if (data.model.trim().length < 1) {
      modelErr.devicemodelShort = "Please Enter Model";
      isValidCheck = false
    }
    setModelErr(modelErr);
    if (data.range.trim().length < 1) {
      rangeErr.devicerangeShort = "Please Enter Range";
      isValidCheck = false
    }
    setRangeErr(rangeErr);
    console.log('data.resolution.trim().length',data.resolution.trim().length)
    if (data.resolution.trim().length < 1) {
      resolutionErr.deviceresolutionShort = "Please Enter Resolution";
      isValidCheck = false
    }
    setResolutionErr(resolutionErr);

    if (data.accuracy.trim().length < 1) {
      accuracyErr.deviceaccuracyShort = "Please Enter Accuracy";
      isValidCheck = false
    }
    setAccuracyErr(accuracyErr);
    if (data.criticality == '') {
      criticErr.critiShort = " Choose Criticality";
      isValidCheck = false
    }
    setCriticErr(criticErr);
    return isValidCheck;
  };

  const validation = () => {
    let isValidCheck = formValidation();
    return isValidCheck;
  };
  const handleDeviceID = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        device_id: val,
        isValidDeviceID: true,
      });
    } else {
      setData({
        ...data,
        device_id: val,
        isValidDeviceID: false,
      });
    }
  };
  const handleName = (val) => {
    setData({
      ...data,
      name: val,
    });
  };
  const handleType = (val) => {
    setData({
      ...data,
      type: val,
    });
  };
  const handleMake = (val) => {
    setData({
      ...data,
      make: val,
    });
  };
  const handleModel = (val) => {
    setData({
      ...data,
      model: val,
    });
  };
  const handleSerial = (val) => {
    setData({
      ...data,
      srlno: val,
    });
  };
  const handleRange = (val) => {
    setData({
      ...data,
      range: val,
    });
  };
  const handleResolution = (val) => {
    setData({
      ...data,
      resolution: val,
    });
  };
  const handleAccuracy = (val) => {
    setData({
      ...data,
      accuracy: val,
    });
  };
  const handleRating = (val) => {
    setData({
      ...data,
      rating: val,
    });
  };
  const handleUom = (val) => {
    setData({
      ...data,
      uom: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      criticality: Number(val),
    });
  };
  const handleManufacture = (val) => {
    setData({
      ...data,
      manufacture: val,
    });
  };
  const handleInstallation = (val) => {
    setData({
      ...data,
      installation: val,
    });
  };
  const handleMonth = (val) => {
    setData({
      ...data,
      month: val,
    });
  };
  const handleDevicelocation = (val) => {
    setData({
      ...data,
      devicelocation: val,
    });
  };
  const handleDepartment = (val) => {
    setData({
      ...data,
      devicedepartment: val,
    });
  };
  const handleAccessiblity = (val) => {
    setData({
      ...data,
      accessibility: val,
    });
  };
  const handlePhoto = (val) => {
    setData({
      ...data,
      photo: val,
    });
  };
  const handleRemark = (val) => {
    setData({
      ...data,
      remark: val,
    });
  };
  const handleURL = (val) => {
    setData({
      ...data,
      url: val,
    });
  };

  //Function to update device details
  const updateDeviceDetails = () => {
    let deviceData = {
      device_id: data.device_id,
      device_name: data.name,
      description: data.desc,
      type: data.type,
      make: data.make,
      model: data.model,
      serial_number: data.srlno,
      range: data.range,
      resolution: data.resolution,
      accuracy: data.accuracy,
      rating_capacity: data.rating,
      uom: data.uom,
      criticality: data.criticality ? Number(data.criticality) : null,
      location: data.devicelocation,
      year_of_manufacture: data.manufacture ? Number(data.manufacture) : null,
      year_of_installation: data.installation ? Number(data.installation) : null,
      expected_life_in_month: data.month ? Number(data.month) : null,
      department: data.devicedepartment,
      accessibility: data.accessibility,
      device_image: data.photo,
      remarks: data.remark,
      url: data.url,
      modified_by: userid,
      ticket_bearer: []
    };
    let checkValidation = validation();

    console.log("checkValidation",checkValidation)
    if (!idChars(data.device_id)) {
      return;
    } else  if(checkValidation){
      dispatch({
        type: DEVICE_UPDATE,
        payload: [id, deviceData],
      });
      navigate("/devicelist");
    }

  };

  //fetch device ID
  const sendID = () => {
    if (id !== null) {
      dispatch({
        type: "DEVICE_GET_SINGLE",
        payload: id,
      });
    }
  };

  //useEffects
  useEffect(() => {
    sendID();
  }, [id]);

  useEffect(() => {
    if (deviceGetSingle != undefined && deviceGetSingle != 0) {
      // console.log(deviceGetSingle);
      setData({
        device_id: deviceGetSingle.device_id,
        name: deviceGetSingle.device_name,
        desc: deviceGetSingle.description,
        type: deviceGetSingle.type,
        make: deviceGetSingle.make,
        model: deviceGetSingle.model,
        srlno: deviceGetSingle.serial_number,
        range: deviceGetSingle.range,
        resolution: deviceGetSingle.resolution,
        accuracy: deviceGetSingle.accuracy,
        rating: deviceGetSingle.rating_capacity,
        uom: deviceGetSingle.uom,
        criticality: deviceGetSingle.criticality,
        devicelocation: deviceGetSingle.location,
        manufacture: deviceGetSingle.year_of_manufacture,
        installation: deviceGetSingle.year_of_manufacture,
        month: deviceGetSingle.expected_life_in_month,
        devicedepartment: deviceGetSingle.department,
        accessibility: deviceGetSingle.accessibility,
        photo: deviceGetSingle.device_image,
        remark: deviceGetSingle.remarks,
        url: deviceGetSingle?.url,
      });
    }
  }, [deviceGetSingle]);

  //functions forinvalid data
  function idChars(str) {
    const specialChars = /^([a-zA-Z0-9-/@*]+\s)*[a-zA-Z0-9-/@*]+$/;
    return specialChars.test(str);
  }
  function snumChars(str) {
    const categoryChars = /^([0-9]+\s)*[0-9]+$/;
    return categoryChars.test(str);
  }
  function resolutionChar(str) {
    const specialResoltion = /^([a-zA-Z]+[0-9.]+\s)*[0-9.]+$/;
    return specialResoltion.test(str);
  }
  function accureccyChar(str) {
    // const specialAcc = /^[0-9-.]*[0-9-.]+$/;
    const specialAcc = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialAcc.test(str);
  }
  function yearChars(str) {
    const categoryChars = /^[0-9]*[0-9]+$/;
    return !categoryChars.test(str);
  }
  function rncChars(str) {
    const specialAcc = /^([a-zA-Z0-9-/&]+\s)*[a-zA-Z0-9-/&]+$/;
    return specialAcc.test(str);
  }
  function uomChars(str) {
    const specialAcc = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialAcc.test(str);
  }

  function validateChars(str, regex) {
    return regex.test(str);
  }


  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to Show Loading effect */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 offset-md-12">
            <div style={{ paddingTop: "25px" }}>
              {access !== null && access.u ? (
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the page from refreshing
                    // Handle the form submission logic here
                  }}
                  className="mt-5 border p-4 bg-light shadow rounded"
                >
                  <div className="formHeadings mb-2">Edit Device</div>

                  <div className="row">
                    <div className="mb-2 col-md-3">
                      <label>
                        Device ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        maxLength="200"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          handleDeviceID(e.target.value);
                        }}
                        defaultValue={data.device_id}
                      />
                      {Object.keys(deviceidErr).map((key) => {
                        if (!data.device_id) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {deviceidErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.device_id, patterns.idChars) || data.device_id === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Device ID
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Device Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        maxLength="200"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleName(e.target.value);
                        }}
                        defaultValue={data.name}
                      />
                      {Object.keys(devicenameErr).map((key) => {
                        if (!data.name) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {devicenameErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.name, patterns.idChars) || data.name === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Device Name
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Type<span className="text-danger">*</span>
                      </label>
                      {/* <input
                        type="text"
                        name="device"
                        className="form-control"
                        onChange={(e) => {
                          handleType(e.target.value);
                        }}
                        defaultValue={data.type}
                      /> */}
                      <select
                        name="devicetype"
                        id="devicetype"
                        disabled={screen}
                        className="form-select"
                        value={data.type}
                        onChange={(e) => {
                          handleType(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="measuring_device">Measuring Device</option>
                        <option value="iot">IoT</option>
                      </select>
                      
                      {data.type != "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Select Type
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Make<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleMake(e.target.value);
                        }}
                        defaultValue={data.make}
                      />
                      {Object.keys(makeErr).map((key) => {
                        if (!data.make) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {makeErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.make, patterns.areaChars) || data.make === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Make Name
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Model<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        maxLength="200"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleModel(e.target.value);
                        }}
                        defaultValue={data.model}
                      />
                      {Object.keys(modelErr).map((key) => {
                        if (!data.model) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {modelErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.model, patterns.areaChars) || data.model === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Model Name
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Serial Number</label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        maxLength="100"
                        className="form-control"
                        onChange={(e) => {
                          handleSerial(e.target.value);
                        }}
                        defaultValue={data.srlno}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Range<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleRange(e.target.value);
                        }}
                        defaultValue={data.range}
                      />
                      {Object.keys(rangeErr).map((key) => {
                        if (!data.range) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {rangeErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {data?.range!=='' ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Range 
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Resolution<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleResolution(e.target.value);
                        }}
                        defaultValue={data.resolution}
                      />
                      {Object.keys(resolutionErr).map((key) => {
                        if (!data.resolution) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {resolutionErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {data.resolution!==''?(
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Resolution 
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Accuracy<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleAccuracy(e.target.value);
                        }}
                        defaultValue={data.accuracy}
                      />
                      {Object.keys(accuracyErr).map((key) => {
                        if (!data.accuracy) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {accuracyErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {data.accuracy!==''? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Accuracy 
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Rating/Capacity</label>
                      <input
                        type="text"
                        name="device"
                        disabled={screen}
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleRating(e.target.value);
                        }}
                        defaultValue={data.rating}
                      />
                      {rncChars(data.rating) || data.rating === "" ? (
                        ""
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Rating/Capacity
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>UoM of Rating/Capacity</label>
                      <input
                        type="text"
                        name="device"
                        maxLength="100"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleUom(e.target.value);
                        }}
                        defaultValue={data.uom}
                      />
                      {uomChars(data.uom) || data.uom === "" ? (
                        ""
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid UOM
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Criticality<span className="text-danger">*</span>
                      </label>
                      <select
                        name="criticality"
                        disabled={screen}
                        className="form-select"
                        value={data.criticality}
                        onChange={(e) => {
                          handleCriticality(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      {Object.keys(criticErr).map((key) => {
                        if (!data.criticality) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {criticErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {validateChars(data.criticality, patterns.numChars) || data.criticality === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Criticality 
                        </div>
                      )}
                    </div>
                    {/* location */}
                    <div className="mb-2 col-md-3">
                      <label htmlFor="devicelocation">Location</label>
                      <input
                        name="devicelocation"
                        id="datepicker"
                        disabled={screen}
                        className="form-control"
                        maxLength="200"
                        value={data.devicelocation}
                        onChange={(e) => {
                          handleDevicelocation(e.target.value);
                        }}
                      />

                      {/* {locationChars(data.devicelocation) ||
                    data.devicelocation === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Location
                          </div>
                        ))} */}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Year of Manufacture</label>
                      <input
                        type="text"
                        disabled={screen}
                        name="device"
                        className="form-control"
                        onChange={(e) => {
                          handleManufacture(e.target.value);
                        }}
                        defaultValue={data.manufacture}
                      />
                      {data.manufacture !== null && yearChars(data.manufacture) ? (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Year of Manufacture
                        </div>
                      ) : ""}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Year of Installation</label>
                      <input
                        type="text"
                        disabled={screen}
                        name="device"
                        className="form-control"
                        onChange={(e) => {
                          handleInstallation(e.target.value);
                        }}
                        defaultValue={data.installation}
                      />
                      {data.installation !== null && yearChars(data.installation) ||
                        data.installation === "" ? (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Year of Installation
                        </div>
                      ) : ""}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Expected Life in Years</label>
                      <input
                        type="text"
                        disabled={screen}
                        name="device"
                        className="form-control"
                        onChange={(e) => {
                          handleMonth(e.target.value);
                        }}
                        defaultValue={data.month}
                      />
                      {data.month !== null && yearChars(data.month) || data.month === "" ? (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Device Life
                        </div>
                      ) : ""}
                    </div>
                    {/* Department */}
                    <div className="mb-2 col-md-3">
                      <label htmlFor="department">Department</label>
                      <input
                        name="department"
                        disabled={screen}
                        id="datepicker"
                        className="form-control"
                        maxLength="200"
                        value={data.devicedepartment}
                        onChange={(e) => {
                          handleDepartment(e.target.value);
                        }}
                      />

                      {/* {locationChars(data.devicelocation) ||
                    data.devicelocation === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Location
                          </div>
                        ))} */}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Network Accessibility</label>
                      <select
                        name="accessibility"
                        disabled={screen}
                        className="form-select"
                        onChange={(e) => {
                          handleAccessiblity(e.target.value);
                        }}
                      >
                        <option value="select">{data.accessibility}</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Device Image</label>
                      <input
                        type="file"
                        disabled={screen}
                        name="device"
                        className="form-control"
                        onChange={(e) => {
                          handlePhoto(e.target.value);
                        }}
                        defaultValue={data.photo}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Remarks</label>
                      <input
                        type="text"
                        disabled={screen}
                        name="device"
                        className="form-control"
                        onChange={(e) => {
                          handleRemark(e.target.value);
                        }}
                        defaultValue={data.remark}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>URL</label>
                      <input
                        type="text"
                        name="url"
                        disabled={screen}
                        className="form-control"
                        onChange={(e) => {
                          handleURL(e.target.value);
                        }}
                        defaultValue={data.url}
                      />
                    </div>
                    <div className="mb-2 col-md-12">
                      {screen ? '' :
                        <button
                          type="submit"
                          onClick={() => updateDeviceDetails()}
                          className="float-end saveButton"
                        >
                          Update
                        </button>}{" "}
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={() => navigate("/devicelist")}
                        className="backButton"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//exporting component
export default DeviceUpdate;
