import React, { useEffect } from "react";
import moment from "moment";
import S3BucketImage from "./s3BucketImages";

function ApprovalActivity(props) {
  const { MaintenanceActivity, activityName } = props;

  useEffect(() => {
    // console.log(props);
  }, [props]);

  const convertToDMS = (coordinate, isLatitude) =>{
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);
  
    const direction = isLatitude
        ? coordinate >= 0 ? 'N' : 'S'
        : coordinate >= 0 ? 'E' : 'W';
  
    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }

  return (
    <div style={{ margin: "auto" }}>
      <table className="table maint-activity-table-performer-details">
        <thead className="align-middle">
          <tr>
            <th colSpan="10" className="report-asset-details">
              {activityName}
            </th>
          </tr>
          <tr>
            <th colSpan="10" className="border border-dark">
              Approval Type -&nbsp;
              {MaintenanceActivity?.approvals?.sequence_approval_type
                ? MaintenanceActivity?.approvals?.sequence_approval_type
                    ?.charAt(0)
                    .toUpperCase() +
                  MaintenanceActivity?.approvals?.sequence_approval_type?.slice(
                    1
                  )
                : MaintenanceActivity?.approvals?.parallel_approval_type
                    ?.charAt(0)
                    .toUpperCase() +
                  MaintenanceActivity?.approvals?.parallel_approval_type?.slice(
                    1
                  )}
            </th>
          </tr>
          <tr>
            <th scope="col" className="text-break border border-dark text-center">
              Sequence Number
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark">
              Approver Name
            </th>
            <th scope="col" className="text-break border border-dark">
              Designation
            </th>
            <th scope="col" colSpan="2" className="text-break border border-dark" >
              Approved By - ID
            </th>
            <th scope="col" colSpan="1" className="text-break border border-dark">
              Approved Date & Time
            </th>
            <th scope="col" colSpan="1" className="text-break border border-dark">
              Approver Geo Location
            </th>
            <th scope="col" colSpan="1" className="text-break border border-dark">
              Comments
            </th>
            <th scope="col" colSpan="1" className="text-break border border-dark">
              Signature
            </th>

          </tr>
        </thead>
        {MaintenanceActivity?.length !== 0
          ? MaintenanceActivity?.approvals?.approver_info.map(
              (eachData, index) => {
                return (
                  <tbody style={{ width: "100%" }} key={index}>
                    <tr>
                      <td className="text-break border border-dark text-center align-middle">
                        {index + 1}
                      </td>
                      <td colSpan="2" className="text-break border border-dark align-middle">
                        {eachData?.approved_by?.first_name}&nbsp;
                        {eachData?.approved_by?.middle_name}&nbsp;
                        {eachData?.approved_by?.last_name}
                      </td>
                      <td className="text-break border border-dark align-middle" >
                        {eachData?.approved_by?.designation}
                      </td>
                      <td colSpan="2" className="text-break border border-dark align-middle" >
                        {eachData?.approved_by?.email}
                      </td>

                      <td colSpan="1" className="text-break border border-dark align-middle" >
                        {moment(eachData?.approved_date).format(
                          "MMMM Do YYYY | hh:mm:ss A"
                        )}
                      </td>
                      <td colSpan="1" className="text-break border border-dark align-middle" >
                        {eachData?.geo_loc?.coordinates?.length > 0 ? (
                          <div>
                            {`Lat: ${convertToDMS(eachData?.geo_loc?.coordinates[1], true)}`} 
                            <br />
                            {`Long: ${convertToDMS(eachData?.geo_loc?.coordinates[0], false)}`}
                          </div>
                        ) : (
                          <>
                            {"Lat: NA"}
                            <br />
                            {"Long: NA"}
                          </>
                        )}
                      </td>
                      <td colSpan="1" className="text-break border border-dark align-middle">
                        {eachData?.approver_comment?eachData?.approver_comment:'-'}
                      </td>
                      <td className="text-break overflow-hidden border border-dark align-middle text-center">
                        {eachData.approver_signature ? 
                          <S3BucketImage
                            imageUrl={eachData?.approver_signature}
                            widthSize={"100px"}
                          />: "-"}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            )
          : null}
      </table>
    </div>
  );
}

export default ApprovalActivity;
