//importing dependencies and packages
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import IMAGENAME from "../../../medias/media.index";
import { useSelector, useDispatch } from "react-redux";
import "../../../css/modules/report/workorderReport/commonstyle.css";
import "../../../css/modules/report/workorderReport/inspection.css"


import S3BucketImage from "./s3BucketImages";
import Loader from "../../../components/loader/loader";

// function of Inspection Report Preview page to download report
const InspectionReport = (insReportData) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.site.isLoading);
  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState([]);
  const [key, setKey] = useState("");
  const [selectedItem, setSelectedItem] = useState("Asset");

  const insReportDataArray = useSelector((state) => state.inspection.insReportData);

  // const insReportData =undefined;

  // if (insReportData !== null) {
  //   var inspCompletedTime = moment(insReportData.inspection.ins_sch_date)
  //     .utc()
  //     .format("LLL");
  // }

  // console.log("insReportData", insReportData);

  /////////////dispatch function to get inspection report data////////////
  const getInsReport = (insId) => {
    let idArray = insId.split(",")
    dispatch({
      type: "INSPECTION_REPORT",
      payload: { inspection_mid: idArray },
    });
  };
  if (insReportDataArray !== null) {
    const date = new Date(insReportDataArray?.inspection?.ins_completed_date);
    var newDate = subtractSeconds(
      date,
      insReportDataArray?.inspection?.inspection_duration
    );
  }
  //useEffects
  useEffect(() => {
    if (insReportDataArray !== null) {
      // console.log(insReportData);
    }
  }, [insReportDataArray]);

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getInsReport(id);
    }
    window.scrollTo(0, 0);
  }, []);

  //function to calculate start date(End date-Inspection Duration)
  function subtractSeconds(date, seconds) {
    date.setSeconds(date.getSeconds() - seconds);
    return date;
  }

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0]);
      setSelectedItem(location.state?.[1]);
    }
  }, []);

  useEffect(() => {
    // { console.log('imageSrc', imageSrc) }
  }, [imageSrc]);

  const convertToDMS = (coordinate, isLatitude) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    const direction = isLatitude
      ? coordinate >= 0 ? 'N' : 'S'
      : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }


  const momentDate = (date, type) => {
    
    if (type == 'time') {
      return `${moment.utc(moment.duration(date, 'seconds').asMilliseconds()).format("HH:mm:ss")}`
    }
    return `${moment(date).format("MMMM Do YYYY | hh:mm:ss A")}`
  }


  ///////////////////////////////HTML//////////////////////////////////
  return (
    <>
      {isLoading && <Loader />}
      {insReportDataArray !== undefined && insReportDataArray?.length > 0 && insReportDataArray.map((insReportData) => {
        return <div className="report-responsive main-div" >
          <div className="report-container">
            {(insReportData?.inspection?.trans_status === "approved" ||
              insReportData?.inspection?.trans_status === "auto_approved") && (
                <table className="table report-header-table"  >
                  <thead>
                    <tr>
                      <th scope="col" className="report-header-table-logo" >
                        <img src={IMAGENAME.logo} alt="ZongoVita logo" className="report-header-table-logo-image" />
                      </th>
                      <th colSpan="5" className="report-header-table-content" >
                        {`${insReportData.site.site_name} - ${insReportData.site.city}, ${insReportData?.site?.state}`}
                        <br />
                        <span className="report-header-table-content-span">
                          {`Report : ${insReportData.inspection.inspection_name}`}
                        </span>
                      </th>
                      <th className="report-header-table-logo">
                        <S3BucketImage imageUrl={insReportData.customer.cust_logo} widthSize={"auto"} />
                      </th>
                    </tr>
                    <tr className="align-middle border border-dark">
                      <th colSpan="3" className="border border-dark" >
                        {insReportData?.inspection?.start_date !== null ? insReportData?.inspection?.start_date ?
                          `${" "} Start Date & Time -${" "}${momentDate(insReportData?.inspection?.start_date)}${" "}`
                          : `${" "}Start Date & Time -${" "}${momentDate(newDate)}${" "}`
                          : `${" "}Start Date & Time - NA${" "}`
                        }
                      </th>
                      <th colSpan="3" className="border border-dark" >
                        {insReportData?.inspection?.end_date !== null ? insReportData?.inspection?.end_date ?
                          `End Date & Time -${" "}${momentDate(insReportData?.inspection?.end_date)}${" "}`
                          : `End Date & Time -${" "}${momentDate(insReportData?.inspection?.ins_sch_date)}${" "}`
                          : `End Date & Time - NA${" "}`
                        }
                      </th>
                      <th colSpan="1" className="border border-dark text-center">
                        Priority - &nbsp;
                        {insReportData?.inspection?.priority
                          ?.charAt(0)
                          ?.toUpperCase() +
                          insReportData?.inspection?.priority?.slice(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="3" className="border border-dark">
                        {console.log("s",insReportData?.inspection?.inspection_duration,momentDate(insReportData?.inspection?.inspection_duration, "time"))}
                        {`Inspection Duration (hh:mm:ss) - ${momentDate(insReportData?.inspection?.inspection_duration, "time")}`}</th>
                      <th colSpan="4" className="border border-dark">
                        {insReportData?.inspection?.trans_status === "auto_approved" ? "Approved Date & Time - Auto Approved" : `Approved Date & Time - ${momentDate(insReportData?.inspection?.ins_completed_date)}`}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="7" className="report-asset-details">
                        {insReportData?.parent?.type?.charAt(0).toUpperCase() + insReportData?.parent?.type?.slice(1)}{" "}Details
                      </th>
                    </tr>
                    <tr className="align-middle">
                      <th colSpan="3" className="border border-dark text-break">
                        {insReportData?.parent?.type?.charAt(0).toUpperCase() + insReportData?.parent?.type?.slice(1)}{" "}ID - {insReportData?.parent?.parent_id}
                      </th>
                      <th colSpan="2" className="border border-dark text-break">
                        {insReportData?.parent?.type?.charAt(0).toUpperCase() + insReportData?.parent?.type?.slice(1)}{" "} Name - {insReportData?.parent?.parent_name}
                      </th>
                      <th colSpan="2" className="border border-dark text-break">
                        {insReportData?.parent?.type?.charAt(0).toUpperCase() + insReportData?.parent?.type?.slice(1)}{" "} Location - {insReportData?.parent?.location}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="7" className="border border-dark text-break">
                        {insReportData?.parent?.type?.charAt(0).toUpperCase() + insReportData?.parent?.type?.slice(1)}{" "} Geo Location - Lat : {insReportData?.parent?.geo_loc?.coordinates?.length > 0 ? convertToDMS(insReportData?.parent?.geo_loc?.coordinates[1], true) : "NA"} & Long : {insReportData?.parent?.geo_loc?.coordinates?.length > 0 ? convertToDMS(insReportData?.parent?.geo_loc?.coordinates[0], false) : "NA"}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="7" className="report-asset-details" >
                        Task Details
                      </th>
                    </tr>
                  </thead>
                </table>
              )}
            <table className="table report-header-table" >
              <thead className="align-middle">
                <tr>
                  <th scope="col" colSpan='2' className="border border-dark text-break text-center">
                    Task No
                  </th>
                  <th colSpan='3' scope="col" className="border border-dark text-break">
                    Checkpoint
                  </th>
                  <th scope="col" colSpan='5' className="border border-dark text-break">
                    Standard Requirement
                  </th>
                  <th scope="col" colSpan='3' className="border border-dark text-break" >
                    Response
                  </th>
                  <th scope="col" colSpan='3' className="border border-dark text-break">
                    Comments
                  </th>
                  <th scope="col" colSpan='3' className="border border-dark text-break">
                    Before Image
                  </th>
                  <th scope="col" colSpan='3' className="border border-dark text-break">
                    After Image
                  </th>
                </tr>
              </thead>
              {insReportData?.checkpoint?.checkpoints.length !== 0
                ? insReportData?.checkpoint?.checkpoints.map((eachData, index) => {
                  return (
                    <tbody style={{ width: "100%" }} key={index}>
                      <tr>
                        <td colSpan='2' className="border border-dark text-center text-break align-middle">
                          {index + 1}
                        </td>
                        <td colSpan='3' className="border border-dark text-left text-break align-middle">
                          {eachData.checkpoint}
                        </td>
                        <td colSpan='5' className="border border-dark text-left text-break align-middle" >
                          {eachData.standard_requirement}
                        </td>
                        <td colSpan='3' className="border border-dark text-left text-break align-middle" >
                          {eachData.answer === "yes"
                            ? eachData.answer.charAt(0).toUpperCase() +
                            eachData.answer.slice(1)
                            : eachData.answer === "no"
                              ? eachData.answer.charAt(0).toUpperCase() +
                              eachData.answer.slice(1)
                              : eachData.answer === "not_applicable"
                                ? "Not Applicable"
                                : eachData.answer}
                        </td>
                        <td colSpan='3' className="border border-dark text-left text-break align-middle">
                          {eachData.cp_comment ? eachData.cp_comment : '-'}
                        </td>
                        <td colSpan='3' className="border border-dark text-center text-break align-middle">
                          {eachData.before_pic ? (
                            <S3BucketImage imageUrl={eachData.before_pic} widthSize={"6rem"} />) : "-"}
                        </td>
                        <td colSpan='3' className="border border-dark text-center text-break align-middle">
                          {eachData.after_pic ? (
                            <S3BucketImage imageUrl={eachData.after_pic} widthSize={"6rem"} />) : "-"}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
                : null}
            </table>
            <table className="table border border-dark report-header-table" >
              <tbody>
                <tr>
                  <th colSpan="8" className="report-additional-comment border border-dark text-start align-middle text-break">
                    <span>
                      Additional Comments : &nbsp;
                    </span>
                    {insReportData?.inspection.common_comment}
                  </th>
                </tr>
                <tr>
                  <th className="border border-dark text-start align-middle text-break" colSpan="2">
                    Performed by {insReportData?.performer.name && insReportData?.performer.name !== "undefined undefined undefined" ? ` - ${insReportData?.performer.name}` : " - -"}
                  </th>
                  <th colSpan="2" className="border border-dark text-start align-middle text-break">
                    {insReportData?.performer.email ? `Email - ${insReportData?.performer.email}` : "Email - -"}
                  </th>
                  <th colSpan="2" className="border border-dark text-start align-middle text-break">
                    {insReportData?.performer.designation ? `Designation - ${(insReportData?.performer.designation).replace("_", " ")}` : "Designation - -"}
                  </th>
                  <th colSpan="2" className="border border-dark text-start align-middle" >
                    {insReportData?.inspection?.signature ? <S3BucketImage imageUrl={insReportData?.inspection?.signature} widthSize={"100px"} /> : "-NA-"}
                    <br />
                    <span>Signature</span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="8" className="border border-dark text-start align-middle" >
                    {insReportData?.performer.name && insReportData?.performer.name !== "undefined undefined undefined" ? `Performer Geo Location - Lat : ${insReportData?.inspection?.geo_loc_performance?.coordinates?.length > 0 ? convertToDMS(insReportData?.inspection?.geo_loc_performance?.coordinates[1], true) : "NA"} & Long : ${insReportData?.inspection?.geo_loc_performance?.coordinates?.length > 0 ? convertToDMS(insReportData?.inspection?.geo_loc_performance?.coordinates[0], false) : "NA"}` :
                      `Performer Geo Location - Lat : NA & Long : NA`
                    }
                  </th>
                </tr>
                <tr>
                  <th colSpan="2" className="border border-dark text-start align-middle text-break">
                    {insReportData?.approver.name ? `Approved by - ${insReportData?.approver.name}` : insReportData?.inspection?.trans_status ===
                      "auto_approved" ? "Approved by - Auto Approved" : "Approved by - NA"}
                  </th>
                  <th colSpan="2" className="border border-dark text-start align-middle text-break">
                    {insReportData?.approver.email ? `Email - ${insReportData?.approver.email}` : "Email - NA"}
                  </th>
                  <th colSpan="2" className="border border-dark text-start align-middle text-break">
                    {insReportData?.approver.designation ? `Designation - ${(insReportData?.approver.designation).replace("_", " ")}` : "Designation - NA"}
                  </th>
                  <th colSpan="2" className="border border-dark text-start align-middle text-break">
                    {insReportData?.inspection?.approver_signature ?
                      <S3BucketImage
                        imageUrl={insReportData?.inspection?.approver_signature}
                        widthSize={"100px"}
                      /> : "-NA-"}
                    <br />
                    <span>Signature</span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="8" className="report-additional-comment border border-dark text-start align-middle text-break">
                    <span>
                      Comments : &nbsp;
                    </span>
                    {insReportData?.inspection?.approver_comment}
                  </th>
                </tr>
                <tr>
                  <th colSpan="8" className="border border-dark text-start align-middle text-break">
                    {insReportData?.approver.name ? `Approver Geo Location - Lat : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length > 0 ? convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[1], true) : "NA"} & Long : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length > 0 ? convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[0], false) : "NA"}` :
                      insReportData?.inspection?.trans_status === "auto_approved" ? `Approver Geo Location - Lat : NA & Long : NA` : `Approver Geo Location - Lat : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length > 0 ? convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[1], true) : "NA"} & Long : ${insReportData?.inspection?.geo_loc_approval?.coordinates?.length > 0 ? convertToDMS(insReportData?.inspection?.geo_loc_approval?.coordinates[0], false) : "NA"}`}
                  </th>
                </tr>
                {/* {insReportData?.inspection?.reopen_history && insReportData?.inspection?.reopen_history?.length > 0 &&
                  <tr>
                    <th
                      colSpan="8"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: "none",
                        padding: "7px",
                        borderTop: "1px black",
                        margin: "0px",
                        alignItems: "center",
                      }}
                    >
                      Inspection History
                    </th>
                  </tr>} */}
              </tbody>
            </table>
            {/* {insReportData?.inspection?.reopen_history?.length > 0 && insReportData?.inspection?.reopen_history?.map((el, i) => {
              return <>
                <table
                  className="table"
                  style={{
                    margin: "auto",
                    width: 1000,
                    border: "1px solid black",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    <tr>
                      <th colSpan="60" style={{
                        fontWeight: "bold",
                        border: "1px solid black",
                        backgroundColor: "#0862B4",
                        color: "white",
                        textAlign: "center",
                        borderBottom: 'none',
                        padding: "7px",
                        borderTop: '1px black',
                        margin: '0px',
                        alignItems: 'center'
                      }}>{i + 1} . Reopen Details - {el?.at !== null && el?.at !== '' && el?.at !== undefined ? moment(
                        el?.at
                      ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}</th>
                    </tr>
                    <tr>
                      <th colSpan="20" className="border border-dark align-middle">Reopened On - <span className="fw-light">
                        {`${el?.at !== null && el?.at !== '' && el?.at !== undefined ? moment(
                          el?.at
                        ).format("MMMM Do YYYY | hh:mm:ss A") : "NA"}`}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Reopened By - <span className="fw-light">
                        {`${el?.by?.first_name || ""} ${el?.by?.middle_name || ""} ${el?.by?.last_name || ""}`.trim() || "NA"}
                      </span>
                      </th>
                      <th colSpan="20" className="border border-dark align-middle">Signature - <span className="fw-light">
                        {el?.signature ?
                          <S3BucketImage
                            imageUrl={el?.signature}
                            widthSize={"100px"}
                          />
                          : "-NA-"}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="30" className="border border-dark align-middle">
                        Reopened Geo Location - Lat : <span className="fw-light">
                          {el?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.geo_loc?.coordinates[1], true)
                            : "NA"}
                        </span> & Long : <span className="fw-light">
                          {el?.geo_loc?.coordinates?.length > 0
                            ? convertToDMS(el?.geo_loc?.coordinates[0], false)
                            : "NA"}
                        </span>
                      </th>
                      <th colSpan="30" className="border border-dark align-middle">Comments - <span className="fw-light">
                        {`${el?.comment || "NA"}`}
                      </span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        colSpan="60"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#548DD4",
                          color: "white",
                          textAlign: "center",
                          borderBottom: "none",
                          padding: "7px",
                          borderTop: "1px black",
                          alignItems: "center",
                        }}
                      >
                        Task Details
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table"
                  style={{
                    width: 1000,
                    margin: "auto",
                    marginBottom: "none",
                    border: "1px solid black",
                    borderBottom: "none",
                    tableLayout: "fixed",

                  }}
                >
                  <thead style={{ verticalAlign: "middle" }}>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          wordWrap: "break-word",
                          width: "70px",
                          border: "1px solid black",
                        }}
                      >
                        Task No
                      </th>
                      <th
                        scope="col"
                        style={{ wordWrap: "break-word", border: "1px solid black" }}
                      >
                        Checkpoint
                      </th>
                      <th
                        scope="col"
                        style={{ wordWrap: "break-word", border: "1px solid black" }}
                      >
                        Standard Requirement
                      </th>
                      <th
                        scope="col"
                        style={{ wordWrap: "break-word", border: "1px solid black" }}
                      >
                        Response
                      </th>
                      <th
                        scope="col"
                        style={{ wordWrap: "break-word", border: "1px solid black" }}
                      >
                        Comments
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "110px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        Before Image
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "100px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        After Image
                      </th>
                    </tr>
                  </thead>
                  {el?.data?.checkpoints.length !== 0
                    ? el?.data?.checkpoints.map((eachData, index) => {
                      return (
                        <tbody style={{ width: "100%" }} key={index}>
                          <tr>
                            <td
                              style={{
                                wordWrap: "break-word",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                wordWrap: "break-word",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {eachData.checkpoint}
                            </td>
                            <td
                              style={{
                                wordWrap: "break-word",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {eachData.standard_requirement}
                            </td>
                            <td
                              style={{
                                wordWrap: "break-word",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              
                              {eachData.answer === "yes"
                                ? eachData.answer.charAt(0).toUpperCase() +
                                eachData.answer.slice(1)
                                : eachData.answer === "no"
                                  ? eachData.answer.charAt(0).toUpperCase() +
                                  eachData.answer.slice(1)
                                  : eachData.answer === "not_applicable"
                                    ? "Not Applicable"
                                    : eachData.answer}
                            </td>
                            <td
                              style={{
                                wordWrap: "break-word",
                                overflow: "hidden",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {eachData.cp_comment}
                            </td>
                            <td
                              style={{
                                wordWrap: "break-word",
                                overflow: "hidden",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {eachData.before_pic ? (
                                <S3BucketImage
                                  imageUrl={eachData.before_pic}
                                  widthSize={"6rem"}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td
                              style={{
                                wordWrap: "break-word",
                                overflow: "hidden",
                                border: "1px solid black",
                                borderBottom: "1px solid black",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {eachData.after_pic ? (
                                <S3BucketImage
                                  imageUrl={eachData.after_pic}
                                  widthSize={"6rem"}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                    : null}
                </table>
                <table
                  className="table"
                  style={{
                    margin: "auto",
                    width: 1000,
                    border: "1px solid black",
                    tableLayout: "fixed",
                    borderBottom: "none",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        colSpan="8"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#548DD4",
                          color: "white",
                          textAlign: "center",
                          borderBottom: "none",
                          padding: "7px",
                          borderTop: "1px black",
                          margin: "0px",
                          alignItems: "center",
                        }}
                      >
                        {`Performance Details`}
                      </th>
                    </tr>
                    <tr>
                      <th
                        colSpan="8"
                        style={{
                          textAlign: "left",
                          padding: "7px",
                          borderTop: "1px black solid",
                          borderBottom: "1px black solid",
                          margin: "0px",
                          alignItems: "center",
                          wordWrap: "break-word",
                          fontWeight: "normal",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Additional Comments : &nbsp;
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th style={{
                        border: "1px solid",
                        textAlign: "left",
                        verticalAlign: "middle",
                        wordWrap:'break-word'
                      }} colSpan="2">
                        {el?.data?.performed_by[0]?.first_name &&
                          el?.data?.performed_by[0]?.first_name !==
                          "undefined undefined undefined" ? (
                          <div>{`Performed by - ${el?.data?.performed_by[0]?.first_name + " " + el?.data?.performed_by[0]?.middle_name + " "  + el?.data?.performed_by[0]?.last_name}`}</div>
                        ) : (
                          "Performed by - -"
                        )}
                      </th>
                      <th colSpan="2" style={{
                        border: "1px solid",
                        textAlign: "left",
                        verticalAlign: "middle",
                        wordWrap:'break-word'
                      }}>
                        {el?.data?.performed_by[0]?.email ? (
                          <div>{`Email - ${el?.data?.performed_by[0]?.email}`}</div>
                        ) : (
                          "Email - -"
                        )}
                      </th>
                      <th colSpan="2"
                        style={{
                          border: "1px solid",
                          textAlign: "left",
                          verticalAlign: "middle",
                          wordWrap:'break-word'
                        }}
                      >
                        {el?.data?.performed_by[0]?.designation ? (
                          <div>{`Designation - ${(el?.data?.performed_by[0]?.designation).replace(
                            "_",
                            " "
                          )}`}</div>
                        ) : (
                          "Designation - -"
                        )}
                      </th>
                      <th
                        colSpan="2"
                        style={{
                          border: "1px solid",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {el?.data?.signature ? (
                          <S3BucketImage
                            imageUrl={el?.data?.signature}
                            widthSize={"100px"}
                          />
                        ) : (
                          "-NA-"
                        )}
                        <br />
                        <span>Signature</span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="8"
                        style={{
                          border: "1px solid",
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                      >
                        {el?.data?.performed_by[0]?.first_name &&
                          el?.data?.performed_by[0]?.first_name !==
                          "undefined undefined undefined" ? (
                          <div>{`Performer Geo Location - Lat : ${el?.data?.geo_loc_performance?.coordinates?.length > 0 ? convertToDMS(el?.data?.geo_loc_performance?.coordinates[1], true) : "NA"} & Long : ${el?.data?.geo_loc_performance?.coordinates?.length > 0 ? convertToDMS(el?.data?.geo_loc_performance?.coordinates[0], false) : "NA"}`}</div>
                        ) : (
                          `Performer Geo Location - Lat : NA & Long : NA`
                        )}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </>
            })} */}
          </div>
        </div>
      })
      }
    </>
  );
};

export default InspectionReport;
