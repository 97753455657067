import React from 'react'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";



function WorkorderList({ keyvalue, name, selectedReports, data, handleReportSelection, selectedItem, currentPage, convertSecondsToMinSec, itemsPerPage, startDate, endDate, searchInput }) {
    const navigate = useNavigate();

    const formatDate = (date) => date ? moment.utc(date).local().format("LLL") : '-';

    const renderCheckbox = (id, selectedReports, handleReportSelection) => (
        <input
            checked={selectedReports?.includes(id) || false}
            onChange={(e) => handleReportSelection(e.target.checked, id)}
            type="checkbox"
        />
    );

    const renderPreviewButton = (path, id, state) => (
        <button
            type="button"
            data-tip
            data-for="viewTip"
            onClick={() => navigate(`${path}/${id}`, { state })}
            className="pdf-preview-btn"
        >
            <FontAwesomeIcon icon={faFilePdf} size="lg" className="icons" />
            <ReactTooltip id="viewTip" place="bottom" effect="solid">Preview Report</ReactTooltip>
        </button>
    );

    return (
        <div className="d-none d-xl-block">
            <table className="table-bordered" width="100%">
                <thead>
                    <tr className="workorder-table-header">
                        {/* Selection Checkbox */}
                        {((keyvalue === "approved" && name !== "Ticket") || (name === "Ticket" && keyvalue !== "total")) && (
                            <th className="workorder-table-selection d-none d-md-table-cell">
                                <input
                                    checked={selectedReports?.length === data?.length && data?.length !== 0}
                                    onChange={(e) => {
                                        handleReportSelection(e.target.checked, "All")
                                    }}
                                    type="checkbox"
                                />
                            </th>
                        )}

                        {/* Common Columns */}
                        <th className="workorder-table-sno">S. No</th>

                        {name === "Ticket" && <th scope="col" className="common-styling-th">{name} ID</th>}

                        <th scope="col" className="common-styling-th">{name} Name</th>

                        <th scope="col" className="common-styling-th d-none d-md-table-cell">Site Name</th>

                        <th scope="col" className="common-styling-th">
                            {selectedItem !== "All" && selectedItem !== "all"
                                ? `${selectedItem?.charAt(0).toUpperCase() + selectedItem?.slice(1)} ID`
                                : "Asset / Device / Facility - ID"}
                        </th>

                        <th scope="col" className="common-styling-th">
                            {selectedItem !== "All" && selectedItem !== "all"
                                ? `${selectedItem?.charAt(0).toUpperCase() + selectedItem?.slice(1)} Name`
                                : "Asset / Device / Facility - Name"}
                        </th>

                        <th scope="col" className="common-styling-th">
                            {name === "Ticket" ? "Created Date & Time" : "Planned Date & Time"}
                        </th>

                        {(keyvalue === "waiting_for_approval" || keyvalue === "approved") && (
                            <th scope="col" className="common-styling-th">Performed Date & Time</th>
                        )}

                        <th scope="col" className="d-none d-md-table-cell">
                            {name === "Ticket" ? "Criticality" : "Priority"}
                        </th>

                        {/* Action Column for Ticket */}
                        {name === "Ticket" && ["waiting_for_approval", "assigned", "reassigned", "rejected", "yet_to_be_assigned", "cancelled", "reopened"].includes(keyvalue) && (
                            <th scope="col" className="actionColumn text-center">Action</th>
                        )}

                        {/* Action Column for Approved */}
                        {keyvalue === "approved" && (
                            <th scope="col" className="actionColumn text-center">Action</th>
                        )}
                    </tr>
                </thead>
                {/* Maintenance details based on status are mapped to read */}
                {data?.length > 0 ? (
                    data.map((eachData, index) => {
                        const { hours, minutes, seconds } = convertSecondsToMinSec(
                            eachData?.maintenance?.maintenance_duration ||
                            eachData?.inspection?.inspection_duration ||
                            eachData?.performance?.actual_duration
                        );

                        const commonState = [keyvalue, selectedItem, currentPage, itemsPerPage, startDate, endDate, searchInput, name];

                        return (
                            <tbody key={index} className="tbody">
                                <tr className="table-body-response">
                                    {((keyvalue === "approved" && name !== "Ticket") || (name === "Ticket" && keyvalue !== "total")) && (
                                        <td className="d-none d-md-table-cell workorder-table-sno">
                                            {keyvalue === "approved" && eachData?.inspection?._id && renderCheckbox(eachData.inspection._id, selectedReports, handleReportSelection)}
                                            {keyvalue === "approved" && eachData?.maintenance?._id && renderCheckbox(eachData.maintenance._id, selectedReports, handleReportSelection)}
                                            {name === "Ticket" && eachData?._id && renderCheckbox(eachData._id, selectedReports, handleReportSelection)}
                                        </td>
                                    )}

                                    <td className="workorder-table-sno">{(currentPage - 1) * itemsPerPage + (index + 1)}</td>

                                    {name === "Ticket" && (
                                        <td className="common-styling-th">
                                            {eachData?.maintenance?.maintenance_name || eachData?.inspection?.inspection_name || eachData?.id}
                                        </td>
                                    )}

                                    <td className="common-styling-th">
                                        {eachData?.maintenance?.maintenance_name || eachData?.inspection?.inspection_name || eachData?.name}
                                    </td>

                                    <td className="table-body-sitename d-none d-md-table-cell">
                                        {eachData?.site?.site_name || '-'}
                                    </td>

                                    <td className="common-styling-th">{eachData?.parent?.parent_id}</td>
                                    <td className="common-styling-th">{eachData?.parent?.parent_name}</td>

                                    <td className="common-styling-th">
                                        {formatDate(eachData?.maintenance?.maint_sch_date || eachData?.inspection?.ins_sch_date || eachData?.createdAt)}
                                    </td>

                                    {(keyvalue === "waiting_for_approval" || keyvalue === "approved") && (
                                        <td className="text-center">
                                            {formatDate(eachData?.maintenance?.performed_date || eachData?.inspection?.end_date || eachData?.performance?.performed_date)}
                                        </td>
                                    )}

                                    <td className="d-none d-md-table-cell text-center">
                                        {(eachData?.maintenance?.priority || eachData?.inspection?.priority)?.charAt(0).toUpperCase() +
                                            (eachData?.maintenance?.priority || eachData?.inspection?.priority)?.slice(1) ||
                                            (name === "Ticket" && (eachData?.service?.criticality || '-'))}
                                    </td>

                                    {name === "Ticket" && ["approved", "waiting_for_approval", "assigned", "reassigned", "rejected", "yet_to_be_assigned", "cancelled", "reopened"].includes(keyvalue) && (
                                        <td className="d-none d-md-table-cell text-center">
                                            {eachData?._id && renderPreviewButton("/ticket/report", eachData._id, commonState)}
                                        </td>
                                    )}

                                    {keyvalue === "approved" && (
                                        <td className="text-center">
                                            {eachData?.inspection?._id && renderPreviewButton("/inspection/report", eachData.inspection._id, commonState)}
                                            {eachData?.maintenance?._id && renderPreviewButton("/maintenance/report", eachData.maintenance._id, commonState)}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        );
                    })
                ) : (
                    <tbody>
                        <tr className="no-data">
                            <td colSpan="11">No Data Available</td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    )
}

export default WorkorderList
