import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import Logo from "../../components/logo";
import '../../css/layouts/common/commonHeader.css'

function Commonheader({ sitefilter }) {

  return (
    <div className="nabar-div">
      {/* Responsive Navbar */}
      <nav className="navbar1">
        {/* Left Side: Hamburger & Logo */}
        <div className="leftSection">
          <Sidebar />
          <div className="logo" >
            <Logo />
          </div>
        </div>

        {/* Right Side: Logout Button */}
        <div className="logoutbtn" >
          <LogoutButton sitefilter={sitefilter} />
        </div>
      </nav>
      <Outlet />
    </div>
  );
}

export default Commonheader;
