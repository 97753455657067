import React from 'react'
import { ResponsivePie } from '@nivo/pie';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardService } from '../../services/service.index';
import '../../css/modules/home/paiChart.css'

function PaiChart() {
  const [data, setData] = useState({})
  const selectedSite = useSelector((state) => state.site.selectedSiteList);
  const getCriticalityData = async () => {
    let arr = selectedSite?.selectedSite.map((site) => site._id);
    let id = Array.isArray(arr) ? arr.join(',') : arr;
    await dashboardService.criticalityData(id).then(function (res) {
      setData(res.data);
    })
      .catch(function (error) {
      });
  };

  const color = [
    "rgba(100, 255, 226, 1)", // criticality 1
    "rgba(0, 99, 185, 1)", // criticality 2
    "rgba(133, 250, 231, 0.8)" // criticality 3
  ];

  if (Object.keys(data).length > 0 && data.asset_criticality !== undefined) {
    var dataAsset = data.asset_criticality.map((d) => ({
      id: d.criticality,
      label: `Criticality ${d.criticality}`,
      value: d.count
    }));
  }
  if (data.device_criticality !== undefined) {
    var dataDevice = data.device_criticality.map((d) => ({
      id: d.criticality,
      label: `Criticality ${d.criticality}`,
      value: d.count
    }));
  }
  if (data.facility_criticality !== undefined) {
    var dataFacility = data.facility_criticality.map((d) => ({
      id: d.criticality,
      label: `Criticality ${d.criticality}`,
      value: d.count
    }));
  }
  useEffect(() => {
   
    if (Object.keys(data).length === 0) {
      getCriticalityData();
    }
  }, [data]);

  useEffect(() => {
    // Call API only if selectedSite has a valid value
    if (selectedSite && selectedSite.selectedSite?.length > 0) {
      getCriticalityData();
    }
  }, [selectedSite]);
  return (
    <div>
      <div className="paiChart-div d-block d-lg-flex">
        {/* Asset data */}
        {(Object.keys(data).length > 0 && data.asset_criticality !== undefined && data.asset_criticality.length > 0) &&
          <div className='chart-container'>
            <br />
            <span className='chart-title'>Asset Based on Criticality</span>
            <br />
            <br />
            <ResponsivePie
              data={dataAsset}
              colors={color}
              margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
              innerRadius={0.6}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            />
          </div>
        }
        {/* Device data */}
        {(Object.keys(data).length > 0 && data.device_criticality !== undefined && data.device_criticality.length > 0) &&
          <div className='chart-container'>
            <br />
            <span className='chart-title'>Device Based on Criticality</span>
            <br />
            <br />
            <ResponsivePie
              data={dataDevice}
              colors={color}
              margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
              innerRadius={0.6}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            />
          </div>
        }
        {/* Facility data */}
        {(Object.keys(data).length > 0 && data.facility_criticality !== undefined && data.facility_criticality.length > 0) &&
          <div className='chart-container'>
            <br />
            <span className='chart-title'>Facility Based on Criticality</span>
            <br />
            <br />
            <ResponsivePie
              data={dataFacility}
              colors={color}
              margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
              innerRadius={0.6}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            />
          </div>}
      </div>
    </div>
  )
}

export default PaiChart
