/* Description
+----------------------------------------------------------------------
 * 
   Author: [Mona Raychura & Ajit Shelake]
   Purpose: [
            - All API calls enter from this file (i.e. Response)
            - All API calls finally leave from this file (i.e. Request)
            - Implementing axios interceptor function to set headers and other information before making request
            - Response error handling is done here
            - Token refresh is managed here
            ]envir
+----------------------------------------------------------------------
 */

/* Package Imports */
import axios from 'axios';
import store from '../../redux/store.index';
import { CONSTANTS } from '../../constants/URLs/urlConstants';

/* Token handling Methods */
/*---------------------------------------------------------------------
     |  Purpose:  [Global methods for handling actions on token: 
                    1) getting Stored token from local storage
                    2) storing new token in local storage and reducer
                  ]
     *-------------------------------------------------------------------*/

let requestArray = [];
let isRefreshing = false;

const getToken = async () => {
  let token = localStorage.getItem("userToken")
  return token;
}
const redirectToLogin = async () => {
  requestArray = [];
  isRefreshing = false;
  let token = getToken();
  store.dispatch({
    type: "LOGOUT",
    payload: JSON.stringify(token)
  });
  localStorage.removeItem("userToken")
  alert(
    'Session has expired, please re-login.',
  )
}
/* Logout function */
/*---------------------------------------------------------------------
     |  Method [forceLogout]
     |
     |  Purpose:  [Function for logging out user if token is invalid ]
     |
     |  Parameters: [reason -- reason/message for logging out user]
     *-------------------------------------------------------------------*/

const forceLogout = async () => {
  requestArray = [];
  isRefreshing = false;
  localStorage.removeItem("userToken")
  store.dispatch({
    type: "LOGOUT_SUCCESS"
  });
}

const storeToken = async (resData) => {
  const data = {
    accessToken: resData.accessToken,
  };
  store.dispatch({ // updating state with new token
    type: "REFRESH_TOKEN_SUCCESS",
    payload: data,
  });
  localStorage.setItem('userToken', resData.accessToken);
}

/* Configuration for axios Interceptor */
/*---------------------------------------------------------------------
     |  Purpose: - For setting global constants to API request.
                ( eg: adding necessary headers, url, etc. )
                 - Globally accessible for making all HTTP requests 
     *-------------------------------------------------------------------*/

let serviceBase = axios.create({
  //add necessary headers
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

/* Intercepting all requests */
serviceBase.interceptors.request.use(
  async config => {
    getToken().then((token) => {
      config.withCredentials = true;
      if (token != null) {
        config.headers = {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }
    });
    return config;
  },
  error => {
    Promise.reject(error)
  });

/* Intercepting all responses */
serviceBase.interceptors.response.use(
  response => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const { status, data, config } = error.response;
    if (status === 401 || status === 403) {
      isRefreshing = true;
      isAlreadyFetchingAccessToken = false;
      return await resetTokenAndReattemptRequest(error);
    }
    if (status === 500) {
      alert("500 - Internal Server Error");
      return Promise.reject(error);
    }
    if (status === 204) {
      alert("204 - no content redirect to login");
      return forceLogout();
    }
    if (status === 401) {
      return forceLogout();
    }
    return Promise.reject(error);
  }
);
export default serviceBase;


// This is the list of waiting requests that will retry after the JWT refresh complete
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const resetToken = await getToken();
      if (!resetToken) {
        forceLogout();
        return Promise.reject(error);
      }
      const response = await refreshToken(resetToken);
      if (!response.data || response.status === 401 || response.status === 403) {
        forceLogout();
        return Promise.reject(error);
      }
      const newToken = response.data.accessToken;

      const retryOriginalRequest = new Promise((resolve, reject) => {
        addSubscriber(async access_token => {
          errorResponse.config.headers = {};
          serviceBase.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
          errorResponse.config.headers["Authorization"] = `Bearer ${access_token}`;
          errorResponse.config.headers["Content-Type"] = "application/json";
          try {
            const response = await axios(errorResponse.config);
            resolve(response);
          } catch (err) {
            reject(err);
          }
        });
      });

      onAccessTokenFetched(newToken);
      isAlreadyFetchingAccessToken = false;

      return retryOriginalRequest;
    }
  } catch (err) {
    isAlreadyFetchingAccessToken = false;
    return Promise.reject(err);
  }
}

/* Function for executing pending requests */
function onAccessTokenFetched(access_token) {

  if (subscribers.length === 0) {
    console.log("No subscribers to execute");
  }

  subscribers.forEach(callback => {
    if (typeof callback === "function") {
      try {
        callback(access_token);
      } catch (err) {
        console.log("Error", err);
      }
    } else {
      console.log("Invalid callback found in subscribers list:", callback);
    }
  });

  subscribers = [];
}

/* Function for queuing pending requests */
function addSubscriber(callback) {

  if (typeof callback !== "function") {
    console.log("Trying to add a non-function as a subscriber!", callback);
    return;
  }

  subscribers.push(callback);
}

const refreshService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true, // ✅ Ensures cookies are included in every request
  headers: {
    "Content-Type": "application/json"
  }
});

/* Function for making refresh token API call */
async function refreshToken() {
  try {
    const response = await refreshService.get(CONSTANTS.REFRESH_TOKEN, {
      withCredentials: true, // ✅ Ensure cookies are sent
    });

    if (response.status === 200) {
      storeToken(response.data);
      const newToken = response.data.accessToken;
      localStorage.setItem("userToken", newToken);
      isRefreshing = false;
      return response;
    }else{
      isRefreshing = false;
      forceLogout()
      return response;
    }

    
  } catch (error) {
    forceLogout()
    isRefreshing = false;
    throw error;
  }
}
