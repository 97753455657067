import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleLeft,faAngleRight,faAngleDoubleLeft,faAngleDoubleRight,} from "@fortawesome/free-solid-svg-icons";

function Pagination({itemsPerPage,handleItemsPerPageChange,currentPage,handleFirstPage,handlePrev,totalPages,handleNext,handleLastpage}) {
    return (
        <div className="pagination-limit-div d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="mb-3 mb-md-0 d-flex align-items-center">
                <label className="me-2">Items per page:</label>
                <select
                    value={itemsPerPage}
                    onChange={(e) => handleItemsPerPageChange(e.target.value)}
                    className="pagination-limit-select"
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            {/* Pagination Controls */}
            <div className="pagination pagination-div d-flex flex-wrap align-items-center justify-content-center">
                <button className="pagination-btn" disabled={currentPage === 1} onClick={handleFirstPage}>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} color={currentPage === 1 ? "#ccc" : "steelblue"} />
                </button>
                <button className="pagination-btn" disabled={currentPage === 1} onClick={handlePrev}>
                    <FontAwesomeIcon icon={faAngleLeft} color={currentPage === 1 ? "#ccc" : "steelblue"} />
                </button>

                <span className="pagination-showing text-center mx-3">
                    Showing {currentPage} of {totalPages} pages
                </span>

                <button className="pagination-btn" disabled={currentPage === totalPages} onClick={handleNext}>
                    <FontAwesomeIcon icon={faAngleRight} color={currentPage === totalPages ? "#ccc" : "steelblue"} />
                </button>
                <button className="pagination-btn" disabled={currentPage === totalPages} onClick={handleLastpage}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} color={currentPage === totalPages ? "#ccc" : "steelblue"} />
                </button>
            </div>
        </div>
    )
}

export default Pagination
