import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import InspectionReport from "./inspectionReport";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import MaintenanceReport from "./maintenanceReport";
import Commonheader from "../../../layouts/common/commonHeader";
import "../../../css/modules/report/workorderReport/inspection.css"



const GenrateMainpdf = () => {

    const componentRef = useRef();
    const [key, setKey] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState("Asset");
    const [fileName, setFileName] = useState(""); // To set Custom file name

    const maintReportDataArray = useSelector(
        (state) => state.maintenance.maintReportData
    );
    const [data, setData] = useState({
        currentPage: '',
        itemsPerPage: '',
        startDate: '',
        endDate: '',
        searchInput: '',
        name: '',
    });
    // console.log(insReportData);


    const handlePrint = () => {
        componentRef.current.handlePrint();
    };

    useEffect(() => {
        // console.log(location);
        // console.log(location.state);
        // console.log(location.state[0]);
        if (location.state !== undefined && location.state !== null) {
            setKey(location.state?.[0])
            setSelectedItem(location.state?.[1])
            const dynamicFileName = generateFileName();
            setFileName(dynamicFileName);
            setData({
                currentPage: location.state?.[2],
                itemsPerPage: location.state?.[3],
                startDate: location.state?.[4],
                endDate: location.state?.[5],
                searchInput: location.state?.[6],
                name: location.state?.[7],
            })
        }
    }, [maintReportDataArray])

    // Function to generate the custom file name dynamically
    const generateFileName = () => {
        // Implement your logic here to generate the file name
        // Example: return `inspection_${key}_${selectedItem}.pdf`;
        if (!Array.isArray(maintReportDataArray) || maintReportDataArray.length === 0) {
            return "Report_NoDataAvailable_V1.09.pdf";
        }

        // Iterate over the array and extract required information
        const fileDetails = maintReportDataArray.map((item, index) => {
            const parentId = item?.parent?.parent_id || "";
            const endDate = item?.maintenance?.maint_completed_date
                ? moment(item?.maintenance?.maint_completed_date).format("YYYY-MM-DD")
                : "";
            return `${parentId}_${endDate}`;
        });

        // Join details with a separator for a comprehensive filename
        const fileName = `${fileDetails?.length > 1 ? `Merged_Maintenance_Reports` : 'Maintenance_Report_' + fileDetails.join("_")}_V1.09.pdf`;

        return fileName;
    };

    const handleBeforePrint = () => {
        document.title = fileName;  // Set custom title
    };
    
    const handleAfterPrint = () => {
        document.title = fileName; // Reset to default after printing
    };



    return (
        <div>
            <Commonheader />
            <h4 className="listHeadings">Maintenance Report</h4>
            {/* Back Btn */}
            <div className="container">
                <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <button
                        type="button"
                        onClick={() => {
                            navigate("/maintenancestatus", { state: [key, selectedItem?.toLowerCase(), data?.currentPage, data?.itemsPerPage, data?.startDate, data?.endDate, data?.searchInput] })
                        }}
                        className="backButton my-2 my-sm-0 mx-sm-4 work-order-report-btn"
                    >
                        Back
                    </button>
                    <ReactToPrint
                        trigger={() => (
                            <button
                                type="button"
                                onClick={handlePrint}
                                className="saveButton my-2 my-sm-0 mx-sm-4 download-btn work-order-report-btn"
                            >
                                Download
                            </button>
                        )}
                        content={() => componentRef.current}
                        documentTitle={fileName} // Set the custom file name
                        onBeforePrint={handleBeforePrint}   // Set before print
                        onAfterPrint={handleAfterPrint}     // Reset after print
                        pageStyle={`
                    @page {
                        size: 'A4 landscape';
                        margin: 5%;
                    }
                    @media print {
                        #page-header {
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            height: 50px;
                            background-color: lightgray;
                            padding: 10px;
                        }
                    #content {
                        page-break-before: always;
                    }   
                }`}
                    />
                </div>
            </div>
            <div ref={componentRef} className="report-pdf" >
                <div>
                    {/* Add the necessary content here */}
                    <MaintenanceReport />
                </div>
            </div>
        </div>
    );
    // }
}

export default GenrateMainpdf;
