import React, { useState, useEffect } from "react";
import Commonheader from "../../../layouts/common/commonHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";
import "../../../css/modules/workorder/schStatusStyle.css";
import WorkStatus from "../workStatus";
import { useLocation } from "react-router-dom";


function TicketStatus() {

  const isLoading = useSelector((state) => state.ticket.isLoading);
  const [key, setKey] = useState("");
  const [assetType, setAssetType] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const location = useLocation();


  // console.log(selectedItem);

  const dispatch = useDispatch();

  const ticketStatus = useSelector((state) => state?.ticket?.ticketStatus);
  const userid = useSelector(state => state?.auth?.userid);
  const selectedSite = useSelector((state) => state.site.selectedSiteList);




  // console.log(key);

  // Function to handle next button
  let totalPages = 0;
  var ticketList = [];
  if (ticketStatus?.result !== undefined) {
    ticketList = ticketStatus?.result;
  }
  if (ticketStatus?.total_count !== undefined) {
    totalPages = Math.ceil(ticketStatus?.total_count / itemsPerPage);
  }

  const handleNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };
  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e?.target?.value);
  };

  //Function to handel clear
  const handleClearButton = () => {
    setSearchInput("");
  };

  const handleTabKey = (val) => {
    // console.log('Tab key val', val);
    setKey(val)
    setCurrentPage(1);
  }
  const handleType = (val) => {
    // console.log('Type val', val);
    setAssetType(val.toLowerCase())
    setCurrentPage(1);
  }

  
  const handleStartDate = (e) => {
    // console.log(e.target.value);
    e.preventDefault();
    setStartDate(e?.target?.value)
  }
  const handleEndDate = (e) => {
    // console.log(e.target.value);
    e.preventDefault();
    setEndDate(e?.target?.value)
  }
 
  const handleStartDateoFDashboard = (e) => {
    setStartDate(e);
  };

  const handleEndDateoFDashboard = (e) => {
    setEndDate(e);
  };
  useEffect(() => {
    if (currentPage && currentPage > 0) {
      dispatch({
        type: "TICKET_STATUS",
        payload: [currentPage, itemsPerPage, key, assetType, userid, startDate, endDate, searchInput],
      });
    }
  }, [currentPage, itemsPerPage, key, assetType, userid, startDate, endDate, searchInput,selectedSite]);

  useEffect(() => {
    // console.log(location.state, "location.state - inspection status");
    // console.log(typeof(location.state));
    // console.log(typeof(location.state) === 'object');
    // console.log(location.state, Object.keys(location.state).length);
    // if (location.state !== undefined && location.state !== null) {
      if (location.state && Object.keys(location.state).length !== 1) {
      setKey(location.state?.[0]);
      setAssetType(location.state?.[1]);
      setCurrentPage(location.state?.[2]);
      setItemsPerPage(location.state?.[3]);
      setStartDate(
        location.state?.[4] === "Invalid date"
          ? new Date().toISOString().split("T")[0]
          : location.state?.[4]
      );
      setEndDate(
        location.state?.[5] === "Invalid date"
          ? new Date().toISOString().split("T")[0]
          : location.state?.[5]
      );
      setSearchInput(location.state?.[6]);
    }
  }, [location.state]);
 

  return (
    <div>
      <Commonheader />
      {isLoading && (
        <div className="">
          <Loader />
        </div>
      )}
      <WorkStatus
        sendTabKeyData={handleTabKey}
        sendType={handleType}
        data={ticketList}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleFirstPage={handleFirstPage}
        handleLastpage={handleLastpage}
        handleItemsPerPageChange={handleItemsPerPageChange}
        handleSearchInputChange={handleSearchInputChange}
        handleClearButton={handleClearButton}
        searchInput={searchInput}
        totalPages={totalPages}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        name={"Ticket"}
        startDate={startDate}
        handleStartDate={handleStartDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
        handleStartDateoFDashboard={handleStartDateoFDashboard}
        handleEndDateoFDashboard={handleEndDateoFDashboard}
      />

    </div >
  );
}

export default TicketStatus;
