/* Feature - lading page / home page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React from "react";
// import "../home/home.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Commonheader from "../../layouts/common/commonHeader";
import StatusChart from './statusChart';
import PaiChart from "./paiChart";
import { Outlet } from 'react-router-dom';
import Dashboard from "../../layouts/dashboard/dashboard";
import StatusChartFA from "../FA/StatusChartFA";
import Verification from "../Verification/VerificationDashboard";
import '../../css/modules/home/home.css'


// function of home page
const Home = () => {

  // Zongo cyan color code = #64FFE2
  // Zongo blue color code = #0063B9
  // Zongo dark blue  color code = #021D37

  const dispatch = useDispatch();

  //////////////// fetch user role from redux/////////////////
  const role = useSelector((state) => state.auth.role);
  const access = useSelector((state) => state.access.accessData);
  const accessFA = useSelector((state) => state.access.dashboard_accounting);
  const accessAMM = useSelector((state) => state.access.dashboard);
  const accessPlant = useSelector((state) => state.access.dashboard_plant);
  const dashboard_criticality = useSelector((state) => state.access.dashboard_criticality);

  /////////// dispatch for role get/////////////
  useEffect(() => {
    // console.log('role', role)
    const roleCode = role[0];
    // console.log(roleCode);
    if (roleCode !== null) {
      // console.log(roleCode);
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role]);

  useEffect(() => {
    // console.log(access);
  }, [access]);


  return (
    <div
      style={{
        backgroundColor: '#f5f5f5'
      }}
    >
      <div className="overflowX">
        {/* Header of home page */}
        {/* <Dashboard selectedTab={'dashboard'} /> */}
        <Commonheader />
        {
          (accessPlant !== null || accessFA !== null) && (accessPlant?.r || accessFA?.r) &&
          <div className="verification">
            <Verification />
          </div>
        }
        {/* First & Second component of landing page - Inspection & Maintnance status  */}
        {accessAMM !== null && accessAMM?.r && <div>
          <StatusChart />
          <br />
          {/* Third component of landing page - asset / device & facility count based on criticality in paichart */}
          {dashboard_criticality !== null && dashboard_criticality?.r &&
            <div>
              <div className="asset-criticality" >
                Total Asset by Criticality
              </div>
              <PaiChart />
              <br />
            </div>}
        </div>
        }
        {/* ........... Dashboard for Verification ............. */}
        {accessFA !== null && accessFA?.r &&
          <StatusChartFA />
        }
      </div>
    </div>

  );
};

export default Home;
