//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function GenexDailyReport({ responseData, filterData, chklname, docNumber, depname, malur, medopharm }) {

    const [resData, setResData] = useState([])
    const startDate = moment(filterData?.selectedStartDate, "DD MMM YY");
    const endDate = moment(filterData?.selectedEndDate, "DD MMM YY");



    // header details
    const data1 = {
        data: responseData?.parent?.site_name,
        colSpan: '5'
    }
    const data2 = [];

    // Extract unique dates from responseData.checklist
    const uniqueDates = [...new Set(
        responseData?.checklist
            ?.map(item => 
                moment(item.ins_sch_date)
                    .utcOffset(330)                   // Adjusting to IST (UTC +5:30)
                    .format('YYYY-MM-DD')             // Extract date in YYYY-MM-DD format
            )
            .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
    )];
    

    uniqueDates.forEach(date => {
        data2.push({
            data: `Date ${moment(date).format("DD/MM/YY")}`, // Formatting date
            colSpan: '1'
        });
    });
    const uniqueAssets = new Map();
    const uniqueAssets2 = new Map();
    const uniqueTime = new Map();

    let uniqueNames = []

    uniqueAssets.set("Time", { data: "Time", textAlign: 'left', rowSpan: '2', colSpan: '2' });

    responseData?.checklist?.forEach(el => {
        if (el?.asset_id && !uniqueAssets.has(el.asset_id)) {
            uniqueNames.push(el.asset_id);
            uniqueAssets.set(el.asset_id, { data: `${el?.asset_name} (${(el?.asset_id).trim().split('/').pop()})`, textAlign: 'center', data1: `${el.asset_id}`, colSpan: '2' });
            uniqueAssets2.set(el.asset_id, { data: "ON/OFF", textAlign: 'center', colSpan: '2' });
        }

        let formattedTime = moment(el?.ins_sch_date)
            .add(15, 'minutes')  // Add 30 minutes to round to the nearest hour
            .startOf('hour')     // Set to the start of the next hour
            .format("hh:mm A");

        if (formattedTime && !uniqueTime.has(formattedTime)) {
            uniqueTime.set(formattedTime, formattedTime);
        }

    });

    const tableHeaders = Array.from(uniqueAssets.values());
    const tableHeaders2 = Array.from(uniqueAssets2.values());
    const chunkArray = (array, chunkSize) => {
        const result = [];
        const firstElement = array[0]; // Always keep the first element (index 0)

        let start = 1; // Start processing from index 1
        while (start < array.length) {
            const chunk = [firstElement, ...array.slice(start, start + chunkSize - 1)];
            result.push(chunk);
            start += chunkSize - 1;
        }

        return result;
    };
    const chunkArray2 = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };
    const chunkedHeaders = chunkArray(tableHeaders, 7);
    const chunkedHeaders2 = chunkArray2(tableHeaders2, 6);
    let time = Array.from(uniqueTime.values());
    time = time.sort((a, b) => {
        return moment(a, "hh:mm A").diff(moment(b, "hh:mm A"));
    });

    const response = [];


    // Calculate the day difference
    const dayDifference = endDate.diff(startDate, 'days') + 1;

    // for(let j =0;j<dayDifference;j++){
    //     let res = [];
    //     chunkedHeaders?.forEach((el, i) => {
    //         time.forEach(currentTime => {
    //             const rowData = {
    //                 time: {
    //                     value: currentTime,
    //                     style: { textAlign: 'left', colSpan: '2' },
    //                 }
    //             }
    //             el.forEach(header => {
    //                 if (header.data !== 'Time') { // Skip the 'Time' header
    //                     const assetName = header.data1;


    //                     const matchingChecklistItem = responseData?.checklist.find(
    //                         (item) => {
    //                             let formattedTime = moment(item?.ins_sch_date)
    //                                 .add(30, 'minutes')  // Add 30 minutes to round to the nearest hour
    //                                 .startOf('hour')     // Set to the start of the next hour
    //                                 .format("hh:mm A");

    //                             return formattedTime === currentTime && item.asset_id === assetName
    //                         }
    //                     );

    //                     const matchingChecklistItems = responseData?.checklist.filter((item) => {
    //                         let formattedTime = moment(item?.ins_sch_date)
    //                             .add(30, 'minutes')
    //                             .startOf('hour')
    //                             .format("hh:mm A");

    //                         return formattedTime === currentTime && item.asset_id === assetName;
    //                     });

    //                     if (matchingChecklistItems) {
    //                         rowData[assetName] = {
    //                             value: matchingChecklistItems[j]?.checkpoints[0]?.answer !== '' ? matchingChecklistItems[j]?.checkpoints[0]?.answer : '-',
    //                             style: { textAlign: 'center', colSpan: '2' },
    //                         };
    //                     } 

    //                 }
    //             });

    //             res.push(rowData);
    //         });
    //     })
    //     response.push(chunkArray2(res, 24))
    // }




    // new 
    const checklistMap = new Map();

    // Preprocess checklist data for faster lookup
    responseData?.checklist?.length > 0 && responseData?.checklist.forEach(item => {
        const formattedTime = moment(item?.ins_sch_date)
            .add(30, 'minutes')
            .startOf('hour')
            .format("hh:mm A");

        const key = `${formattedTime}_${item.asset_id}`;
        if (!checklistMap.has(key)) {
            checklistMap.set(key, []);
        }
        checklistMap.get(key).push(item);
    });



    for (let j = 0; j < uniqueDates?.length; j++) {
        let res = [];
        chunkedHeaders?.forEach(el => {
            time.forEach(currentTime => {
                const rowData = {
                    time: {
                        value: currentTime,
                        style: { textAlign: 'left', colSpan: '2' },
                    },
                };

                el.forEach(header => {
                    if (header.data !== 'Time') { // Skip the 'Time' header
                        const assetName = header.data1;
                        const key = `${currentTime}_${assetName}`;
                        const matchingChecklistItems = checklistMap.get(key) || [];

                        rowData[assetName] = {
                            value: matchingChecklistItems[j]?.checkpoints[0]?.answer || '-',
                            style: { textAlign: 'center', colSpan: '2' },
                        };
                    }
                });

                res.push(rowData);
            });
        });

        response.push(chunkArray2(res, 24));
    }

    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            {response?.map((resObj, index) => {
                return chunkedHeaders?.map((el, i) => {
                    return <div key={i} style={{ marginBottom: '50px', pageBreakAfter: 'always' }}>
                        <ReportHearder
                            data1={data1}
                            data2={data2[index]}
                            // data3={data3}
                            custLogo={resData?.customer?.cust_logo}
                            Genex={true}
                        />
                        <ReportTable
                            headerData={el}
                            headerData2={chunkedHeaders2[i]}
                            verticalBodyData={resObj[i]}
                        />
                    </div>
                })
            })}
        </div>
    )
}

export default GenexDailyReport
