import React from 'react'
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from "react-tooltip";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useSelector, } from "react-redux";
import '../../css/modules/home/statusComponent.css'




function StatusComponent(props) {

    const { startDate, endDate, handleDateChange } = props;
    const [status, setStatus] = useState(null)
    const [cardOpen, setCardOpen] = useState(false); // to open filter of inspection card
    const sites_id = useSelector((state) => state.auth?.sites_id && state.auth?.sites_id[0]);

    const today = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone: "UTC",
    }).format(new Date());

    //open or close the filter based on Onclick  ///
    const openInsOption = () => {
        setCardOpen(!cardOpen);
    };

    const handleClear = () => {
        window.location.reload(true)
    }

    useEffect(() => {
        if (props.data !== undefined) {
            setStatus(props.data)
        }
    }, [props.data, status])

    const CustomInput = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            onFocus={(e) => e.target.blur()} // Prevent keyboard
            onTouchStart={(e) => e.target.blur()} // Ensure mobile support
            className="custom-date-input"
            placeholder='click to select date'
        />
    );

    function StatusCard({ parent, title, count, handlefunction, tooltip_data, percentage }) {

        // Set the maximum font size and the threshold for reducing font size
        const thresholdLength = 24; // characters
        return (
            <div className='status-card-div' onClick={handlefunction} >
                <div>
                    <div className="d-flex flex-column justify-content-between">
                        <div className="percentage-div">
                            {percentage !== undefined ?
                                <>
                                    <p className={`dynamic-text ${count == null ? "small-font" : "large-font"}`} >
                                        {percentage} %
                                    </p>
                                    <p className='count' >
                                        {count === null ? "Loading..." : count} <span>{parent === 'ticket' ? "Ticket" : "Activity"}</span>
                                    </p>
                                </>
                                :
                                <>
                                    <p className={`dynamic-text ${count == null ? "small-font" : "large-font"}`} >
                                        {count == null ? "Loading..." : count}
                                    </p>
                                    <p className='count'>
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="w-100 m-0 p-0">
                    <p className={`status-title ${title.length > thresholdLength ? 'font-small' : 'font-large'}`}
                    >{title}</p>
                </div>
            </div>
        )
    }
    return (
        <div class="m-auto">
            <div className="row inspection-card" >
                {/* Inspection Card */}
                <div className="d-flex w-100">
                    <div
                        className="card-name"
                    >
                        {props.name}
                        <div>
                            {startDate !== null && endDate !== null && (props.name !== "Maintenance Status") ?
                                <p className="text-secondary">
                                    {moment(startDate).local().format("Do MMMM YYYY")} To {moment(endDate).local().format("Do MMMM YYYY")}
                                </p>
                                : null}
                            {startDate !== null && endDate !== null && (props.name === "Maintenance Status") ?
                                <p className="text-secondary">
                                    {moment(startDate).startOf('week').format("Do MMMM YYYY")} To {moment(endDate).endOf('week').format("Do MMMM YYYY")} (Week - {moment(startDate).week()})
                                </p>
                                : null}
                            {((props.name === "Maintenance Status") && (startDate === null && endDate === null)) ?
                                <p className="text-secondary">{moment().startOf('week').format("Do MMMM YYYY")} To {moment().endOf('week').format("Do MMMM YYYY")} (Week - {moment().week()})</p>
                                :
                                ((props.name === "Inspection Status") && (startDate === null && endDate === null)) ?
                                    <p className="text-secondary">{moment(today).local().format("Do MMMM YYYY")}</p>
                                    :
                                    ((props.name === "On-Demand Status") && (startDate === null && endDate === null)) ?
                                        <p className="text-secondary">{moment(today).local().format("Do MMMM YYYY")}</p>
                                        :
                                        ((props.name === "Ticket Status") && (startDate === null && endDate === null)) ? <p className="text-secondary">{moment(today).local().format("Do MMMM YYYY")}</p>
                                            :
                                            null
                            }
                        </div>
                    </div>
                    <div className='inspection-card-collapsed' >
                        {!cardOpen ? (
                            <button
                                type="button"
                                className="border-0 bg-transparent text-white"
                                onClick={openInsOption}
                                data-tip
                                data-for="openCloseCal"
                            >
                                <FontAwesomeIcon icon={faCalendar} size="lg" className="text-secondary" />
                                <ReactTooltip
                                    id="openCloseCal"
                                    place="bottom"
                                    effect="solid"
                                >
                                    Custom Date
                                    <br />
                                    Open Calendar
                                </ReactTooltip>
                            </button>
                        )
                            :
                            (
                                <button
                                    type="button"
                                    className="border-0 bg-transparent text-white float-end"
                                    onClick={openInsOption}
                                    data-tip
                                    data-for="CloseCal"
                                >
                                    <div>
                                        <FontAwesomeIcon icon={faSquareXmark} style={{ color: 'black' }} size="lg" />
                                    </div>
                                    <ReactTooltip
                                        id="CloseCal"
                                        place="bottom"
                                        effect="solid"
                                    >
                                        Custom Date
                                        <br />
                                        Close Calendar
                                    </ReactTooltip>
                                </button>
                            )}
                        {cardOpen && (
                            <div className="d-block d-lg-flex p-2 justify-content-between inspection-card-expanded bg-white">
                                {props.name == "Maintenance Status" ? <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    minDate={sites_id === '6422f56e26037ff46450359d' ? new Date("2024-06-21") : null}
                                    dateFormat="w/y"
                                    withPortal
                                    showWeekNumbers
                                    showWeekPicker
                                    customInput={<CustomInput />}
                                    onFocus={(e) => e.target.blur()}  // ✅ Prevents keyboard opening
                                    onTouchStart={(e) => e.target.blur()} // ✅ Ensures mobile browsers respect it
                                    wrapperClassName="full-width-datepicker"

                                /> :
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={new Date()}
                                        minDate={sites_id === '6422f56e26037ff46450359d' ? new Date("2024-06-21") : null}
                                        selectsRange
                                        withPortal
                                        placeholderText="Click to select date"
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<CustomInput />}
                                        onFocus={(e) => e.target.blur()}  // ✅ Prevents keyboard opening
                                        onTouchStart={(e) => e.target.blur()} // ✅ Ensures mobile browsers respect it
                                        wrapperClassName="full-width-datepicker"
                                    />
                                }
                                &nbsp;
                                <div>
                                    <button className='clear-btn' onClick={handleClear} >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Maintenance status bar */}

                {props.name !== "On-Demand Status" && props.name !== "Ticket Status" &&
                    <div className="justify-content-evenly row" style={{ gap: '10px' }}>
                        {/* Total status */}
                        <StatusCard
                            title={"Total Planned"}
                            handlefunction={props.handleTotal}
                            count={!status?.totalPlanned ? 0 : status.totalPlanned}
                            // count={(status === null || status.totalPlanned === '') ? null : status.totalPlanned}
                            tooltip_data={props.info.total_planned} />
                        {/* Executed : waiting_for_approval */}
                        <StatusCard
                            title={"Waiting For Approval"}
                            handlefunction={props.handlewaiting}
                            count={!status?.waitingApproval ? 0 : status.waitingApproval}
                            // count={(status === null || status.waitingApproval === '') ? null : status.waitingApproval}
                            tooltip_data={props.info.waiting_for_approval}
                            percentage={!status?.waitingApproval && !status?.totalPlanned ? 0 : Math.round((status.waitingApproval / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.waitingApproval === '' && status.totalPlanned === '') ? null : Math.round((status.waitingApproval / status.totalPlanned) * 100 || 0)} /> */}
                        {/* Completed */}
                        <StatusCard
                            title={"Completed"}
                            handlefunction={props.handleCompleted}
                            count={!status?.completed ? 0 : status.completed}
                            // count={(status === null || status.completed === '') ? null : status.completed}
                            tooltip_data={props.info.completed}
                            percentage={!status?.completed && !status?.totalPlanned ? 0 : Math.round((status.completed / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.completed === '' && status.totalPlanned === '') ? null : Math.round((status.completed / status.totalPlanned) * 100 || 0)} /> */}
                        {/* Reopened */}
                        <StatusCard
                            title={"Reopened"}
                            handlefunction={props.handleReopen}
                            count={!status?.reopened ? 0 : status.reopened}
                            // count={(status === null || status.reopened === '') ? null : status.reopened}
                            tooltip_data={props.info.reopened}
                            percentage={!status?.reopened && !status?.totalPlanned ? 0 : Math.round((status.reopened / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.reopened === '' && status.totalPlanned === '') ? null : Math.round((status.reopened / status.totalPlanned) * 100 || 0)} /> */}
                        {/* Skipped */}
                        <StatusCard
                            title={"Skipped"}
                            handlefunction={props.handleskipped}
                            count={!status?.skipped ? 0 : status.skipped}
                            // count={(status === null || status.skipped === '') ? null : status.skipped}
                            tooltip_data={props.info.skipped}
                            percentage={!status?.skipped && !status?.totalPlanned ? 0 : Math.round((status.skipped / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.skipped === '' && status.totalPlanned === '') ? null : Math.round((status.skipped / status.totalPlanned) * 100 || 0)} /> */}
                    </div>
                }

                {/* On demand status bar */}
                {props.name === "On-Demand Status" &&
                    <div className="justify-content-evenly row" style={{ gap: '10px' }}>
                        {/* Total status */}
                        <StatusCard
                            title={"In Progress"}
                            handlefunction={props.handleInprogress}
                            count={!status?.ondemandIndraft ? 0 : status.ondemandIndraft}
                            // count={(status === null || status.ondemandIndraft === '') ? null : status.ondemandIndraft}
                            tooltip_data={props.info.in_progress} />
                        {/* Waiting for Approval status */}
                        <StatusCard
                            title={"Waiting for Approval"}
                            handlefunction={props.handlewaiting}
                            count={!status?.ondemandWaitingForApproval ? 0 : status.ondemandWaitingForApproval}
                            // count={(status === null || status.ondemandWaitingForApproval === '') ? null : status.ondemandWaitingForApproval}
                            tooltip_data={props.info.waiting_for_approval} />
                        {/* Completed status */}
                        <StatusCard
                            title={"Completed"}
                            handlefunction={props.handleCompleted}
                            count={!status?.ondemandApproved ? 0 : status.ondemandApproved}
                            // count={(status === null || status.ondemandApproved === '') ? null : status.ondemandApproved}
                            tooltip_data={props.info.completed} />
                        {/* ondemandReopened status */}
                        <StatusCard
                            title={"Reopened"}
                            handlefunction={props.handleReopen}
                            count={!status?.ondemandReopened ? 0 : status.ondemandReopened}
                            // count={(status === null || status.ondemandReopened === '') ? null : status.ondemandReopened}
                            tooltip_data={props.info.re_opened} />
                        {/* Save as Draft status */}
                        <StatusCard
                            title={"Save as Draft"}
                            handlefunction={props.handleSaveasDraft}
                            count={!status?.ondemandSaveAsDraft ? 0 : status.ondemandSaveAsDraft}
                            // count={(status === null || status.ondemandSaveAsDraft === '') ? null : status.ondemandSaveAsDraft}
                            tooltip_data={props.info.save_as_draft} />
                    </div>}

                {/* Ticket status bar */}
                {props.name === "Ticket Status" &&
                    <div className="justify-content-evenly row" style={{ gap: '10px' }}>
                        {/* Total status */}
                        <StatusCard
                            parent={'ticket'}
                            title={"Total Raised"}
                            handlefunction={props.handleTotal}
                            count={!status?.totalPlanned ? 0 : status.totalPlanned}
                            // count={(status === null || status.totalPlanned === '') ? null : status.totalPlanned}
                            tooltip_data={props.info.total_planned}
                        />
                        {/* Open status */}
                        {/* <StatusCard title={"Open"} count={(status === null || status.assigned === '') ? null : status.assigned} tooltip_data={props.info.assigned} /> */}
                        {/* Waiting for Approval status */}
                        <StatusCard
                            parent={'ticket'}
                            title={"Waiting for Approval"}
                            handlefunction={props.handlewaiting}
                            count={!status?.waitingApproval ? 0 : status.waitingApproval}
                            // count={(status === null || status.waitingApproval === '') ? null : status.waitingApproval}
                            tooltip_data={props.info.waiting_for_approval}
                            percentage={!status?.waitingApproval && !status?.totalPlanned ? 0 : Math.round((status.waitingApproval / status.totalPlanned) * 100 || 0)}
                        // percentage={(status === null || status.waitingApproval === '' && status?.totalPlanned === '') ? null : Math.round((status.waitingApproval / status.totalPlanned) * 100 || 0)}
                        />
                        {/* Completed status */}
                        <StatusCard
                            parent={'ticket'}
                            title={"Completed"}
                            count={!status?.completed ? 0 : status.completed}
                            handlefunction={props.handleCompleted}
                            // count={(status === null || status.completed === '') ? null : status.completed}
                            tooltip_data={props.info.completed}
                            percentage={!status?.completed && !status?.totalPlanned ? 0 : Math.round((status.completed / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.completed === '' && status.totalPlanned === '') ? null : Math.round((status.completed / status.totalPlanned) * 100 || 0)} /> */}
                        {/* Reopened status */}
                        <StatusCard
                            parent={'ticket'}
                            title={"Reopened & Reassigned"}
                            handlefunction={props.handlereopened}
                            count={!status?.reopened ? 0 : (status.reopened + status.reassigned)}
                            // count={(status === null || status.reopened === '') ? null : (status.reopened + status.reassigned)}
                            tooltip_data={props.info.re_opened}
                            percentage={!status?.reopened && !status?.totalPlanned ? 0 : Math.round(((status.reopened + status.reassigned) / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.reopened === '' && status.totalPlanned === '') ? null : Math.round(((status.reopened + status.reassigned) / status.totalPlanned) * 100 || 0)} /> */}
                        {/* Yet To Assign status */}
                        <StatusCard
                            parent={'ticket'}
                            title={"Yet To Be Assigned"}
                            count={!status?.yetToBeAssigned ? 0 : status.yetToBeAssigned}
                            // count={(status === null || status.yetToBeAssigned === '') ? null : status.yetToBeAssigned}
                            handlefunction={props.handleYetTobeAssigned}
                            tooltip_data={props.info.yet_to_assign}
                            percentage={!status?.yetToBeAssigned && !status?.totalPlanned ? 0 : Math.round((status.yetToBeAssigned / status.totalPlanned) * 100 || 0)} />
                        {/* percentage={(status === null || status.yetToBeAssigned === '' && status.totalPlanned === '') ? null : Math.round((status.yetToBeAssigned / status.totalPlanned) * 100 || 0)} /> */}
                    </div>}
            </div >
        </div >
    )
}

export default StatusComponent
