import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import '../../css/modules/workorder/workorder.css'
import CardList from "./cardList";
import Pagination from "../../components/pagination/pagination";
import WorkOrdertabs from "./workOrdertabs";
import DropdownComp from "./dropdown";
import SearchBox from "../../components/searchBox/searchBox";
import CustomStatus from "./customStatus";
import WorkorderList from "./workorderList";


const WorkStatus = (props) => {
  const {
    sendTabKeyData,
    sendType,
    handleNext,
    handlePrev,
    handleFirstPage,
    handleLastpage,
    handleItemsPerPageChange,
    handleSearchInputChange,
    handleClearButton,
    searchInput,
    data,
    totalPages,
    currentPage,
    itemsPerPage,
    name,
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    totalCount,
    handleStartDateoFDashboard,
    handleEndDateoFDashboard,
    yet_to_be_triggered_count,
  } = props;
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("All");
  const [key, setKey] = useState("");
  const [selectedReports, setSelectedReports] = useState([]);

  const navigate = useNavigate();
  const sites_id = useSelector(
    (state) => state.auth?.sites_id && state.auth?.sites_id[0]
  );


  const handleSelectType = (val) => {
    // console.log(val);
    sendType(val);
    setSelectedItem(val);
  };
  const handleTabKey = (val) => {
    setKey(val);
    sendTabKeyData(val);
  };

  const convertSecondsToMinSec = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(0);
    if (!isNaN(minutes)) {
      return {
        hours,
        minutes,
        seconds: remainingSeconds,
      };
    } else {
      return {
        hours: "-",
        minutes: "-",
        seconds: "-",
      };
    }
  };

  useEffect(() => {
    if (
      location.state !== undefined &&
      location.state !== null &&
      name !== "Physical Verification"
    ) {
      setKey(location.state?.[0]);
      sendTabKeyData(location.state?.[0]);
      setSelectedItem(location.state?.[1]);
    }
    if (location?.state?.[0] === undefined) {
      setKey(name === "On - Demand" ? "inprogress" : "total");
      sendTabKeyData(name === "On - Demand" ? "inprogress" : "total");
      setSelectedItem("All");
    }
    if (
      location?.state !== null &&
      location?.state?.[2] !== null &&
      location?.state !== undefined
    ) {
    }
  }, [location?.state]);

  useEffect(() => {
  }, [selectedItem, props]);

  const handleReportSelection = (value, id) => {
    if (id === "All") {
      const allIds = value
        ? data.map((el) => el?._id || el?.inspection?._id || el?.maintenance?._id)
        : [];
      setSelectedReports(allIds);
    } else {
      setSelectedReports((prev) =>
        value ? [...prev, id] : prev.filter((el) => el !== id)
      );
    }
  };

  const handlePreview = () => {
    if (!selectedReports?.length) {
      alert("Select at least one report");
      return;
    }

    if (selectedReports?.length > 100) {
      alert("You cannot view more than 100 reports");
      return;
    }

    const navigateTo = (basePath) => {
      navigate(basePath, {
        state: [
          key,
          selectedItem,
          currentPage,
          itemsPerPage,
          startDate,
          endDate,
          searchInput,
          name,
        ],
      });
    };

    if (data?.length > 0) {
      const firstItem = data[0];
      if (firstItem?.inspection?._id) {
        navigateTo(`/inspection/report/${selectedReports}`);
      } else if (firstItem?.maintenance?._id) {
        navigateTo(`/maintenance/report/${selectedReports}`);
      } else if (firstItem?._id) {
        navigateTo(`/ticket/report/${selectedReports}`);
      }
    }
  };


  return (
    <div>
      <div className="pt-5 d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
        <div className="w-100">
          <WorkOrdertabs name={name} keyValue={key} handleTabKey={handleTabKey} />
        </div>
        <div className="asset-filter-div">
          <DropdownComp selectedItem={selectedItem} handleSelectType={handleSelectType} />
        </div>
      </div>
      <div className="col-md-12">
        <div className="workorder-header-main-div">
          <span className="workorder-header">{selectedItem.charAt(0).toUpperCase() + selectedItem.slice(1)} -{" "}{name} List</span>
          <div className="d-lg-none">
            <CustomStatus name={name} keyValue={key} />
          </div>
          <br className="d-block d-md-none" />
          <SearchBox searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} handleClearButton={handleClearButton} />
          <div className="d-flex float-start date-div">
            <div>
              <label>Start Date</label>
              <input
                type="date"
                placeholder="Start Date"
                value={startDate}
                onChange={handleStartDate}
                min={
                  sites_id === "6422f56e26037ff46450359d" ? "2024-06-21" : ""
                }
                className="workorder-date"
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <label>End Date</label>
              <input
                type="date"
                placeholder="End Date"
                value={endDate}
                onChange={handleEndDate}
                min={
                  sites_id === "6422f56e26037ff46450359d" ? "2024-06-21" : ""
                }
                className="workorder-date"
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            {(key === "approved" && name !== "Ticket" || (name === "Ticket" && key !== "total")) && selectedReports?.length > 1 ? <div>
              <button className="float-end saveButton view-report-btn" onClick={handlePreview}>View Reports - {selectedReports?.length}</button>
            </div> : null}
          </div>
          <div className="d-none d-lg-block">
            <CustomStatus name={name} keyValue={key} />
          </div>
        </div>
        <div className="status-result-div">
          {totalCount !== 0 && (
            <>
              {key == "total" && (name == "Inspection" || name == "Maintenance") ? (
                <p className="m-0 text-secondary">
                  Yet To Be Triggered -{" "}
                  {props?.ins_count?.yet_to_be_triggered_count
                    ? props?.ins_count?.yet_to_be_triggered_count
                    : 0}
                </p>
              ) : key === "pending" ? (
                <p className="m-0 text-secondary"></p>
              ) : (
                <p className="m-0 text-secondary"></p>
              )}
            </>
          )}
          {totalCount !== 0 && totalCount && (
            <p className="m-0 text-secondary showing-result">
              Showing {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {(+currentPage - 1) * +itemsPerPage + Number(itemsPerPage) > totalCount
                ? totalCount
                : (+currentPage - 1) * +itemsPerPage + Number(itemsPerPage)}{" "}
              results of {totalCount}
            </p>
          )}
        </div>
        {/* Table to view Maintenance list */}
        <div className="mb-6">
          <WorkorderList keyvalue={key} name={name} selectedReports={selectedReports} data={data} handleReportSelection={handleReportSelection} selectedItem={selectedItem} convertSecondsToMinSec={convertSecondsToMinSec} currentPage={currentPage} itemsPerPage={itemsPerPage} startDate={startDate} endDate={endDate} searchInput={searchInput} />
          {/* Card format for mobile */}
          <div className="d-xl-none">
            <CardList data={data} currentPage={currentPage} itemsPerPage={itemsPerPage} name={name} CardKey={key} selectedItem={selectedItem} startDate={startDate} endDate={endDate} searchInput={searchInput} />
          </div>
        </div>
        {/* pagination */}
        {data !== null && data.length > 0 ? (
          <Pagination itemsPerPage={itemsPerPage} handleItemsPerPageChange={handleItemsPerPageChange} currentPage={currentPage} handleFirstPage={handleFirstPage} handlePrev={handlePrev} handleNext={handleNext} totalPages={totalPages} handleLastpage={handleLastpage} />
        ) : null}
      </div>
    </div>
  );
};

export default WorkStatus;
