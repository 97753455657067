import { Dropdown } from "react-bootstrap";

const dropdownItems = ["Asset", "Device", "Facility", "All"];

const DropdownComp = ({ selectedItem, handleSelectType }) => {
  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  return (
    <Dropdown>
      <Dropdown.Toggle className="custom-button">
        {selectedItem ? capitalize(selectedItem) : "All"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {dropdownItems.map((item, index) => (
          <div key={item}>
            <Dropdown.Item onClick={() => handleSelectType(item)}>
              {item}
            </Dropdown.Item>
            {index === dropdownItems.length - 2 && <Dropdown.Divider />}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownComp;
