import React, { useEffect, useState } from 'react'
import Speedometer, {
    Background,
    Arc,
    Needle,
    Progress,
    Marks,
    Indicator,
} from 'react-speedometer';
import '../../css/modules/home/radialinsChart.css'



function RadialInsChart(props) {

    const [status, setStatus] = useState({
        inTotalPlanned: 0,
        inProgressvalue: 0,
        inCompletedvalue: 0,
        inPendingvalue: 0,
    })
    
    if (status.inTotalPlanned === 0) {
        var stepSize = 1;
        status.inTotalPlanned = 1;
    }
    if (status.inTotalPlanned <= 10 && status.inTotalPlanned >= 2) {
        var stepSize = status.inTotalPlanned / status.inTotalPlanned;
    } 
    if (status.inTotalPlanned >= 11) {
        var stepSize = status.inTotalPlanned / 10;
    }

    useEffect(() => {
        if (props.data !== undefined && props.data.totalPlanned !== '') {
            setStatus({
                inTotalPlanned: props.data.totalPlanned,
                inProgressvalue: props.data.inProgress,
                inCompletedvalue: props.data.completed,
                inPendingvalue: props.data.pending,
            })
        }
    }, [props.data])

    return (
        <div className="w-100 d-flex flex-wrap justify-content-evenly mb-4">
            <div className='speedometer-div' >
                <div className="text-center fw-bold fs-2 mx-auto">
                    <p>In Progress - {status.inProgressvalue}</p>
                </div>
                <div >
                    <Speedometer
                        value={status.inProgressvalue}
                        max={status.inTotalPlanned}
                        angle={160}
                        fontFamily='squada-one'
                    >
                        <Background angle={180} color='#021D37' opacity={1} />
                        <Arc />
                        <Needle color={'#64FFE2'} />
                        <Progress color={'#64FFE2'} />
                        <Marks step={stepSize} fontSize={'auto'} color={'#000'} />
                    </Speedometer>
                </div>
            </div>
            <div className='speedometer-div'>
                <div className="text-center fw-bold fs-2 mx-auto">
                    <p>Completed - {status.inCompletedvalue}</p>
                </div>
                <div>
                    <Speedometer
                        value={status.inCompletedvalue}
                        max={status.inTotalPlanned}
                        angle={160}
                        fontFamily='squada-one'
                    >
                        <Background angle={180} color='#021D37' opacity={1} />
                        <Arc />
                        <Needle color={'#64FFE2'} />
                        <Progress color={'#64FFE2'} />
                        <Marks step={stepSize} fontSize={'auto'} color={'#000'} />
                    </Speedometer>
                </div>
            </div>
            <div className='speedometer-div'>
                <div className="text-center fw-bold fs-2 mx-auto">
                    <p>Pending - {status.inPendingvalue}</p>
                </div>
                <div>
                    <Speedometer
                        value={status.inPendingvalue}
                        max={status.inTotalPlanned}
                        angle={160}
                        fontFamily='squada-one'
                    >
                        <Background angle={180} color='#021D37' opacity={1} />
                        <Arc />
                        <Needle color={'#64FFE2'} />
                        <Progress color={'#64FFE2'} />
                        <Marks step={stepSize} fontSize={'auto'} color={'#000'} />
                    </Speedometer>
                </div>
            </div>
        </div>
    )
}

export default RadialInsChart

