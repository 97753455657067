import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import "../../../css/modules/report/workorderReport/inspection.css"

const ReportPDFDownload = ({ btnDisable, printData, pageSetup, startDate, endDate, reportName }) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const componentRef = useRef();
    const [fileName, setFileName] = useState(""); // To set Custom file name

    const handlePrint = () => {
        componentRef.current.handlePrint();
    };

    useEffect(() => {
        const dynamicFileName = generateFileName();
        setFileName(dynamicFileName);
    }, [printData, pageSetup, startDate, endDate, reportName])

    // Function to generate the custom file name dynamically
    const generateFileName = () => {
        // Example: return `inspection_${key}_${selectedItem}.pdf`;
        return `Report_${reportName}_From_${moment(startDate).format("DD-MM-YYYY")}_To_${moment(endDate).format("DD-MM-YYYY")}_V1.0.pdf`;
    };
    useEffect(() => {
        const headerElement = document.getElementById('page-header');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            setHeaderHeight(height);
        }
    }, []);

    const handleBeforePrint = () => {
        document.title = fileName;  // Set custom title
    };
    
    const handleAfterPrint = () => {
        document.title = fileName; // Reset to default after printing
    };

    return (
        <div>
            {btnDisable ? '' : <p className="download-title">The report is ready. Click the download button to proceed</p>}
            <div className="download-custom-btn-div" style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', }}>
                <ReactToPrint
                    trigger={() => (
                        <button
                            type="button"
                            style={{
                                opacity: btnDisable ? 0.5 : 1
                            }}
                            onClick={handlePrint}
                            disabled={btnDisable}
                            className="saveButton my-0 mx-4 download-btn-custom work-order-report-btn"
                        >
                            Download
                        </button>
                    )}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}   // Set before print
                    onAfterPrint={handleAfterPrint}     // Reset after print
                    documentTitle={fileName} // Set the custom file name
                        pageStyle={`
                    @page {
                        size: 'A4 landscape';margin: 5%;
                    }
                    @media print {
                        #page-header {
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            background-color: lightgray;
                            margin-top: 30px;
                            height: 50px;
                            page-break-inside: avoid;

                        }
                        #content {
                            margin-top: calc(30px + ${headerHeight}px);
                            page-break-before: always;

                        }
                    }
                    @media (max-width: 768px) {
                        // @page {
                        //   size: landscape !important;
                        // }
                        html, body {
                           width: 100%;
                           height: auto;
                           transform: rotate(0deg) !important; /* Prevent rotation */
                        }
                    }

                    `}
                   
                />
            </div>
            <br />
            <div ref={componentRef}>
                <div style={{ marginTop: '60px', marginBottom: '60px' }}>
                    {/* Add the necessary content here */}
                    {printData}
                </div>
            </div>
        </div>
    );
    // }
}

export default ReportPDFDownload;
