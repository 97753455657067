import React, { useEffect } from 'react'
import S3BucketImage from '../pdfPreview/s3BucketImages'

function ReportHearder({ data1, data2, data3, custLogo, malur, medopharm, Genex, genexMain, pageWidth }) {

    useEffect(() => {
        // console.log(custLogo);
    }, [data1, data2, data3, custLogo])

    return (
        <div className='report-responsive'>
            <table className="table" style={{ margin: "auto", width: pageWidth || 1000, border: '1px solid black', borderBottom: 'none', tableLayout: 'fixed', marginLeft: 'auto' }}>
                <thead style={{ width: '100%', fontWeight: 'bold' }}>
                    <tr>
                        <th scope="col" style={{ border: '1px solid', textAlign: 'center', verticalAlign: 'middle' }}>
                            <S3BucketImage
                                imageUrl={custLogo}
                                widthSize={'auto'}
                            /></th>
                        {Genex == true ? <th colSpan={data1?.colSpan} style={{ border: '1px solid', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold' }}>
                            {`${data1?.data !== undefined ? (data1?.data) + " - Operation & Maintenance By" : ""}`}
                            <br />
                            {'Genex Utility Management Pvt Ltd'}
                        </th> :
                            (genexMain === true ?
                                < th colSpan={data1?.colSpan} style={{ border: '1px solid', textAlign: data1?.textAlign, verticalAlign: data1?.verticalAlign, fontWeight: 'bold' }}>
                                    {data1?.data.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </th>
                                :
                                <th colSpan={data1?.colSpan} style={{ border: '1px solid', textAlign: 'left', verticalAlign: 'middle', fontWeight: 'bold' }}>
                                    {medopharm?.length > 1 ? medopharm : "Medopharm"}
                                    <br />
                                    {malur?.length > 0 ? malur : 'MALUR'}
                                    <br />
                                    <br />
                                    {data1?.data} DEPARTMENT
                                </th>)
                        }
                        {genexMain === true ? '' :
                            <th style={{ border: '1px solid', textAlign: 'right', verticalAlign: 'middle' }}>
                                {data2?.data}
                            </th>
                        }
                    </tr>
                    {(Genex == true || genexMain === true) ? '' :
                        <tr>
                            <th style={{ border: '1px solid', textAlign: 'left', verticalAlign: 'middle' }}>
                                Title :
                            </th>
                            <th colSpan={data3?.colSpan} style={{ border: '1px solid', textAlign: 'center', verticalAlign: 'middle' }}>
                                {data3?.data}
                            </th>
                        </tr>}
                </thead>
            </table>
        </div>
    )
}

export default ReportHearder
