/* Feature - Bulkupload of inspection & maintenance activity

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";



// function to create bulkupload feature
function CreateBulkSchedule(props) {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userid = useSelector((state) => state.auth.userid);


    const [inspectionData, setInspectionData] = useState({
        asset_id: '',
        inspection_data: '',
    })

    // setting data for navingate to the correct list (passing as props)
    const [data, setData] = useState('')

    const [maintenanceData, setmaintenanceData] = useState({
        maintenance_data: '',
        pre_maintenance_approval: '',
        pre_maintenance_check: '',
        post_maintenance_approval: '',
        post_maintenance_check: '',
    });


    // to disable button based on file input //
    const [disabled, setDisabled] = useState(true)

    // to disable button based on empty file of Inspection / Maintenance input //
    const [disEmptyMainArray, setDisEmptyMainArray] = useState(false);


    // console.log('maintenance_data', maintenanceData.maintenance_data);
    // console.log('pre_maintenance_approval', maintenanceData.pre_maintenance_approval);
    // console.log('pre_maintenance_check', maintenanceData.pre_maintenance_check);
    // console.log('post_maintenance_approval', maintenanceData.post_maintenance_approval);
    // console.log('posr_maintenance_check', maintenanceData.post_maintenance_check);

    // Asset ID & Asset Name of each row //
    const assetid = props.id // 

    var assetName = props.assetname // here assetname prop is given in lowercase to avoid warrning fro React Dom.

    // console.log(assetName);

    // console.log(props.type);

    inspectionData.asset_id = assetid

    // console.log(maintenanceData);


    // Excel read fucntion //

    const readExcel = (file) => {

        // console.log(file);

        // to handle button 
        if (file.length === 0) {
            setDisabled(true);
        }

        const promise = new Promise((resolve, reject) => {

            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file)

            fileReader.onload = (e) => {

                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: 'buffer' }); // wb - workbook                

                const wsname = wb.SheetNames; // wsname - worksheet name

                ////////////////////////////////   Inspection Bulk Upload    //////////////////////////////////////////

                const wsInspection = wb.Sheets['inspection']; // 'inspection' is taken from excel workbook name.

                const inspectionHeader = [
                    [
                        'SrNo',
                        'frequency_type',
                        'frequency_type_value',
                        'priority',
                        'based_on',
                        'managed_by',
                        'inspection_duration',
                        'man_power_required',
                        'man_hour_required',
                        'frequency_end_type',
                        'sla',
                        'sla_interval',
                        // 'type',
                        'checkpoint',
                        'uom',
                        'standard_requirement',
                        'answer_type',
                        'inspection_name',
                        "created_by",
                    ]]

                const data = XLSX.utils.sheet_to_json(wsInspection, { defval: null });

                // console.log('data', data);

                const CreateWStoMearge = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(CreateWStoMearge, inspectionHeader);

                const MeargehederData = XLSX.utils.sheet_add_json(CreateWStoMearge, data, { skipHeader: true, origin: "A2" }, { defval: null });

                // console.log('hederData', MeargehederData);

                const MeagedDataInspection = XLSX.utils.sheet_to_json(MeargehederData, { defval: null });
                // console.log('MeagedDataInspection', MeagedDataInspection);

                // to delete Sl.no column before sending to backend

                MeagedDataInspection.forEach(function (obj) {
                    delete obj.SrNo
                });

                // to add the automatically inspection name based on frontend data   

                MeagedDataInspection.forEach(function (obj) {
                    // console.log(obj.inspection_name);
                    obj.inspection_name = null
                    obj.frequency_end_type = 'no_end'
                    obj.created_by = userid
                }, MeagedDataInspection);

                ////// create automatic inspection name based on selected frequency /////////////////////

                MeagedDataInspection.map((eachdata) => {

                    if (eachdata.frequency_type === 'Daily') {

                        var enteredFrequency = 'Daily Inspection';

                    } if (eachdata.frequency_type === 'Hourly') {

                        var enteredFrequency = 'Hourly Inspection';

                    } if (eachdata.frequency_type === 'Minutes') {

                        var enteredFrequency = 'Minute Based Inspection';

                    } if (eachdata.based_on!==''){
                        eachdata.based_on = (eachdata.based_on).toLowerCase();
                    }
                    if (eachdata.priority!==''){
                        eachdata.priority = (eachdata.priority).toLowerCase();
                    }
                    if (eachdata.managed_by!==''){
                        eachdata.managed_by = (eachdata.managed_by).toLowerCase();
                    }
                    if (eachdata.frequency_type!==''){
                        eachdata.frequency_type = (eachdata.frequency_type).toLowerCase();
                    }

                    const inspactionName = MeagedDataInspection

                    // console.log(inspactionName);

                    const index = inspactionName.findIndex(object => {
                        return object.inspection_name === null;
                    });

                    if (index !== -1) {
                        inspactionName[index].inspection_name = enteredFrequency + ' - ' + assetName
                    }
                })

                ////////// array to find and replace the data as per backend requirement //////

                const Insarray1 = MeagedDataInspection.map(eachdata => {
                    if (eachdata.sla_interval === null) {
                        return { ...eachdata, sla_interval: '' };
                    }
                    return eachdata;
                });

                const updatedMeagedDataInspection = Insarray1.map(eachdata => {
                    if (eachdata.answer_type === 'Yes / No / Not Applicable') {
                        return { ...eachdata, answer_type: 'yes_no_na' };
                    }else if (eachdata.answer_type === 'Value'){
                        return { ...eachdata, answer_type: 'value' };
                    }
                    return eachdata;
                });

                // console.log(updatedMeagedDataInspection);

                ////////////////////////////////   Maintenance Bulk Upload  //////////////////////////////////////////

                const wsMaintenance = wb.Sheets['Maintenance Checkpoints']; // 'Maintenance Checkpoints' is taken from excel workbook name.              

                const maintenanceHeader = [['SrNo', 'frequency_type', 'frequency_type_value', 'maintenance_type', 'priority',
                    'based_on', 'managed_by', 'frequency_end_type', 'maintenance_duration', 'shutdown_duration',
                    'man_power_required', 'man_hour_required', 'ma_sla', 'ma_sla_interval', 'checkpoint', 'uom', 'standard_requirement',
                    'answer_type', 'maintenance_name', 'created_by']]

                const dataToJson = XLSX.utils.sheet_to_json(wsMaintenance, { defval: null });

                // console.log('dataToJson', dataToJson);

                const CreateWStoMeargeMaint = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(CreateWStoMeargeMaint, maintenanceHeader);

                const MeargehederDataMain = XLSX.utils.sheet_add_json(CreateWStoMeargeMaint, dataToJson, { skipHeader: true, origin: "A2" }, { defval: null });

                // console.log('hederData', MeargehederDataMain);

                var MeagedDataMaint = XLSX.utils.sheet_to_json(MeargehederDataMain, { defval: null });
                // console.log('MeagedDataMaint', MeagedDataMaint);

                // to delete Sl.no column before sending to backend

                MeagedDataMaint.forEach(function (obj) {
                    delete obj.SrNo
                    obj.maintenance_name = null
                    obj.frequency_end_type = 'no_end'
                    obj.created_by = userid
                }, MeagedDataMaint);

                //////// create automatic inspection name based on selected frequency /////////////

                MeagedDataMaint.map((eachdata) => {

                    if (eachdata.frequency_type === 'Weekly') {

                        var enteredFrequency = 'Weekly Maintenance';
                    }
                    if (eachdata.frequency_type === 'Monthly') {

                        var enteredFrequency = 'Monthly Maintenance';

                    } if (eachdata.frequency_type === 'Quarterly') {

                        var enteredFrequency = 'Quarterly Maintenance';

                    } if (eachdata.frequency_type === 'Half Yearly') {

                        var enteredFrequency = 'Half Yearly Maintenance';

                    } if (eachdata.frequency_type === 'Yearly') {

                        var enteredFrequency = 'Yearly Maintenance';

                    }

                    const scheduleName = MeagedDataMaint

                    const index = scheduleName.findIndex(object => {
                        // console.log(object.maintenance_name === null);
                        return object.maintenance_name === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        scheduleName[index].maintenance_name = enteredFrequency + ' - ' + assetName
                    }

                })

                ////////// array to find and replace the data as per backend requirement //////

                const Maintarray1 = MeagedDataMaint.map(eachdata => {
                    if (eachdata.answer_type === 'Yes / No / Not Applicable') {
                        return { ...eachdata, answer_type: 'yes_no_na' };
                    }else if (eachdata.answer_type === 'Value'){
                        return { ...eachdata, answer_type: 'value' };
                    }
                    return eachdata;
                });
                const Maintarray2 = Maintarray1.map(eachdata => {
                    if (eachdata.frequency_type === 'Half Yearly') {
                        return { ...eachdata, frequency_type: 'half_yearly' };
                    }
                    return eachdata;
                });
                const updatedMeagedDataMaint = Maintarray2.map(eachdata => {
                    if (eachdata.ma_sla_interval === null) {
                        return { ...eachdata, ma_sla_interval: '' };
                    }
                    return eachdata;
                });

                // console.log(updatedMeagedDataMaint);


                ////////////////////////////////   Pre Maintenance Approval Bulk Upload  //////////////////////////////////////////

                const wsPreMaintApproval = wb.Sheets['Pre Maintenance Approval']; // 'Pre Maintenance Approval' is taken from excel workbook name.

                const preMainApprovalHeader = [['SrNo', 'frequency_type', 'ma_name', 'ma_sla', 'ma_sla_interval',
                    'approval_category', 'sequence_approval_type', 'approval_sequence', 'sequence_approval_set', 'parallel_approval_type',
                    'parallel_type', 'parallel_approval_set', "created_by"]]

                const datapreApproval = XLSX.utils.sheet_to_json(wsPreMaintApproval, { defval: null });

                // console.log('datapreApproval', datapreApproval);

                const CreateWStoMeargePreApproval = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(CreateWStoMeargePreApproval, preMainApprovalHeader);

                const MeargehederDataPreApproval = XLSX.utils.sheet_add_json(CreateWStoMeargePreApproval, datapreApproval, { skipHeader: true, origin: "A2" }, { defval: null });

                // console.log('hederData', MeargehederDataPreApproval);

                const MeagedDataPreApp = XLSX.utils.sheet_to_json(MeargehederDataPreApproval, { defval: null });
                // console.log('MeagedDataPreApp', MeagedDataPreApp);

                // to delete Sl.no column before sending to backend

                MeagedDataPreApp.forEach(function (obj) {
                    delete obj.SrNo
                    obj.created_by = userid
                }, MeagedDataPreApp);

                // to delete 0 index of array because excel header is given with mearged two rows.

                MeagedDataPreApp.shift();


                ////////////////////////////////   Pre Maintenance Check Bulk Upload  //////////////////////////////////////////

                const wsPreMaintCheck = wb.Sheets['Pre Maintenance Check']; // 'Pre Maintenance Check' is taken from excel workbook name.

                const preMaintenanceHeader = [['SrNo', 'frequency_type', 'ma_name', 'ma_sla', 'ma_sla_interval', 'checkpoint', 'uom', 'standard_requirement',
                    'answer_type', 'created_by']]

                const dataToJsonpreMain = XLSX.utils.sheet_to_json(wsPreMaintCheck, { defval: null });

                // console.log('dataToJsonpreMain', dataToJsonpreMain);

                const CreateWStoMeargePreMaint = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(CreateWStoMeargePreMaint, preMaintenanceHeader);

                const MeargehederDataPreMain = XLSX.utils.sheet_add_json(CreateWStoMeargePreMaint, dataToJsonpreMain, { skipHeader: true, origin: "A2" }, { defval: null });

                // console.log('MeargehederDataPreMain', MeargehederDataPreMain);

                const MeagedDataPreMaint = XLSX.utils.sheet_to_json(MeargehederDataPreMain, { defval: null });
                // console.log('MeagedDataPreMaint', MeagedDataPreMaint);

                // to delete Sl.no column before sending to backend

                MeagedDataPreMaint.forEach(function (obj) {
                    delete obj.SrNo
                    obj.created_by = userid
                }, MeagedDataPreMaint);

                ////////// array to find and replace the data as per backend requirement //////

                const MaintPrearray1 = MeagedDataPreMaint.map(eachdata => {
                    if (eachdata.answer_type === 'Yes / No / Not Applicable') {
                        return { ...eachdata, answer_type: 'yes_no_na' };
                    }else if (eachdata.answer_type === 'Value'){
                        return { ...eachdata, answer_type: 'value' };
                    }
                    return eachdata;
                });
                const MaintPrearray2 = MaintPrearray1.map(eachdata => {
                    if (eachdata.frequency_type === 'Half Yearly') {
                        return { ...eachdata, frequency_type: 'half_yearly' };
                    }
                    return eachdata;
                });
                const updatedMeagedDataPreMaint = MaintPrearray2.map(eachdata => {
                    if (eachdata.ma_sla_interval === null) {
                        return { ...eachdata, ma_sla_interval: '' };
                    }
                    return eachdata;
                });


                ////////////////////////////////   Post Maintenance Check Bulk Upload  //////////////////////////////////////////

                const wsPostMaintCheck = wb.Sheets['Post Maintenance Check']; // 'Post Maintenance Check' is taken from excel workbook name.

                const postMaintenanceHeader = [['SrNo', 'frequency_type', 'ma_name', 'ma_sla', 'ma_sla_interval', 'checkpoint', 'uom',
                    'standard_requirement', 'answer_type', 'created_by']]

                const dataToJsonpostMain = XLSX.utils.sheet_to_json(wsPostMaintCheck, { defval: null });

                // console.log('dataToJsonpostMain', dataToJsonpostMain);

                const CreateWStoMeargePostMaint = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(CreateWStoMeargePostMaint, postMaintenanceHeader);

                const MeargehederDataPostMain = XLSX.utils.sheet_add_json(CreateWStoMeargePostMaint, dataToJsonpostMain, { skipHeader: true, origin: "A2" }, { defval: null });

                // console.log('MeargehederDataPostMain', MeargehederDataPostMain);

                const MeagedDataPostMaint = XLSX.utils.sheet_to_json(MeargehederDataPostMain, { defval: null });
                // console.log('MeagedDataPostMaint', MeagedDataPostMaint);

                // to delete Sl.no column before sending to backend

                MeagedDataPostMaint.forEach(function (obj) {
                    delete obj.SrNo
                    obj.created_by = userid
                }, MeagedDataPostMaint);

                ////////// array to find and replace the data as per backend requirement //////

                const MaintPostarray1 = MeagedDataPostMaint.map(eachdata => {
                    if (eachdata.answer_type === 'Yes / No / Not Applicable') {
                        return { ...eachdata, answer_type: 'yes_no_na' };
                    }else if (eachdata.answer_type === 'Value'){
                        return { ...eachdata, answer_type: 'value' };
                    }
                    return eachdata;
                });
                const MaintPostarray2 = MaintPostarray1.map(eachdata => {
                    if (eachdata.frequency_type === 'Half Yearly') {
                        return { ...eachdata, frequency_type: 'half_yearly' };
                    }
                    return eachdata;
                });
                const updatedMeagedDataPostMaint = MaintPostarray2.map(eachdata => {
                    if (eachdata.ma_sla_interval === null) {
                        return { ...eachdata, ma_sla_interval: '' };
                    }
                    return eachdata;
                });


                ////////////////////////////////   Post Maintenance Approval Bulk Upload  //////////////////////////////////////////

                const wsPostMaintApproval = wb.Sheets['Post Maintenance Approval']; // 'Post Maintenance Approval' is taken from excel workbook name.

                const postMainApprovalHeader = [['SrNo', 'frequency_type', 'ma_name', 'ma_sla', 'ma_sla_interval',
                    'sequence_approval_type', 'approval_sequence', 'sequence_approval_set', 'parallel_approval_type',
                    'parallel_type', 'parallel_approval_set', 'created_by']]

                const datapostApproval = XLSX.utils.sheet_to_json(wsPostMaintApproval, { defval: null });

                // console.log('datapostApproval', datapostApproval);

                const CreateWStoMeargePostApproval = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(CreateWStoMeargePostApproval, postMainApprovalHeader);

                const MeargehederDataPostApproval = XLSX.utils.sheet_add_json(CreateWStoMeargePostApproval, datapostApproval, { skipHeader: true, origin: "A2" }, { defval: null });

                // console.log('hederData', MeargehederDataPostApproval);

                const MeagedDataPostApp = XLSX.utils.sheet_to_json(MeargehederDataPostApproval, { defval: null });
                // console.log('MeagedDataPostApp', MeagedDataPostApp);

                // to delete Sl.no column before sending to backend

                MeagedDataPostApp.forEach(function (obj) {
                    delete obj.SrNo
                    obj.created_by = userid
                }, MeagedDataPostApp);

                // to delete 0 index of array because excel header is given with mearged two rows.

                MeagedDataPostApp.shift();

                // console.log('MeagedDataPostApp', MeagedDataPostApp);

                //////////////////////////// client side validation - sheet name ///////////////////////////////////////

                if (wsname.includes('inspection') || wsname.includes('Pre Maintenance Approval') ||
                    wsname.includes('Pre Maintenance Check') || wsname.includes('Post Maintenance Check') ||
                    wsname.includes('Post Maintenance Approval') || wsname.includes('Maintenance Checkpoints')) {

                }
                else {
                    alert(`Please verify the uploaded sheet. \n It doesn't includes following workbook: \n 1. Inspection - work book with the name - "inspection" \n 2. Maintenance - work book with the name of -  \n "Pre Maintenance Approval" or \n "Pre Maintenance Check" or \n "Maintenance Checkpoints" or \n "Post Maintenance Check" or \n "Post Maintenance Approval" \n \n\n Note: Either you may have changed the work book name or you are not uploading correct format`)
                }


                //////////////////////  client side validation - Inspection ///////////////////////////////////////////////////

                var result = "";

                updatedMeagedDataInspection.map((eachdata) => {

                    if (eachdata.frequency_type == null) {
                        var frequency_type_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.frequency_type_value == null) {
                        var frequency_type_value_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.priority == null) {
                        var priority_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.based_on == null) {
                        var based_on_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.managed_by == null) {
                        var managed_by_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.frequency_end_type == null) {
                        var frequency_end_type_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.checkpoint == null) {
                        var checkpoint_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.standard_requirement == null) {
                        var standard_requirement_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    if (eachdata.answer_type == null) {
                        var answer_type_index = updatedMeagedDataInspection.indexOf(eachdata);
                    }

                    result += validateFrequency();
                    result += validateFrequencyDuration();
                    result += validatePriority();
                    result += validateBasedOn();
                    result += validateManagedBy();
                    result += validateScheduleEnd();
                    result += validateTaskName();
                    result += validateStandardRequirement();
                    result += validateAnswer();


                    function validateFrequency() {
                        if (eachdata.frequency_type == null)
                            return `Sl.No: ${frequency_type_index + 1} - Insection Calendar Frequency is mandetory field.\n`;
                        return "";
                    }
                    function validateFrequencyDuration() {
                        if (eachdata.frequency_type_value == null)
                            return `Sl.No: ${frequency_type_value_index + 1} - Insection Calendar Frequency Duration is mandetory field.\n`;
                        return "";
                    }
                    function validatePriority() {
                        if (eachdata.priority == null)
                            return `Sl.No: ${priority_index + 1} - Priority is mandetory field.\n`;
                        return "";
                    }
                    function validateBasedOn() {
                        if (eachdata.based_on == null)
                            return `Sl.No: ${based_on_index + 1} - Insection Based On is mandetory field.\n`;
                        return "";
                    }
                    function validateManagedBy() {
                        if (eachdata.managed_by == null)
                            return `Sl.No: ${managed_by_index + 1} - Managed By is mandetory field.\n`;
                        return "";
                    }
                    function validateScheduleEnd() {
                        if (eachdata.frequency_end_type == null)
                            return `Sl.No: ${frequency_end_type_index + 1} - Insection End is mandetory field.\n`;
                        return "";
                    }
                    function validateTaskName() {
                        if (eachdata.checkpoint == null)
                            return `Sl.No: ${checkpoint_index + 1} - Inspection Checkpoints is mandetory field.\n`;
                        return "";
                    }
                    function validateStandardRequirement() {
                        if (eachdata.standard_requirement == null)
                            return `Sl.No: ${standard_requirement_index + 1} - Standard Requirement is mandetory field.\n`;
                        return "";
                    }
                    function validateAnswer() {
                        if (eachdata.answer_type == null)
                            return `Sl.No: ${answer_type_index + 1} - Answer Type is mandetory field.\n`;
                        return "";
                    }
                })

                resolve(updatedMeagedDataInspection);

                ///////////////////////////  client side validation - Maintenance ///////////////////////////////////////////////////

                updatedMeagedDataMaint.map((eachdata) => {

                    if (eachdata.frequency_type == null) {
                        var frequency_type_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.frequency_type_value == null) {
                        var frequency_type_value_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.maintenance_type == null) {
                        var maintenance_type_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.priority == null) {
                        var priority_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.based_on == null) {
                        var based_on_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.managed_by == null) {
                        var managed_by_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.frequency_end_type == null) {
                        var frequency_end_type_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.checkpoint == null) {
                        var checkpoint_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.standard_requirement == null) {
                        var standard_requirement_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    if (eachdata.answer_type == null) {
                        var answer_type_index = updatedMeagedDataMaint.indexOf(eachdata);
                    }

                    result += validateFrequency();
                    result += validateFrequencyDuration();
                    result += validateType();
                    result += validatePriority();
                    result += validateBasedOn();
                    result += validateManagedBy();
                    result += validateScheduleEnd();
                    result += validateTaskName();
                    result += validateStandardRequirement();
                    result += validateAnswer();


                    function validateFrequency() {
                        if (eachdata.frequency_type == null)
                            return `Sl.No: ${frequency_type_index + 1} - Maintenance Calendar Frequency is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateFrequencyDuration() {
                        if (eachdata.frequency_type_value == null)
                            return `Sl.No: ${frequency_type_value_index + 1} - Maintenance Calendar Frequency Duration is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateType() {
                        if (eachdata.maintenance_type == null)
                            return `Sl.No: ${maintenance_type_index + 1} - Planned Maintenance Type is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validatePriority() {
                        if (eachdata.priority == null)
                            return `Sl.No: ${priority_index + 1} - Priority is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateBasedOn() {
                        if (eachdata.based_on == null)
                            return `Sl.No: ${based_on_index + 1} - Maintenance Based On is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateManagedBy() {
                        if (eachdata.managed_by == null)
                            return `Sl.No: ${managed_by_index + 1} - Managed By is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateScheduleEnd() {
                        if (eachdata.frequency_end_type == null)
                            return `Sl.No: ${frequency_end_type_index + 1} - Maintenance End is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateTaskName() {
                        if (eachdata.checkpoint == null)
                            return `Sl.No: ${checkpoint_index + 1} - Maintenance Checkpoints is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateStandardRequirement() {
                        if (eachdata.standard_requirement == null)
                            return `Sl.No: ${standard_requirement_index + 1} - Standard Requirement is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                    function validateAnswer() {
                        if (eachdata.answer_type == null)
                            return `Sl.No: ${answer_type_index + 1} - Answer Type is mandetory field - Maintenance Checkpoints.\n`;
                        return "";
                    }
                })

                // console.log(MeagedDataMaint);
                resolve(updatedMeagedDataMaint);

                ///////////////////////////  client side validation - Pre Maintenance Approval ///////////////////////////////////////////////////

                MeagedDataPreApp.map((eachdata) => {

                    if (eachdata.frequency_type == null) {
                        var frequency_type_index = MeagedDataPreApp.indexOf(eachdata);
                    }
                    if (eachdata.ma_name == null) {
                        var ma_name_index = MeagedDataPreApp.indexOf(eachdata);
                    }
                    if (eachdata.approval_category == null) {
                        var approval_category_index = MeagedDataPreApp.indexOf(eachdata);
                        // console.log(approval_index);
                    }
                    if (eachdata.sequence_approval_type === 'Sequence' &&
                        eachdata.approval_sequence === null) {
                        var approval_sequence_index = MeagedDataPreApp.indexOf(eachdata);
                        // console.log(approval_sequence_index);
                    }
                    if (eachdata.sequence_approval_type == 'Sequence' && eachdata.sequence_approval_set == null) {
                        var approval_set_index = MeagedDataPreApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type == 'Parallel' && eachdata.parallel_type == null) {
                        var parallel_type_index = MeagedDataPreApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type == 'Parallel' && eachdata.parallel_approval_set == null) {
                        var parallel_approver_id_index = MeagedDataPreApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type === null &&
                        eachdata.sequence_approval_type === null) {
                        var parallel_approver_type_index = MeagedDataPreApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type != null &&
                        eachdata.sequence_approval_type != null) {
                        var both_approver_type_index = MeagedDataPreApp.indexOf(eachdata);
                    }

                    result += validateFrequency();
                    result += validateTaskName();
                    result += validateApproval();
                    result += validateSequenceNumber();
                    result += validateSequenceApprovalId();
                    result += validateParallelType();
                    result += validateParallelApprovalId();
                    result += validateApprovalType();
                    result += validateBothApprovalType();

                    function validateFrequency() {
                        if (eachdata.frequency_type === null)
                            return `Sl.No: ${frequency_type_index + 1} - Maintenance Calendar Frequency is mandetory field - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateTaskName() {
                        if (eachdata.ma_name == null)
                            return `Sl.No: ${ma_name_index + 1} - Activity Name is mandetory field - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateApproval() {
                        if (eachdata.approval_category == null)
                            return `Sl.No: ${approval_category_index + 1} - Approval is mandetory field - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateSequenceNumber() {
                        if (eachdata.sequence_approval_type === 'Sequence' &&
                            eachdata.approval_sequence === null)
                            return `Sl.No: ${approval_sequence_index + 1} - Sequence number for Approver is mandetory field as "Approval Type" is selected as Sequence - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateSequenceApprovalId() {
                        if (eachdata.sequence_approval_type === 'Sequence' &&
                            eachdata.sequence_approval_set == null)
                            return `Sl.No: ${approval_set_index + 1} - Approver is mandetory field for Approval Type - Sequence - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateParallelType() {
                        if (eachdata.parallel_approval_type == 'Parallel' &&
                            eachdata.parallel_type == null)
                            return `Sl.No: ${parallel_type_index + 1} - All Mandatory / Random is mandetory field as "Approval Type" is selected as Parallel - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateParallelApprovalId() {
                        if (eachdata.parallel_approval_type == 'Parallel' &&
                            eachdata.parallel_approval_set == null)
                            return `Sl.No: ${parallel_approver_id_index + 1} - Approver is mandetory field for Approval Type - Parallel - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateApprovalType() {
                        if (eachdata.parallel_approval_type === null &&
                            eachdata.sequence_approval_type === null)
                            return ` Sl.No: ${parallel_approver_type_index + 1} - Select atleast one approval type either Parallel or Sequence - Pre Maintenance Approval.\n`;
                        return "";
                    }
                    function validateBothApprovalType() {
                        if (eachdata.parallel_approval_type != null &&
                            eachdata.sequence_approval_type != null)
                            return ` Sl.No: ${both_approver_type_index + 1} - Don't select both approval type in same line(Paralle & Sequence). Enter same details in next line to upload all the details - Pre Maintenance Approval.\n`;
                        return "";
                    }
                })
                MeagedDataPreApp.map((eachdata) => {

                    if ((eachdata.sequence_approval_type === null) || (eachdata.sequence_approval_type === undefined)) {
                        var sequenceType = '';
                    }

                    const data = MeagedDataPreApp

                    const index = data.findIndex(object => {
                        return object.sequence_approval_type === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].sequence_approval_type = sequenceType
                    }

                    eachdata.sequence_approval_type = eachdata.sequence_approval_type === undefined ? "" : eachdata.sequence_approval_type;
                    return eachdata;

                })
                MeagedDataPreApp.map((eachdata) => {

                    if ((eachdata.approval_sequence === null) || (eachdata.approval_sequence === undefined)) {
                        var sequenceType = '';
                    }

                    const data = MeagedDataPreApp

                    const index = data.findIndex(object => {
                        return object.approval_sequence === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].approval_sequence = sequenceType
                    }

                    eachdata.approval_sequence = eachdata.approval_sequence === undefined ? "" : eachdata.approval_sequence;
                    return eachdata;
                })
                MeagedDataPreApp.map((eachdata) => {

                    if ((eachdata.sequence_approval_set === null) || (eachdata.sequence_approval_set === undefined)) {
                        var sequenceType = '';
                    }

                    const data = MeagedDataPreApp

                    const index = data.findIndex(object => {
                        return object.sequence_approval_set === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].sequence_approval_set = sequenceType
                    }

                    eachdata.sequence_approval_set = eachdata.sequence_approval_set === undefined ? "" : eachdata.sequence_approval_set;
                    return eachdata;


                })
                MeagedDataPreApp.map((eachdata) => {

                    if ((eachdata.parallel_approval_type === null) || (eachdata.parallel_approval_type === undefined)) {
                        var parallelType = '';
                    }

                    const data = MeagedDataPreApp

                    const index = data.findIndex(object => {
                        return object.parallel_approval_type === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].parallel_approval_type = parallelType
                    }

                    eachdata.parallel_approval_type = eachdata.parallel_approval_type === undefined ? "" : eachdata.parallel_approval_type;
                    return eachdata;

                })
                MeagedDataPreApp.map((eachdata) => {

                    if ((eachdata.parallel_type === null) || (eachdata.parallel_type === undefined)) {
                        var parallelType = '';
                    }

                    const data = MeagedDataPreApp

                    const index = data.findIndex(object => {
                        return object.parallel_type === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].parallel_type = parallelType
                    }

                    eachdata.parallel_type = eachdata.parallel_type === undefined ? "" : eachdata.parallel_type;
                    return eachdata;

                })
                MeagedDataPreApp.map((eachdata) => {
                    eachdata.parallel_type = eachdata.parallel_type === "All Mandatory" ? "all_mandatory" : eachdata.parallel_type;
                    return eachdata;
                })
                MeagedDataPreApp.map((eachdata) => {
                    eachdata.parallel_type = eachdata.parallel_type === "Random" ? "any_one" : eachdata.parallel_type;
                    return eachdata;
                })
                MeagedDataPreApp.map((eachdata) => {

                    if ((eachdata.parallel_approval_set === null) || (eachdata.parallel_approval_set === undefined)) {
                        var parallelType = '';
                    }

                    const data = MeagedDataPreApp

                    const index = data.findIndex(object => {
                        return object.parallel_approval_set === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].parallel_approval_set = parallelType
                    }

                    eachdata.parallel_approval_set = eachdata.parallel_approval_set === undefined ? "" : eachdata.parallel_approval_set;
                    return eachdata;

                })

                MeagedDataPreApp.map((eachdata) => {

                    if (eachdata.ma_sla_interval === null) {
                        var sla = '';
                    }

                    const slaType = MeagedDataPreApp

                    const index = slaType.findIndex(object => {
                        return object.ma_sla_interval === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        slaType[index].ma_sla_interval = sla
                    }

                })

                MeagedDataPreApp.sort((a, b) => {
                    return a.approval_sequence - b.approval_sequence;
                });



                // console.log('MeagedDataPreApp', MeagedDataPreApp);

                // console.log(MeagedDataPreApp);
                resolve(MeagedDataPreApp);

                ///////////////////////////  client side validation - Pre Maintenance Check ///////////////////////////////////////////////////

                updatedMeagedDataPreMaint.map((eachdata) => {

                    if (eachdata.frequency_type == null) {
                        var frequency_type_index = updatedMeagedDataPreMaint.indexOf(eachdata);
                    }

                    if (eachdata.ma_name == null) {
                        var ma_name_index = updatedMeagedDataPreMaint.indexOf(eachdata);
                    }

                    if (eachdata.checkpoint == null) {
                        var checkpoint_index = updatedMeagedDataPreMaint.indexOf(eachdata);
                    }

                    if (eachdata.standard_requirement == null) {
                        var standard_requirement_index = updatedMeagedDataPreMaint.indexOf(eachdata);
                    }

                    if (eachdata.answer_type == null) {
                        var answer_type_index = updatedMeagedDataPreMaint.indexOf(eachdata);
                    }

                    result += validateFrequency();
                    result += validateActivityName();
                    result += validateTaskName();
                    result += validateStandardRequirement();
                    result += validateAnswer();

                    function validateFrequency() {
                        if (eachdata.frequency_type == null)
                            return `Sl.No: ${frequency_type_index + 1} - Maintenance Calendar Frequency is mandetory field - Pre Maintenance Check.\n`;
                        return "";
                    }
                    function validateActivityName() {
                        if (eachdata.ma_name == null)
                            return `Sl.No: ${ma_name_index + 1} - Activity Name is mandetory field - Pre Maintenance Check.\n`;
                        return "";
                    }

                    function validateTaskName() {
                        if (eachdata.checkpoint == null)
                            return `Sl.No: ${checkpoint_index + 1} - Pre Maintenance Checkpoints are mandetory field - Pre Maintenance Check.\n`;
                        return "";
                    }
                    function validateStandardRequirement() {
                        if (eachdata.standard_requirement == null)
                            return `Sl.No: ${standard_requirement_index + 1} - Standard Requirement is mandetory field - Pre Maintenance Check.\n`;
                        return "";
                    }
                    function validateAnswer() {
                        if (eachdata.answer_type == null)
                            return `Sl.No: ${answer_type_index + 1} - Answer Type is mandetory field - Pre Maintenance Check.\n`;
                        return "";
                    }
                })
                resolve(updatedMeagedDataPreMaint);

                ///////////////////////////  client side validation - Post Maintenance Check ///////////////////////////////////////////////////

                updatedMeagedDataPostMaint.map((eachdata) => {

                    if (eachdata.frequency_type == null) {
                        var frequency_type_index = updatedMeagedDataPostMaint.indexOf(eachdata);
                    }

                    if (eachdata.ma_name == null) {
                        var ma_name_index = updatedMeagedDataPostMaint.indexOf(eachdata);
                    }

                    if (eachdata.checkpoint == null) {
                        var checkpoint_index = updatedMeagedDataPostMaint.indexOf(eachdata);
                    }

                    if (eachdata.standard_requirement == null) {
                        var standard_requirement_index = updatedMeagedDataPostMaint.indexOf(eachdata);
                    }

                    if (eachdata.answer_type == null) {
                        var answer_type_index = updatedMeagedDataPostMaint.indexOf(eachdata);
                    }

                    result += validateFrequency();
                    result += validateActivityName();
                    result += validateTaskName();
                    result += validateStandardRequirement();
                    result += validateAnswer();


                    function validateFrequency() {
                        if (eachdata.frequency_type == null)
                            return `Sl.No: ${frequency_type_index + 1} - Maintenance Calendar Frequency is mandetory field - Post Maintenance Check.\n`;
                        return "";
                    }
                    function validateActivityName() {
                        if (eachdata.ma_name == null)
                            return `Sl.No: ${ma_name_index + 1} - Activity Name is mandetory field - Post Maintenance Check.\n`;
                        return "";
                    }

                    function validateTaskName() {
                        if (eachdata.checkpoint == null)
                            return `Sl.No: ${checkpoint_index + 1} - Post Maintenance Checkpoints are mandetory field - Post Maintenance Check.\n`;
                        return "";
                    }
                    function validateStandardRequirement() {
                        if (eachdata.standard_requirement == null)
                            return `Sl.No: ${standard_requirement_index + 1} - Standard Requirement is mandetory field - Post Maintenance Check.\n`;
                        return "";
                    }
                    function validateAnswer() {
                        if (eachdata.answer_type == null)
                            return `Sl.No: ${answer_type_index + 1} - Answer Type is mandetory field - Post Maintenance Check.\n`;
                        return "";
                    }
                })
                resolve(updatedMeagedDataPostMaint);

                ///////////////////////////  client side validation - Post Maintenance Approval ///////////////////////////////////////////////////

                MeagedDataPostApp.map((eachdata) => {

                    if (eachdata.frequency_type == null) {
                        var frequency_type_index = MeagedDataPostApp.indexOf(eachdata);
                    }
                    if (eachdata.ma_name == null) {
                        var ma_name_index = MeagedDataPostApp.indexOf(eachdata);
                    }
                    if (eachdata.sequence_approval_type === 'Sequence' &&
                        eachdata.approval_sequence === null) {
                        var approval_sequence_index = MeagedDataPostApp.indexOf(eachdata);
                        // console.log(approval_sequence_index);
                    }
                    if (eachdata.sequence_approval_type == 'Sequence' && eachdata.sequence_approval_set == null) {
                        var approval_set_index = MeagedDataPostApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type == 'Parallel' && eachdata.parallel_type == null) {
                        var parallel_type_index = MeagedDataPostApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type == 'Parallel' && eachdata.parallel_approval_set == null) {
                        var parallel_approver_id_index = MeagedDataPostApp.indexOf(eachdata);
                    }
                    if (eachdata.parallel_approval_type === null &&
                        eachdata.sequence_approval_type === null) {
                        var parallel_approver_type_index = MeagedDataPostApp.indexOf(eachdata);
                    }

                    if (eachdata.parallel_approval_type != null &&
                        eachdata.sequence_approval_type != null) {
                        var both_approver_type_index = MeagedDataPostApp.indexOf(eachdata);
                    }

                    result += validateFrequency();
                    result += validateTaskName();
                    result += validateSequenceNumber();
                    result += validateSequenceApprovalId();
                    result += validateParallelType();
                    result += validateParallelApprovalId();
                    result += validateApprovalType();
                    result += validateBothApprovalType();


                    function validateFrequency() {
                        if (eachdata.frequency_type == null)
                            return `Sl.No: ${frequency_type_index + 1} - Maintenance Calendar Frequency is mandetory field - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateTaskName() {
                        if (eachdata.ma_name == null)
                            return `Sl.No: ${ma_name_index + 1} - Activity Name is mandetory field - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateSequenceNumber() {
                        if (eachdata.sequence_approval_type === 'Sequence' &&
                            eachdata.approval_sequence === null)
                            return `Sl.No: ${approval_sequence_index + 1} - Sequence number for Approver is mandetory field as "Approval Type" is selected as Sequence - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateSequenceApprovalId() {
                        if (eachdata.sequence_approval_type === 'Sequence' &&
                            eachdata.sequence_approval_set == null)
                            return `Sl.No: ${approval_set_index + 1} - Approver is mandetory field for Approval Type - Sequence - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateParallelType() {
                        if (eachdata.parallel_approval_type == 'Parallel' &&
                            eachdata.parallel_type == null)
                            return `Sl.No: ${parallel_type_index + 1} - All Mandatory / Random is mandetory field as "Approval Type" is selected as Parallel - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateParallelApprovalId() {
                        if (eachdata.parallel_approval_type == 'Parallel' &&
                            eachdata.parallel_approval_set == null)
                            return `Sl.No: ${parallel_approver_id_index + 1} - Approver is mandetory field for Approval Type - Parallel - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateApprovalType() {
                        if (eachdata.parallel_approval_type === null &&
                            eachdata.sequence_approval_type === null)
                            return ` Sl.No: ${parallel_approver_type_index + 1} - Select atleast one approval type either Parallel or Sequence - Post Maintenance Approval.\n`;
                        return "";
                    }
                    function validateBothApprovalType() {
                        if (eachdata.parallel_approval_type != null &&
                            eachdata.sequence_approval_type != null)
                            return ` Sl.No: ${both_approver_type_index + 1} - Don't select both approval type in same line(Paralle & Sequence). Enter same details in next line to upload all the details - Post Maintenance Approval.\n`;
                        return "";
                    }
                })

                MeagedDataPostApp.map((eachdata) => {

                    if ((eachdata.sequence_approval_type === null) || (eachdata.sequence_approval_type === undefined)) {
                        var sequenceType = '';
                    }

                    const data = MeagedDataPostApp

                    const index = data.findIndex(object => {
                        return object.sequence_approval_type === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].sequence_approval_type = sequenceType
                    }


                    eachdata.sequence_approval_type = eachdata.sequence_approval_type === undefined ? "" : eachdata.sequence_approval_type;
                    return eachdata;

                })

                MeagedDataPostApp.map((eachdata) => {

                    if ((eachdata.approval_sequence === null) || (eachdata.approval_sequence === undefined)) {
                        var sequenceType = '';
                    }

                    const data = MeagedDataPostApp

                    const index = data.findIndex(object => {
                        return object.approval_sequence === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].approval_sequence = sequenceType
                    }

                    eachdata.approval_sequence = eachdata.approval_sequence === undefined ? "" : eachdata.approval_sequence;
                    return eachdata;

                })

                MeagedDataPostApp.map((eachdata) => {

                    if ((eachdata.sequence_approval_set === null) || (eachdata.sequence_approval_set === undefined)) {
                        var sequenceType = '';
                    }

                    const data = MeagedDataPostApp

                    const index = data.findIndex(object => {
                        return object.sequence_approval_set === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].sequence_approval_set = sequenceType
                    }

                    eachdata.sequence_approval_set = eachdata.sequence_approval_set === undefined ? "" : eachdata.sequence_approval_set;
                    return eachdata;

                })
                MeagedDataPostApp.map((eachdata) => {

                    if ((eachdata.parallel_approval_type === null) || (eachdata.parallel_approval_type === undefined)) {
                        var parallelType = '';
                    }

                    const data = MeagedDataPostApp

                    const index = data.findIndex(object => {
                        return object.parallel_approval_type === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].parallel_approval_type = parallelType
                    }

                    eachdata.parallel_approval_type = eachdata.parallel_approval_type === undefined ? "" : eachdata.parallel_approval_type;
                    return eachdata;

                })

                MeagedDataPostApp.map((eachdata) => {

                    if ((eachdata.parallel_type === null) || (eachdata.parallel_type === undefined)) {
                        var parallelType = '';
                    }

                    const data = MeagedDataPostApp

                    const index = data.findIndex(object => {
                        return object.parallel_type === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].parallel_type = parallelType
                    }

                    eachdata.parallel_type = eachdata.parallel_type === undefined ? "" : eachdata.parallel_type;
                    return eachdata;

                })

                MeagedDataPostApp.map((eachdata) => {
                    eachdata.parallel_type = eachdata.parallel_type === "All Mandatory" ? "all_mandatory" : eachdata.parallel_type;
                    return eachdata;
                })
                MeagedDataPostApp.map((eachdata) => {
                    eachdata.parallel_type = eachdata.parallel_type === "Random" ? "any_one" : eachdata.parallel_type;
                    return eachdata;
                })


                MeagedDataPostApp.map((eachdata) => {

                    if ((eachdata.parallel_approval_set === null) || (eachdata.parallel_approval_set === undefined)) {
                        var parallelType = '';
                    }

                    const data = MeagedDataPostApp

                    const index = data.findIndex(object => {
                        return object.parallel_approval_set === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        data[index].parallel_approval_set = parallelType
                    }

                    eachdata.parallel_approval_set = eachdata.parallel_approval_set === undefined ? "" : eachdata.parallel_approval_set;
                    return eachdata;

                })

                MeagedDataPostApp.map((eachdata) => {

                    if (eachdata.ma_sla_interval === null) {
                        var sla = '';
                    }

                    const slaType = MeagedDataPostApp

                    const index = slaType.findIndex(object => {
                        return object.ma_sla_interval === null;
                    });

                    // console.log(index);

                    if (index !== -1) {
                        slaType[index].ma_sla_interval = sla
                    }

                })

                MeagedDataPostApp.sort((a, b) => {
                    return a.approval_sequence - b.approval_sequence;
                });

                // console.log('MeagedDataPreApp', MeagedDataPreApp);

                // console.log(MeagedDataPostApp);
                resolve(MeagedDataPostApp);

                setInspectionData({
                    ...inspectionData,
                    inspection_data: updatedMeagedDataInspection,
                })

                setmaintenanceData({
                    ...maintenanceData,
                    maintenance_data: updatedMeagedDataMaint,
                    pre_maintenance_approval: MeagedDataPreApp,
                    pre_maintenance_check: updatedMeagedDataPreMaint,
                    post_maintenance_approval: MeagedDataPostApp,
                    post_maintenance_check: updatedMeagedDataPostMaint,

                });

                // if (wsname.includes(inspection)) {
                //     alert("Please enter atleast one Inspection Checkpoint")
                //     setDisEmptyInArray(true)
                // // }

                if (((wsname.includes("inspection")) && (MeagedDataInspection.length === 0)) ||
                    ((wsname.includes("Maintenance Checkpoints")) && (MeagedDataMaint.length === 0 && MeagedDataPreApp.length === 0
                        && MeagedDataPreMaint.length === 0 &&
                        MeagedDataPostMaint.length === 0 &&
                        MeagedDataPostApp.length === 0))) {
                    alert("Please enter atleast one Inspection / Maintenance activity")
                    setDisEmptyMainArray(true)
                }
                // console.log('result', result);
                if (result == "") return setDisabled(false);
                alert("Please resolve the following error in the attachment:\n\n" + result);
                return false;
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((data) => {
            // console.log('data', data)
        })
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const submitInspectionData = inspectionData.inspection_data
        const submitMaintenanceData = {
            mc: maintenanceData.maintenance_data,
            pma: maintenanceData.pre_maintenance_approval,
            pmc: maintenanceData.pre_maintenance_check,
            pomc: maintenanceData.post_maintenance_check,
            poma: maintenanceData.post_maintenance_approval
        }
        if (submitInspectionData.length !== 0) {
            dispatch({
                type: 'INSPECTION_BULK_ADD',
                payload: [inspectionData.asset_id, submitInspectionData, props.type]
            })
            // console.log(props.type);            
            navigate('/inspectionlist', { state: { data } })
        }
        if ((maintenanceData.maintenance_data).length !== 0) {
            dispatch({
                type: 'MAINTENANCE_BULK_ADD',
                payload: [assetid, submitMaintenanceData, props.type]
            })
            props.onHide();
            // navigate("/maintenancelist");
            navigate('/maintenancelist', { state: { data } })
        }
    }

    useEffect(() => {
        if (data === '') {
            setData(props.type)
        }
    }, [data])


    return (
        <>
            <div>
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Bulk Upload of Inspection/Maintenance
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <input
                                type="file"
                                onClick={(e)=>{
                                    e.target.value = null
                                }}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    readExcel(file);
                                }}
                            />
                        </div>
                        <br />
                        <br />
                        <span>
                            Download Inspection Bulk Upload Template -
                            <a
                                href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Inspection_Bulk_Upload_V1_0.xlsx`}
                            >
                                Click here
                            </a>
                        </span>
                        <br />
                        <br />
                        <span>
                            Download Maintenance Bulk Upload Template -
                            <a
                                href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Maintenance_Bulk_Upload_V1_0.xlsx`}
                            >
                                Click here
                            </a>
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleSubmit}
                            disabled={disabled || disEmptyMainArray}
                        >
                            Upload
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}


export default CreateBulkSchedule;