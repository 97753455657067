import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import InspectionReport from "./inspectionReport";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Commonheader from "../../../layouts/common/commonHeader";
import "../../../css/modules/report/workorderReport/inspection.css"


const Genratepdf = () => {
  const componentRef = useRef();
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("Asset");
  const [data, setData] = useState({
    currentPage: "",
    itemsPerPage: "",
    startDate: "",
    endDate: "",
    searchInput: "",
    name: "",
  });
  const [fileName, setFileName] = useState(""); // To set Custom file name

  const insReportDataArray = useSelector((state) => state.inspection.insReportData);


  const handlePrint = () => {
    componentRef.current.handlePrint();
  };

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0]);
      setSelectedItem(location.state?.[1]);
      setData({
        currentPage: location.state?.[2],
        itemsPerPage: location.state?.[3],
        startDate: location.state?.[4],
        endDate: location.state?.[5],
        searchInput: location.state?.[6],
        name: location.state?.[7],
      });
      const dynamicFileName = generateFileName();
      setFileName(dynamicFileName);
    }
  }, [insReportDataArray]);
  // Function to generate the custom file name dynamically
  const generateFileName = () => {
    // Check if the array is empty
    if (!Array.isArray(insReportDataArray) || insReportDataArray.length === 0) {
      return "Report_NoDataAvailable_V1.09.pdf";
    }

    // Iterate over the array and extract required information
    const fileDetails = insReportDataArray.map((item, index) => {
      const parentId = item?.parent?.parent_id || "";
      const endDate = item?.inspection?.end_date
        ? moment(item.inspection.end_date).format("YYYY-MM-DD")
        : "";
      return `${parentId}_${endDate}`;
    });

    // Join details with a separator for a comprehensive filename
    const fileName = `${fileDetails?.length > 1 ? `Merged_Inspection_Reports` : "Inspection_Report_" + fileDetails.join("_")}_V1.09.pdf`;

    return fileName;
  };
  const handleBeforePrint = () => {
    document.title = fileName;  // Set custom title
  };

  const handleAfterPrint = () => {
    document.title = fileName; // Reset to default after printing
  };

  return (
    <div>
      <Commonheader />
      <h4 className="listHeadings">Inspection Report</h4>
      {/* Back Btn */}
      <div className="container">
        {/* <!-- Button Container --> */}
        <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
          {/* <!-- Back Button --> */}
          <button
            type="button"
            onClick={() => {
              navigate(
                data.name === "Inspection" ? "/inspectionstatus" : "/ondemandstatus",
                {
                  state: [
                    key,
                    selectedItem?.toLowerCase(),
                    data?.currentPage,
                    data?.itemsPerPage,
                    data?.startDate,
                    data?.endDate,
                    data?.searchInput,
                  ],
                }
              );
            }}
            className="backButton my-2 my-sm-0 mx-sm-4 work-order-report-btn"
          >
            Back
          </button>
          {/* <!-- Download Button (Print) --> */}
          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                onClick={handlePrint}
                className="saveButton my-2 my-sm-0 mx-sm-4 download-btn work-order-report-btn"
              >
                Download
              </button>
            )}
            content={() => componentRef.current}
            documentTitle={fileName}
            onBeforePrint={handleBeforePrint}   // Set before print
            onAfterPrint={handleAfterPrint}     // Reset after print
            pageStyle={`
              @page {
                size: 'A4 landscape';
                margin: 5%;
              }
              @media print {
                body * {
                  visibility: hidden; /* Hide everything by default */
                  -webkit-print-color-adjust: exact !important; /* Safari/Chrome */
                  print-color-adjust: exact !important; /* Standard */
                }
                * {
                  -webkit-print-color-adjust: exact !important;
                  print-color-adjust: exact !important;
                }
                #print-section, #print-section * {
                  visibility: visible; /* Only show the print section */
                }
                #print-section {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                }
              }
              @media print {
                #page-header {
                  position: fixed;
                  top: 0;
                  left: 0;
                  right: 0;
                  background-color: lightgray;
                  margin-top: 30px;
                  height: 50px;
                  page-break-inside: avoid;
                }
            }
              #content {
                page-break-before: always;
              }
                
            `}
          />
        </div>
      </div>

      <div ref={componentRef} id="print-section" className="report-pdf">
        <div>
          {/* Add the necessary content here */}
          <InspectionReport />
        </div>
      </div>

    </div>
  );
  // }
};

export default Genratepdf;
