/* Feature - Parent component of Inspection & Maintenace card status in landing page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React from "react";
import StatusComponent from "./statusComponent";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RadialInsChart from "./radialInsChart";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../css/modules/home/statusChart.css";
function StatusChart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userid = useSelector((state) => state.auth.userid);
  const selectedSite = useSelector((state) => state.site.selectedSiteList);
  const accessOnDemand = useSelector(
    (state) => state.access.onDemandInspection
  );
  const accessTicket = useSelector((state) => state.access.breakdown);
  const offline_access = useSelector((state) => state.access.offline_access);
  const assetMaintenance = useSelector(
    (state) => state.access.assetMaintenance
  );
  const deviceMaintenance = useSelector(
    (state) => state.access.deviceMaintenance
  );
  const facilityMaintenance = useSelector(
    (state) => state.access.facilityMaintenance
  );
  const assetInspection = useSelector((state) => state.access.assetInspection);
  const deviceInspection = useSelector(
    (state) => state.access.deviceInspection
  );
  const facilityInspection = useSelector(
    (state) => state.access.facilityInspection
  );

  let inspection_info = {
    total_planned: "Total planned for the day (Trigged + Yet to be Triggered)",
    // yet_to_assign: 'Total trigged inspection activity, which yet to be assigned to technician',
    // assigned: 'Total trigged inspection activity, which are already assigned to the technician',
    // in_progress: 'Total ongoing activities (inprogress task & waiting for approval task)',
    // task_in_progress: '-',
    waiting_for_approval:
      "Count of activity, which are completed by technician and waiting for approval",
    completed: "Count of approved & auto approved activity",
    // pending: 'Total - (inprogress + completed + Skipped)',
    skipped: "Count of not performed activity during allowed timeline",
    // rejected: 'Count of rejected task',
    // cancelled: 'Count of cancelled task',
    // yet_to_be_triggered_count: 'The count of inspection activities yet to be triggered will begin upon reaching their scheduled trigger times.'
    reopened: "Count of activity which are completed but reopened by approver",
  };
  let maintenance_info = {
    total_planned: "Total planned for the week (Trigged + Yet to be Triggered)",
    yet_to_assign:
      "Total trigged maintenance activity, which yet to be assigned to technician",
    assigned:
      "Total trigged maintenance activity, which are already assigned to the technician",
    in_progress:
      "Total ongoing activities (inprogress task & waiting for approval task)",
    task_in_progress: "-",
    waiting_for_approval:
      "Count of activity, which are completed by technician and waiting for approval",
    completed: "Count of approved & auto approved activity",
    pending: "Total - (inprogress + completed + Skipped)",
    skipped: "Count of not performed activity during allowed timeline",
    rejected: "Count of rejected task",
    cancelled: "Count of cancelled task",
    yet_to_be_triggered_count:
      "The count of inspection activities yet to be triggered will begin upon reaching their scheduled trigger times.",
    reopened: "Count of activity which are completed but reopened by approver",
  };
  let ondemand_info = {
    in_progress: "Count of activity started by the technician",
    waiting_for_approval:
      "Count of activity, which are completed by technician and waiting for approval",
    completed: "Count of approved & auto approved activity",
    save_as_draft: "Count of activity, which are saved as draft",
    re_opened:
      "Count of activity, which are re-opened by Approver and assigned to the same technician to re-do the task.",
    // task_in_progress: '-',
    // total_planned: 'Total planned for the week (Trigged + Yet to be Triggered)',
    // yet_to_assign: 'Total trigged maintenance activity, which yet to be assigned to technician',
    // assigned: 'Total trigged maintenance activity, which are already assigned to the technician',
    // pending: 'Count = Total - (inprogress + completed)',
    // skipped: 'Count of not performed activity during allowed timeline',
    // rejected: '-',
    // cancelled: '-'
  };
  let ticket_info = {
    total_planned: "Total ticket for the day",
    yet_to_assign:
      "Total trigged inspection activity, which yet to be assigned to technician",
    assigned:
      "Total trigged inspection activity, which are already assigned to the technician",
    // in_progress: 'Total ongoing activities (inprogress task & waiting for approval task)',
    // task_in_progress: '-',
    waiting_for_approval:
      "Count of activity, which are completed by technician and waiting for approval",
    completed: "Count of approved & auto approved activity",
    // pending: 'Total - (inprogress + completed + Skipped)',
    skipped: "Count of not performed activity during allowed timeline",
    // rejected: 'Count of rejected task',
    // cancelled: 'Count of cancelled task',
    // yet_to_be_triggered_count: 'The count of inspection activities yet to be triggered will begin upon reaching their scheduled trigger times.'
    reopened: "Count of activity which are completed but reopened by approver",
  };

  const [insStatus, setinsStatus] = useState({
    totalPlanned: "",
    assigned: "",
    yetToBeAssigned: "",
    inProgress: "",
    waitingApproval: "",
    completed: "",
    pending: "",
    skipped: "",
    rejected: "",
    cancelled: "",
    ondemandIndraft: "",
    ondemandWaitingForApproval: "",
    ondemandApproved: "",
    ondemandSaveAsDraft: "",
    ondemandReopened: "",
  });
  const [onDemandStatus, setOnDemandStatus] = useState({
    ondemandIndraft: "",
    ondemandWaitingForApproval: "",
    ondemandApproved: "",
    ondemandSaveAsDraft: "",
    ondemandReopened: "",
  });
  const [mainStatus, setmainStatus] = useState({
    totalPlanned: "",
    assigned: "",
    yetToBeAssigned: "",
    inProgress: "",
    waitingApproval: "",
    completed: "",
    pending: "",
    skipped: "",
    rejected: "",
    cancelled: "",
  });
  const [ticketStatus, setTicketStatus] = useState({
    totalPlanned: 0,
    assigned: 0,
    yetToBeAssigned: 0,
    inProgress: 0,
    waitingApproval: 0,
    completed: 0,
    // pending: '',
    // skipped: '',
    rejected: 0,
    cancelled: 0,
    reopened: 0,
    reassigned: 0,
  });

  const [startDateIns, setStartDateIns] = useState(null);
  const [endDateIns, setEndDateIns] = useState(null);
  const [startDateMain, setStartDateMain] = useState(null);
  const [endDateMain, setEndDateMain] = useState(null);
  const [startDateOnDemand, setStartDateOnDemand] = useState(null);
  const [endDateOnDemand, setEndDateOnDemand] = useState(null);
  const [startDateTicket, setStartDateTicket] = useState(null);
  const [endDateTicket, setEndDateTicket] = useState(null);

  //////////////////////////// fetch user role from redux//////////////////////////////
  const InsMainData = useSelector((state) => state.dashboard.InsMainStatusData);

  const handleDateChangeIns = (dates) => {
    const [start, end] = dates;
    setStartDateIns(start);
    setEndDateIns(end);
  };
  const handleDateChangeMain = (dates) => {
    const startOfWeek = moment(dates).startOf("week").toDate();
    const endOfWeek = moment(dates).endOf("week").toDate();
    setStartDateMain(startOfWeek);
    setEndDateMain(endOfWeek);
    // }
  };
  const handleDateChangeOndemand = (dates) => {
    const [start, end] = dates;

    setStartDateOnDemand(start);
    setEndDateOnDemand(end);
  };
  const handleDateChangeTicket = (dates) => {
    const [start, end] = dates;
    setStartDateTicket(start);
    setEndDateTicket(end);
  };

  useEffect(()=>{
    
    if(selectedSite?.selectedSite?.length>0){
      if (offline_access?.r === false) {
  
        dispatch({
          type: "INS_MAIN_GET_STATUS",
          // payload: ["", "", moment().format("YYYY-MM-DD"), moment().startOf('week').format("YYYY-MM-DD"), "", "", userid],
          payload: [
            "",
            "",
            moment().startOf("week").format("YYYY-MM-DD"),
            moment().endOf("week").format("YYYY-MM-DD"),
            "",
            "",
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            userid,
          ],
        });
      }
      if (offline_access?.r === true) {
  
        dispatch({
          type: "INS_MAIN_GET_STATUS_OFFLINE",
          // payload: ["", "", moment().format("YYYY-MM-DD"), moment().startOf('week').format("YYYY-MM-DD"), "", "", userid],
          payload: [
            "",
            "",
            moment().startOf("week").format("YYYY-MM-DD"),
            moment().endOf("week").format("YYYY-MM-DD"),
            "",
            "",
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            userid,
          ],
        });
      }
    }
  },[selectedSite])

  ///////////////////////// useEffect/////////////////////////////
  useEffect(() => {
    if (
      !InsMainData.inspection &&
      startDateIns === null &&
      endDateIns === null &&
      startDateMain === null &&
      endDateMain === null &&
      startDateOnDemand === null &&
      endDateOnDemand === null &&
      startDateTicket === null &&
      endDateTicket === null
    ) {
      if (offline_access?.r === false) {
        dispatch({
          type: "INS_MAIN_GET_STATUS",
          // payload: ["", "", moment().format("YYYY-MM-DD"), moment().startOf('week').format("YYYY-MM-DD"), "", "", userid],
          payload: [
            "",
            "",
            moment().startOf("week").format("YYYY-MM-DD"),
            moment().endOf("week").format("YYYY-MM-DD"),
            "",
            "",
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            userid,
          ],
        });
      }
      if (offline_access?.r === true) {
        dispatch({
          type: "INS_MAIN_GET_STATUS_OFFLINE",
          // payload: ["", "", moment().format("YYYY-MM-DD"), moment().startOf('week').format("YYYY-MM-DD"), "", "", userid],
          payload: [
            "",
            "",
            moment().startOf("week").format("YYYY-MM-DD"),
            moment().endOf("week").format("YYYY-MM-DD"),
            "",
            "",
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            userid,
          ],
        });
      }
    }
    if (InsMainData.length !== 0) {
      setinsStatus({
        totalPlanned: InsMainData?.inspection?.total_count,
        assigned: InsMainData?.inspection?.assigned_count,
        yetToBeAssigned: InsMainData?.inspection?.yet_to_be_assigned,
        inProgress: InsMainData?.inspection?.inprogress_count,
        waitingApproval: InsMainData?.inspection?.waiting_for_approval_count,
        completed: InsMainData?.inspection?.completed_count,
        pending:
          InsMainData?.inspection?.total_count -
          (InsMainData?.inspection?.inprogress_count +
            InsMainData?.inspection?.completed_count +
            InsMainData?.inspection?.skipped_count),
        skipped: InsMainData?.inspection?.skipped_count,
        rejected: InsMainData?.inspection?.rejected_count,
        cancelled: InsMainData?.inspection?.cancelled_count,
        yet_to_be_triggered_count:
          InsMainData?.inspection?.yet_to_be_triggered_count,
        reopened: InsMainData?.inspection?.reopened_count,
      });
      setOnDemandStatus({
        ondemandApproved: InsMainData?.on_demand?.ondemand_completed_count,
        ondemandIndraft: InsMainData?.on_demand?.ondemand_indraft_count,
        ondemandReopened: InsMainData?.on_demand?.ondemand_reopened_count,
        ondemandWaitingForApproval:
          InsMainData?.on_demand?.ondemand_waiting_for_approval_count,
        ondemandSaveAsDraft: InsMainData?.on_demand?.ondemand_indraft_count,
      });
      setmainStatus({
        totalPlanned: InsMainData?.maintenance?.total_count,
        assigned: InsMainData?.maintenance?.assigned_count,
        yetToBeAssigned: InsMainData?.maintenance?.yet_to_be_assigned,
        inProgress: InsMainData?.maintenance?.inprogress_count,
        waitingApproval: InsMainData?.maintenance?.waiting_for_approval_count,
        completed: InsMainData?.maintenance?.completed_count,
        pending:
          InsMainData?.maintenance?.total_count -
          (InsMainData?.maintenance?.inprogress_count +
            InsMainData?.maintenance?.completed_count),
        skipped: InsMainData?.maintenance?.skipped_count,
        rejected: InsMainData?.maintenance?.rejected_count,
        cancelled: InsMainData?.maintenance?.cancelled_count,
        yet_to_be_triggered_count:
          InsMainData?.maintenance?.yet_to_be_triggered_count,
        reopened: InsMainData?.maintenance?.reopened_count, // Need to come from backend
      });
      setTicketStatus({
        totalPlanned: InsMainData?.ticket?.total_count,
        assigned: InsMainData?.ticket?.assigned_count,
        yetToBeAssigned: InsMainData?.ticket?.yet_to_be_assigned_count,
        inProgress: InsMainData?.ticket?.inprogress_count,
        waitingApproval: InsMainData?.ticket?.waiting_for_approval_count,
        completed: InsMainData?.ticket?.completed_count,
        // pending: (InsMainData?.ticket?.total_count) - ((InsMainData?.ticket?.inprogress_count) + (InsMainData?.ticket?.completed_count) + (InsMainData?.ticket?.skipped_count)),
        // skipped: InsMainData?.ticket?.skipped_count,
        rejected: InsMainData?.ticket?.rejected_count,
        cancelled: InsMainData?.ticket?.cancelled_count,
        yet_to_be_triggered_count:
          InsMainData?.ticket?.yet_to_be_triggered_count,
        reopened: InsMainData?.ticket?.reopened_count,
        reassigned: InsMainData?.ticket?.reassigned_count,
      });
    }
  }, [InsMainData]);

  useEffect(() => {
    if (
      (InsMainData.length !== 0 &&
        ((startDateIns !== null && endDateIns !== null) ||
          (startDateMain !== null && endDateMain !== null) ||
          (startDateOnDemand !== null && endDateOnDemand !== null))) ||
      (startDateTicket !== null && endDateTicket !== null)
    ) {
      if (offline_access?.r === false) {
        dispatch({
          type: "INS_MAIN_GET_STATUS",
          payload: [
            startDateIns !== null
              ? moment(startDateIns).format("YYYY-MM-DD")
              : "",
            endDateIns !== null ? moment(endDateIns).format("YYYY-MM-DD") : "",
            startDateMain !== null
              ? moment(startDateMain).format("YYYY-MM-DD")
              : moment().startOf("week").format("YYYY-MM-DD"),
            endDateMain !== null
              ? moment(endDateMain).format("YYYY-MM-DD")
              : moment().endOf("week").format("YYYY-MM-DD"),
            startDateOnDemand !== null
              ? moment(startDateOnDemand).format("YYYY-MM-DD")
              : "",
            endDateOnDemand !== null
              ? moment(endDateOnDemand).format("YYYY-MM-DD")
              : "",
            startDateTicket !== null
              ? moment(startDateTicket).format("YYYY-MM-DD")
              : "",
            endDateTicket !== null
              ? moment(endDateTicket).format("YYYY-MM-DD")
              : "",
            userid,
          ],
        });
      }
      if (offline_access?.r === true) {
        dispatch({
          type: "INS_MAIN_GET_STATUS_OFFLINE",
          payload: [
            startDateIns !== null
              ? moment(startDateIns).format("YYYY-MM-DD")
              : "",
            endDateIns !== null ? moment(endDateIns).format("YYYY-MM-DD") : "",
            startDateMain !== null
              ? moment(startDateMain).format("YYYY-MM-DD")
              : moment().startOf("week").format("YYYY-MM-DD"),
            endDateMain !== null
              ? moment(endDateMain).format("YYYY-MM-DD")
              : moment().endOf("week").format("YYYY-MM-DD"),
            startDateOnDemand !== null
              ? moment(startDateOnDemand).format("YYYY-MM-DD")
              : "",
            endDateOnDemand !== null
              ? moment(endDateOnDemand).format("YYYY-MM-DD")
              : "",
            startDateTicket !== null
              ? moment(startDateTicket).format("YYYY-MM-DD")
              : "",
            endDateTicket !== null
              ? moment(endDateTicket).format("YYYY-MM-DD")
              : "",
            userid,
          ],
        });
      }
    }
  }, [
    startDateIns,
    endDateIns,
    startDateMain,
    endDateMain,
    startDateOnDemand,
    endDateOnDemand,
    startDateTicket,
    endDateTicket,
  ]);

  // /////////////////////////////////////////////    handle navingation - inspection & maintenance    ///////////////////////////////////////

  // Total status :
  const totalIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "total",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const totalMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "total",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const totalTicket = () => {
    navigate("/ticketstatus", {
      state: [
        "total",
        "all",
        1,
        10,
        moment(startDateTicket).format("YYYY-MM-DD"),
        moment(endDateTicket).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // Yet to be assigned status :
  const yetToBeAssignedTicket = () => {
    navigate("/ticketstatus", {
      state: [
        "yet_to_be_assigned",
        "all",
        1,
        10,
        moment(startDateTicket).format("YYYY-MM-DD"),
        moment(endDateTicket).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // Assigned status :

  const assignedIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "assigned",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  const assignedMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "assigned",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // waiting for approval
  const waMaint = () => {
    navigate("/maintenancestatus", {
      state: [
        "waiting_for_approval",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  const waitingIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "waiting_for_approval",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  const waOnDemand = () => {
    navigate("/ondemandstatus", {
      state: [
        "waiting_for_approval",
        "all",
        1,
        10,
        moment(startDateOnDemand).format("YYYY-MM-DD"),
        moment(endDateOnDemand).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  const waTicket = () => {
    navigate("/ticketstatus", {
      state: [
        "waiting_for_approval",
        "all",
        1,
        10,
        moment(startDateTicket).format("YYYY-MM-DD"),
        moment(endDateTicket).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // Completed status :

  const completedIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "approved",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const completedMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "approved",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  const completedOnDemand = () => {
    navigate("/ondemandstatus", {
      state: [
        "approved",
        "all",
        1,
        10,
        moment(startDateOnDemand).format("YYYY-MM-DD"),
        moment(endDateOnDemand).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  const completedTicket = () => {
    navigate("/ticketstatus", {
      state: [
        "approved",
        "all",
        1,
        10,
        moment(startDateTicket).format("YYYY-MM-DD"),
        moment(endDateTicket).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // Pending status :
  const pendingIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "pending",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const pendingMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "pending",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // Skipped status :
  const skippedIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "skipped",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const skippedMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "skipped",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // Rejected status :
  const rejectedIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "rejected",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const rejectedMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "rejected",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // re-open status
  const reopenedIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "reopened",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const reopenedMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "reopened",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const reopenedOnDemand = () => {
    navigate("/ondemandstatus", {
      state: [
        "reopened",
        "all",
        1,
        10,
        moment(startDateOnDemand).format("YYYY-MM-DD"),
        moment(endDateOnDemand).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const reopenedTicket = () => {
    navigate("/ticketstatus", {
      state: [
        "reopened",
        "all",
        1,
        10,
        moment(startDateTicket).format("YYYY-MM-DD"),
        moment(endDateTicket).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  // Cancelled status :
  const cancelledIns = () => {
    navigate("/inspectionstatus", {
      state: [
        "cancelled",
        "all",
        1,
        10,
        moment(startDateIns).format("YYYY-MM-DD"),
        moment(endDateIns).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const cancelledMain = () => {
    navigate("/maintenancestatus", {
      state: [
        "cancelled",
        "all",
        1,
        10,
        moment(startDateMain).format("YYYY-MM-DD"),
        moment(endDateMain).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  // inprogress and save as drafr
  const onDemandInprogress = () => {
    navigate("/ondemandstatus", {
      state: [
        "inprogress",
        "all",
        1,
        10,
        moment(startDateOnDemand).format("YYYY-MM-DD"),
        moment(endDateOnDemand).format("YYYY-MM-DD"),
        "",
      ],
    });
  };
  const saveAsdraftOnDemand = () => {
    navigate("/ondemandstatus", {
      state: [
        "save_as_draft",
        "all",
        1,
        10,
        moment(startDateOnDemand).format("YYYY-MM-DD"),
        moment(endDateOnDemand).format("YYYY-MM-DD"),
        "",
      ],
    });
  };

  useEffect(() => {
  }, [accessOnDemand]);
  let inspectionTooltip = [];

  return (
    <div>
      {/* Inspection Details */}
      {(assetInspection?.r || deviceInspection?.r || facilityInspection?.r) && (
        <div className="ins-statusChart">
          <StatusComponent
            name={"Inspection Status"}
            data={insStatus}
            startDate={startDateIns}
            endDate={endDateIns}
            handleDateChange={handleDateChangeIns}
            handleTotal={totalIns}
            handleAssign={assignedIns}
            handleCompleted={completedIns}
            handlePending={pendingIns}
            handleskipped={skippedIns}
            handlerejected={rejectedIns}
            handlecancelled={cancelledIns}
            handlewaiting={waitingIns}
            handleReopen={reopenedIns}
            info={inspection_info}
          />
        </div>
      )}

      {/* Maintenance Details */}
      {(assetMaintenance?.r ||
        deviceMaintenance?.r ||
        facilityMaintenance?.r) && (
          <div className="maint-statusChart" >
            <StatusComponent
              name={"Maintenance Status"}
              data={mainStatus}
              startDate={startDateMain}
              endDate={endDateMain}
              handleDateChange={handleDateChangeMain}
              handleTotal={totalMain}
              handleAssign={assignedMain}
              handleCompleted={completedMain}
              handlePending={pendingMain}
              handleskipped={skippedMain}
              handlerejected={rejectedMain}
              handlecancelled={cancelledMain}
              handlewaiting={waMaint}
              handleReopen={reopenedMain}
              info={maintenance_info}
            />
          </div>
        )}
      {/* On-Demand Details */}
      {(accessOnDemand?.c || accessOnDemand?.r || accessOnDemand?.u) && (
        <div className="onDemand-statusChart" >
          <StatusComponent
            name={"On-Demand Status"}
            data={onDemandStatus}
            startDate={startDateOnDemand}
            endDate={endDateOnDemand}
            handleDateChange={handleDateChangeOndemand}
            info={ondemand_info}
            // handleTotal={totalIns}
            // handleAssign={assignedIns}
            // handleCompleted={completedIns}
            // handlePending={pendingIns}
            // handleskipped={skippedIns}
            // handlerejected={rejectedIns}
            // handlecancelled={cancelledIns}
            handleInprogress={onDemandInprogress}
            handlewaiting={waOnDemand}
            handleCompleted={completedOnDemand}
            handleReopen={reopenedOnDemand}
            handleSaveasDraft={saveAsdraftOnDemand}
          />
        </div>
      )}
      {/* Ticket Status Count */}
      {(accessTicket?.c || accessTicket.r) && (
        <div className="ticket-statusChart">
          <StatusComponent
            name={"Ticket Status"}
            data={ticketStatus}
            startDate={startDateTicket}
            endDate={endDateTicket}
            handleDateChange={handleDateChangeTicket}
            info={ticket_info}
            handleTotal={totalTicket}
            handleCompleted={completedTicket}
            // handlePending={pendingTicket}
            handlewaiting={waTicket}
            handleYetTobeAssigned={yetToBeAssignedTicket}
            handlereopened={reopenedTicket}
            // handlecancelled={cancelledIns}
          />
        </div>
      )}

      {/* Radial chart component - Inspection */}
      {(assetInspection?.r || deviceInspection?.r || facilityInspection?.r) && (
        <div className="status-div" >
          <div className="status-heading">
            Inspection Status
          </div>
          <div>
            <RadialInsChart data={insStatus} />
          </div>
        </div>
      )}

      {/* Radial chart component - Maintenance */}
      {(assetMaintenance?.r ||
        deviceMaintenance?.r ||
        facilityMaintenance?.r) && (
          <div className="status-div" >
            <div className="status-heading" >
              Maintenance Status
            </div>
            <div style={{}}>
              <RadialInsChart data={mainStatus} />
            </div>
          </div>
        )}
    </div>
  );
}

export default StatusChart;
