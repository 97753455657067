import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { reportService } from '../../services/service.index';
import Commonheader from '../../layouts/common/commonHeader';
import { useSelector } from 'react-redux';
import "../../css/modules/report/customReport/templateReport.css"
import "../../css/modules/workorder/workorder.css"
import Pagination from '../../components/pagination/pagination';
import SearchBox from '../../components/searchBox/searchBox';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";


function TemplateReport() {

    const [data, setData] = useState([])

    // ..................Pagination States................
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const selectedSite = useSelector((state) => state.site.selectedSiteList);

    const navigate = useNavigate();


    const fetchReportTemplates = (page, limit, search) => {
        return reportService.getReportTemplate({ page, limit, search })
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data);
                }
            })
            .catch((err) => {
            });
    };

    const handleSearchInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        fetchReportTemplates(currentPage, itemsPerPage, e.target.value);
    };

    const handleClearButton = () => {
        setSearchInput("");
        fetchReportTemplates(currentPage, itemsPerPage, '');
    };

    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
        fetchReportTemplates(1, items, searchInput);
    };

    let totalPages = 0;
    if (data?.total_count !== undefined) {
        totalPages = Math.ceil(data.total_count / itemsPerPage);
    }

    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
        fetchReportTemplates(currentPage + 1, itemsPerPage, searchInput);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
        fetchReportTemplates(currentPage - 1, itemsPerPage, searchInput);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
        fetchReportTemplates(1, itemsPerPage, searchInput);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
        fetchReportTemplates(totalPages, itemsPerPage, searchInput);
    };
    // Get report list and store in data - setDat

    const getListOfReport = async (id) => {
        reportService.getReportTemplate({ page: currentPage, limit: itemsPerPage, search: searchInput, site: id }).then((res) => {
            if (res.status === 200) {
                setData(res.data);
            }
        }).catch((err) => {
        })
    }

    useEffect(() => {
        let arr = selectedSite?.selectedSite.map((site) => site._id);
        let id = Array.isArray(arr) ? arr.join(',') : arr;
        getListOfReport(id)
    }, [selectedSite])


    const handleButtonClick = (el) => {
        // navigate(`/reportform/${el._id}?filename=${encodeURIComponent(el.name)}`)
        navigate(`/reportform/${el._id}?isMulti=${el.isMulti ? el.isMulti : false}&frequency=${el?.frequency}&filename=${encodeURIComponent(el.name)}`)
    }

    return (
        <div>
            <Commonheader />
            <div className='custom-report-list-div'>
                <SearchBox searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} handleClearButton={handleClearButton} />
                <div className='custom-report-main-heading'>
                    <h3>Select Report Templates</h3>
                </div>
                <div className='d-none d-md-block'>
                    <table className="table-bordered custom-report-table" >
                        <thead>
                            <tr>
                                <th colSpan={1} className="text-center text-white py-2 custom-report-table-heading">
                                    S. No
                                </th>
                                <th colSpan={6} className="text-left text-white py-3 custom-report-table-heading ps-2">
                                    Report Name
                                </th>
                                <th colSpan={1} className="text-center text-white py-2 custom-report-table-heading">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.length > 0 ? data?.result?.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td colSpan={1} className="text-center align-middle"> {(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                                        <td colSpan={6} className="text-left align-middle custom-report-name">{el.name}</td>
                                        <td colSpan={1} className="text-center align-middle">
                                            <button onClick={() => handleButtonClick(el)} className='preview-report-btn' data-tip data-for="viewTip">
                                                <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                            </button>
                                        </td>
                                    </tr>)
                            }) : <tr className='custom-report-name text-center'>
                                <td colSpan={8}>No Data Available</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="d-md-none">
                    {data?.result?.length > 0 ? data?.result?.map((el, index) => {
                        return <div key={index} className="card-data">
                            <div className="custom-card-body">
                                <div className="custom-card-header">
                                    <h5 className="serial-number">
                                        #{(currentPage - 1) * itemsPerPage + (index + 1)}
                                    </h5>
                                </div>
                                <div className="custom-card-content">
                                    <div className="row row-mobile">
                                        <div className="col-6 key"> Report Name: </div>
                                        <div className="col-6 value">{el?.name}</div>
                                    </div>
                                    <button
                                        type="button"
                                        className="pdf-preview-btn-mobile"
                                        onClick={() => handleButtonClick(el)}
                                    >
                                        Preview Report
                                        <FontAwesomeIcon
                                            icon={faFilePdf}
                                            size="lg"
                                            className="icons"
                                        />
                                        <ReactTooltip
                                            id="viewTip"
                                            place="bottom"
                                            effect="solid"
                                        >
                                            Preview Report
                                        </ReactTooltip>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }) : ""}
                </div>
            </div>
            {/* ................Pagination................ */}
            <Pagination itemsPerPage={itemsPerPage} handleItemsPerPageChange={handleItemsPerPageChange} currentPage={currentPage} handleFirstPage={handleFirstPage} handlePrev={handlePrev} totalPages={totalPages} handleNext={handleNext} handleLastpage={handleLastpage} />
        </div >
    )
}

export default TemplateReport