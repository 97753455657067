import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import '../../css/modules/workorder/workorder.css'

function WorkOrdertabs({ name,keyValue,handleTabKey }) {

    const tabsConfig = [
        {
            condition: ["Ticket", "Inspection", "Maintenance"].includes(name),
            eventKey: "total",
            title:
                name === "Ticket"
                    ? "Total Raised"
                    : name === "Inspection"
                        ? "Total Triggered"
                        : "Total Planned",
        },
        { condition: name === "On - Demand", eventKey: "inprogress", title: "In Progress" },
        { condition: true, eventKey: "waiting_for_approval", title: "Waiting for Approval" },
        { condition: true, eventKey: "approved", title: "Completed" },
        { condition: true, eventKey: "reopened", title: "Reopened" },
        { condition: name === "Ticket", eventKey: "reassigned", title: "Reassigned" },
        { condition: name === "Ticket", eventKey: "yet_to_be_assigned", title: "Yet to Assign" },
        { condition: name === "On - Demand", eventKey: "save_as_draft", title: "Save as Draft" },
        {
            condition: ["Inspection", "Maintenance"].includes(name),
            eventKey: "skipped",
            title: "Skipped",
        },
    ];

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={keyValue}
            onSelect={(k) => handleTabKey(k)}
            className="m-3 headings d-flex flex-wrap justify-content-center justify-content-md-start"
        >
            {tabsConfig
                .filter((tab) => tab.condition)
                .map(({ eventKey, title }) => (
                    <Tab
                        key={eventKey}
                        eventKey={eventKey}
                        title={title}
                        tabClassName="tab-spacing" // Applies to Nav.Link
                    />
                ))}
        </Tabs>
    )
}

export default WorkOrdertabs
