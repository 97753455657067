/* Feature - Logout component

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React from "react";
import { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faSignOut,
  faUser,
  faUserCircle,
  faUserPen,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { Persistor } from "../redux/store.index";
import SiteFilter from "./SiteFilter/SiteFilter";
// import Cookies from 'js-cookie';
import '../css/component/logout.css'

// function for Logout
const LogoutButton = ({ sitefilter }) => {

  const navigate = useNavigate();

  // from redux
  const dispatch = useDispatch();
  const reducerToken = useSelector((state) => state.auth.userToken);
  const email = useSelector((state) => state.auth.email);
  const userProfileData = useSelector((state) => state.user.userSingleData);
  // dispatch to redux for logout functionality
  const handleLogout = (e) => {
    const confirmed = window.confirm("Are you sure you want to Logout ?");
    if (confirmed) {
      Persistor.purge();
      localStorage.clear();
      sessionStorage.clear("persist:root");
      // Cookies.remove('refreshToken', { path: '/' });
      let obj = { "selectAll": true, selectedSite: [] }
      dispatch({
        type: "SITE_SELECTED_LIST",
        payload: obj,
      });
      dispatch({
        type: "LOGOUT",
      });
      // storage.removeItem("persist:root");
    }
  };

  // useEffects
  useEffect(() => {
    if (reducerToken === null) navigate("/");
  }, [reducerToken]);

  useEffect(() => {
    if (email === null);
    let viewUserObj = {
      email: email,
    };
    dispatch({
      type: "USER_GET_SINGLE",
      payload: viewUserObj,
    });
  }, [email]);
  let styles = {
    name: { fontSize: "15px", fontWeight: "bolder" }
  }

  return (
    <div>
      <div className="user-profile-container position-absolute end-0 d-flex align-items-center">
        {sitefilter === false ? "" : <SiteFilter />}
        <FontAwesomeIcon
          size="lg"
          icon={faUserCircle}
          className="user-icon sideNavIcons"
        />
        {/* <div className="d-none d-md-block"> */}
        <span className="user-name d-none d-md-block mx-2">
          {userProfileData.first_name}&nbsp;{userProfileData.middle_name}&nbsp;
          {userProfileData.last_name}
        </span>
        {/* </div> */}
        <button data-bs-toggle="dropdown" aria-expanded="false" className="dropdown-btn">
          <FontAwesomeIcon
            size="lg"
            icon={faCaretDown}
            className="dropdown-icon sideNavIcons"
          />
        </button>

        <ul className="dropdown-menu mt-2">
          <li className="pt-1">
            <button className="dropdwn profile-btn" onClick={() => navigate("/userdetail")}>
              Profile
              <span data-tip data-for="toolTip">
                <FontAwesomeIcon size="1x" id="tooltip-anchor" icon={faUserPen} className="mx-4" />
              </span>
            </button>
          </li>
          <li className="pb-2">
            <button className="dropdwn logout-btn" onClick={handleLogout}>
              Logout
              <span data-tip data-for="toolTip">
                <FontAwesomeIcon size="lg" id="tooltip-anchor" icon={faSignOut} className="mx-3" />
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>

  );
};

export default LogoutButton;
