import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

function CardList({ data, currentPage, itemsPerPage, name, CardKey, selectedItem, startDate, endDate, searchInput }) {
    const navigate = useNavigate();
    return (
        <div>
            {data?.length > 0 ? data?.map((eachData, index) => (
                <div key={index} className="card-data">
                    <div className="custom-card-body">
                        <div className="custom-card-header">
                            <h5 className="serial-number">
                                #{(currentPage - 1) * itemsPerPage + (index + 1)}
                            </h5>
                        </div>
                        <div className="custom-card-content">
                            {name == "Ticket" &&
                                <div className="row row-mobile">
                                    <div className="col-6 key name"> {name} ID: </div>
                                    <div className="col-6 value name">
                                        {eachData?.id}
                                    </div>
                                </div>}
                            <div className="row row-mobile">
                                <div className="col-6 key name"> {name} Name: </div>
                                <div className="col-6 value name">
                                    {eachData?.maintenance?.maintenance_name ||
                                        eachData?.inspection?.inspection_name ||
                                        eachData?.name}
                                </div>
                            </div>
                            <div className="row row-mobile">
                                <div className="col-6 key"> Site Name: </div>
                                <div className="col-6 value"> {eachData?.site?.site_name || "-"} </div>
                            </div>
                            <div className="row row-mobile">
                                <div className="col-6 key">
                                    {selectedItem !== "All" && selectedItem !== "all"
                                        ? `${selectedItem?.charAt(0).toUpperCase() + selectedItem?.slice(1)} ID`
                                        : "Asset / Device / Facility - ID"}: </div>
                                <div className="col-6 value"> {eachData?.parent?.parent_id} </div>
                            </div>
                            <div className="row row-mobile">
                                <div className="col-6 key">
                                    {selectedItem !== "All" && selectedItem !== "all"
                                        ? `${selectedItem?.charAt(0).toUpperCase() + selectedItem?.slice(1)} Name`
                                        : "Asset / Device / Facility - Name"}: </div>
                                <div className="col-6 value"> {eachData?.parent?.parent_name} </div>
                            </div>
                            <div className="row row-mobile">
                                <div className="col-6 key"> {name !== "Ticket" ? "Planned Date & Time:" : 'Created Date & Time:'} </div>
                                <div className="col-6 value">
                                    {eachData?.maintenance?.maint_sch_date
                                        ? moment.utc(eachData?.maintenance?.maint_sch_date).local().format("LLL")
                                        : eachData?.inspection?.ins_sch_date
                                            ? moment.utc(eachData?.inspection?.ins_sch_date).local().format("LLL")
                                            : eachData?.createdAt
                                                ? moment.utc(eachData?.createdAt).local().format("LLL")
                                                : "-"}
                                </div>
                            </div>
                            {["waiting_for_approval", "approved"].includes(CardKey) && (
                                <div className="row row-mobile">
                                    <div className="col-6 key"> Performed Date & Time: </div>
                                    <div className="col-6 value">
                                        {eachData?.maintenance?.performed_date
                                            ? moment.utc(eachData?.maintenance?.performed_date).local().format("LLL")
                                            : eachData?.inspection?.end_date
                                                ? moment.utc(eachData?.inspection?.end_date).local().format("LLL")
                                                : eachData?.performance?.performed_date
                                                    ? moment.utc(eachData?.maintenance?.end_date).local().format("LLL")
                                                    : "-"}
                                    </div>
                                </div>
                            )}
                            <div className="row row-mobile">
                                <div className="col-6 key"> {name === "Ticket" ? "Criticality" : "Priority"}: </div>
                                <div className="col-6 value">
                                    {(eachData?.maintenance || eachData?.inspection) &&
                                        (eachData?.maintenance?.priority?.charAt(0)?.toUpperCase() +
                                            eachData?.maintenance?.priority?.slice(1) ||
                                            eachData?.inspection?.priority?.charAt(0)?.toUpperCase() +
                                            eachData?.inspection?.priority?.slice(1))}
                                    {name === "Ticket" ? (eachData?.service?.criticality !== "" ? eachData?.service?.criticality : "-") : ""}
                                </div>
                            </div>
                            {(name === "Ticket" &&
                                ["approved", "waiting_for_approval", "assigned", "reassigned", "rejected", "yet_to_be_assigned", "cancelled", "reopened"].includes(CardKey)) &&

                                <div className="pdf-preview-btn-mobile-div">{eachData?._id &&
                                    <button
                                        type="button"
                                        data-tip
                                        data-for="viewTip"
                                        onClick={() =>
                                            navigate(`/ticket/report/${eachData?._id}`, {
                                                state: [CardKey, selectedItem, currentPage, itemsPerPage, startDate, endDate, searchInput, name],
                                            })
                                        }
                                        className="pdf-preview-btn-mobile"
                                    > Preview Report
                                        <FontAwesomeIcon
                                            icon={faFilePdf}
                                            size="lg"
                                            className="icons"
                                        />
                                        <ReactTooltip
                                            id="viewTip"
                                            place="bottom"
                                            effect="solid"
                                        >
                                            Preview Report
                                        </ReactTooltip>
                                    </button>
                                }
                                </div>}
                            {CardKey === "approved" && <div className="pdf-preview-btn-mobile-div"> {eachData?.inspection?._id && (
                                <button
                                    type="button"
                                    data-tip
                                    data-for="viewTip"
                                    onClick={() =>
                                        navigate(
                                            `/inspection/report/${eachData?.inspection?._id}`,
                                            {
                                                state: [
                                                    CardKey,
                                                    selectedItem,
                                                    currentPage,
                                                    itemsPerPage,
                                                    startDate,
                                                    endDate,
                                                    searchInput,
                                                    name,
                                                ],
                                            }
                                        )
                                    }
                                    className="pdf-preview-btn-mobile"
                                > Preview Report
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        size="lg"
                                        className="icons"
                                    />
                                    <ReactTooltip
                                        id="viewTip"
                                        place="bottom"
                                        effect="solid"
                                    >
                                        Preview Report
                                    </ReactTooltip>
                                </button>
                            )}
                                {eachData?.maintenance?._id && (
                                    <button
                                        type="button"
                                        data-tip
                                        data-for="viewTip"
                                        onClick={() =>
                                            navigate(
                                                `/maintenance/report/${eachData?.maintenance?._id}`,
                                                {
                                                    state: [
                                                        CardKey,
                                                        selectedItem,
                                                        currentPage,
                                                        itemsPerPage,
                                                        startDate,
                                                        endDate,
                                                        searchInput,
                                                        name,
                                                    ],
                                                }
                                            )
                                        }
                                        className="pdf-preview-btn-mobile"
                                    >Preview Report
                                        <FontAwesomeIcon
                                            icon={faFilePdf}
                                            size="lg"
                                            className="icons"
                                        />
                                        <ReactTooltip
                                            id="viewTip"
                                            place="bottom"
                                            effect="solid"
                                        >
                                            Preview Report
                                        </ReactTooltip>
                                    </button>
                                )}</div>}
                        </div>
                    </div>
                </div>
            )) : <p className='text-center'>No Data Available</p>}
        </div>
    )
}

export default CardList
